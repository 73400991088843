import { ComponentChildren } from 'preact';
import {
  IcoBook,
  IcoCreditCard,
  IcoEye,
  IcoHome,
  IcoMail,
  IcoPencil,
  IcoPresentation,
  IcoSettings,
  IcoTag,
  IcoUsers,
} from '@components/icons';
import { UserMenu } from '@components/nav/user-menu';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { useRouteParams } from '@components/router';
import * as urls from 'shared/urls';
import { useCourseGuideMenu } from '@components/guide-course-menu/use-course-guide-menu';
import { CourseWithGuide } from 'server/types';
import { CourseEnrollmentToggle } from '@components/course-enrollment-status';

type PageType =
  | 'dashboard'
  | 'content'
  | 'prices'
  | 'coupons'
  | 'sales'
  | 'invitations'
  | 'customers'
  | 'messages'
  | 'style'
  | 'settings';

type Props = {
  page: PageType;
  viewLink?: string;
  product: CourseWithGuide;
  children: ComponentChildren;
  menuPrefix?: ComponentChildren;
};

const titles: Record<PageType, string> = {
  dashboard: 'Dashboard',
  content: 'Content',
  prices: 'Prices',
  coupons: 'Coupons',
  sales: 'Sales Page',
  invitations: 'Invitations',
  customers: 'Customers',
  style: 'Style',
  settings: 'Settings',
  messages: 'Messages',
};

function PageNavLink(props: { href: string; isSelected?: boolean; children: ComponentChildren }) {
  return (
    <a
      href={props.href}
      class={`inline-flex items-center gap-2 p-3 lg:pl-2 py-0.5 font-semibold text-inherit rounded-md ${
        props.isSelected ? 'bg-gray-200 text-gray-900' : ''
      } hover:bg-gray-100 [&>svg]:hidden lg:[&>svg]:inline`}
      ref={
        props.isSelected
          ? (el: any) => {
              // Scroll the selected link into view, if it's not already
              if (el && !el.$scrolled && el.parentElement) {
                el.$scrolled = true;
                const parent = el.parentElement;
                if (el.offsetLeft >= parent.offsetWidth) {
                  el.parentElement.scrollTo({
                    left: el.offsetLeft,
                  });
                }
              }
            }
          : undefined
      }
    >
      {props.children}
    </a>
  );
}

export function GuideProductPage({ product, page, children, menuPrefix, viewLink }: Props) {
  const { courseId, lessonId } = useRouteParams();

  const showMenu = useCourseGuideMenu({ course: product });
  useDocumentTitle([titles[page], product.title]);

  return (
    <div class="min-h-screen flex flex-col">
      <header class="relative flex flex-col border-b">
        <header class="flex items-center pl-1 pr-4 py-1 bg-linear-to-r from-indigo-600 to-indigo-600 via-violet-500 text-white font-semibold">
          <span class="flex items-center">
            <a href="/" class="text-inherit hover:bg-white/15 p-2 rounded-lg">
              <IcoHome class="w-5 h-5" />
            </a>
            <a
              href={`/manage/products/${product.id}`}
              class="text-inherit line-clamp-1 hover:bg-white/15 p-2 py-1 rounded-lg text-base"
            >
              {product.title}
            </a>
          </span>
          <nav class="ml-auto flex items-center gap-4">
            <div class="hidden lg:block">
              <CourseEnrollmentToggle course={product} />
            </div>
            <button
              class="inline-flex items-center gap-2 rounded-lg p-2 py-1 hover:bg-white/15 text-inherit"
              onClick={showMenu}
            >
              <IcoSettings />
              Manage Product
            </button>
            <a
              class="inline-flex items-center gap-2 rounded-lg p-2 py-1 hover:bg-white/15 text-inherit"
              href={viewLink || urls.products.customerView({ courseId, lessonId })}
            >
              <IcoEye />
              Customer View
            </a>
            <UserMenu />
          </nav>
        </header>
        <nav class="flex gap-4 lg:gap-6 p-1 py-2 pr-4 whitespace-nowrap overflow-auto max-w-full relative">
          {menuPrefix}
          <PageNavLink href={`/manage/products/${product.id}`} isSelected={page === 'dashboard'}>
            <IcoHome />
            Dashboard
          </PageNavLink>
          <PageNavLink
            href={`/manage/products/${product.id}/content`}
            isSelected={page === 'content'}
          >
            <IcoBook />
            Content
          </PageNavLink>
          <PageNavLink
            href={`/manage/products/${product.id}/prices`}
            isSelected={page === 'prices'}
          >
            <IcoCreditCard />
            Prices
          </PageNavLink>
          <PageNavLink
            href={`/manage/products/${product.id}/coupons`}
            isSelected={page === 'coupons'}
          >
            <IcoTag />
            Coupons
          </PageNavLink>
          <PageNavLink
            href={`/manage/products/${product.id}/salespage`}
            isSelected={page === 'sales'}
          >
            <IcoPresentation />
            Sales Page
          </PageNavLink>
          <PageNavLink
            href={`/manage/products/${product.id}/messages`}
            isSelected={page === 'messages'}
          >
            <IcoMail />
            Messages
          </PageNavLink>
          <PageNavLink
            href={`/manage/products/${product.id}/customers`}
            isSelected={page === 'customers'}
          >
            <IcoUsers />
            Customers
          </PageNavLink>
          <PageNavLink href={`/manage/products/${product.id}/style`} isSelected={page === 'style'}>
            <IcoPencil />
            Style
          </PageNavLink>
          <PageNavLink
            href={`/manage/products/${product.id}/settings`}
            isSelected={page === 'settings'}
          >
            <IcoSettings />
            Settings
          </PageNavLink>
          <div class="absolute"></div>
        </nav>
      </header>

      {children}
    </div>
  );
}
