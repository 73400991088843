/**
 * This component loads the next invoice for a given purchase, if there is one.
 * It also handles changing the payment method for that (and future) invoices.
 */
import { rpx } from 'client/lib/rpx-client';
import * as fmt from 'shared/payments/fmt';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { fmtFullDate } from 'shared/dateutil';
import { Button } from '@components/buttons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { useCurrentUser } from '@components/router/session-context';
import { useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import { StripeContext, StripeInput } from '@components/checkout/stripe';
import { useIntl } from 'shared/intl/use-intl';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

const store = rpx.upcomingInvoice;

function changePaymentMethod({
  purchaseId,
  priceId,
  details,
}: {
  purchaseId: string;
  priceId: string;
  details?: {
    cardLast4?: string;
    cardBrand?: string;
  };
}) {
  showModalForm(({ resolve }) => {
    const currentUser = useCurrentUser()!;

    const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);

    const saveCard = async () => {
      if (!stripeContext) {
        return;
      }
      try {
        // Get the credit card info from Stripe
        const { error, paymentMethod } = await stripeContext.stripe.createPaymentMethod({
          type: 'card',
          card: stripeContext.elements.getElement('card')!,
          billing_details: {
            name: currentUser.name,
            email: currentUser.email,
          },
          metadata: {
            userId: `${currentUser.id}`,
          },
        });

        if (error || !paymentMethod) {
          throw error || new Error('Failed to save card.');
        }

        await store.changePaymentMethod({
          purchaseId,
          paymentMethod: paymentMethod.id,
          details: details && { ...details, userId: currentUser.id },
        });

        // Hacky way to reset all state
        location.reload();
      } catch (err) {
        showError(err);
      }
    };

    return (
      <ModalForm
        onClose={resolve}
        onSubmit={saveCard}
        title="Enter a new payment method"
        confirmButtonText="Save card"
      >
        <StripeInput
          priceId={priceId}
          onReady={setStripeContext}
          collectAddress={false}
          customerName={currentUser.name}
        />
      </ModalForm>
    );
  });
}

function CardBrand({ brand }: { brand?: string }) {
  brand = brand || 'card';
  brand = brand.toLowerCase();

  if (!['amex', 'discover', 'visa', 'mastercard'].includes(brand)) {
    return <span class="border rounded-xs px-1 bg-gray-50 border-gray-200 mr-2">{brand}</span>;
  }

  // These are assets at a well-known location, and are not environment-specific,
  // so we're hard-coding the URL.
  return <img src={`${RUZUKU_ASSETS_BASE_URL}/logos/${brand}.svg`} alt={brand} class="w-8" />;
}

export function UpcomingInvoice({
  purchaseId,
  asSeller,
}: {
  purchaseId: string;
  asSeller: boolean;
}) {
  const intl = useIntl();
  const { isLoading, data: invoice } = useTryAsyncData(
    () => store.getStripeData({ purchaseId }),
    [purchaseId],
  );

  if (isLoading) {
    return <DefaultSpinner />;
  }

  if (!invoice) {
    return null;
  }

  const paymentProgress = invoice.numPaymentsRequired
    ? intl('{count:number} of {total:number}', {
        count: (invoice.numPaymentsMade || 0) + 1,
        total: invoice.numPaymentsRequired,
      })
    : '';
  const [nextAttemptPrefix, nextAttempt, nextAttemptSuffix] = intl.split(
    'The latest payment {paymentProgress:string} failed! The next attempt will be <>{date:string}</>.',
    {
      paymentProgress,
      date: fmtFullDate(invoice.dueDate),
    },
  );

  return (
    <div>
      {invoice.attempted && (
        <p class="text-red-600">
          {nextAttemptPrefix}
          <strong>{nextAttempt}</strong>
          {nextAttemptSuffix}
        </p>
      )}
      {!invoice.attempted && (
        <>
          <h4 class="font-semibold mb-2">
            {intl('Next payment')} {paymentProgress}
          </h4>
          <div>
            <strong>
              {fmt.price({
                priceInCents: invoice.amount,
                currency: invoice.currency,
              })}
            </strong>
            <span class="ml-2">{fmtFullDate(invoice.dueDate)}</span>
          </div>
        </>
      )}
      <Button
        onClick={
          asSeller
            ? undefined
            : () =>
                changePaymentMethod({
                  purchaseId,
                  priceId: invoice.priceId,
                  details: {
                    cardLast4: invoice.cardLast4,
                    cardBrand: invoice.cardBrand,
                  },
                })
        }
        class="flex items-center my-2 space-x-2"
      >
        <CardBrand brand={invoice.cardBrand} />
        <span class="opacity-75">
          {intl('Ending in {last}', { last: invoice.cardLast4 || '****' })}
        </span>
        {!asSeller && <span class="text-indigo-600 dark:text-indigo-400">{intl('change')}</span>}
      </Button>
    </div>
  );
}
