import {
  ChecklistBlock,
  FocusedTestimonialBlock,
  SalesBlockDefinition,
  SplitBlock,
  TestimonialBlock,
  FAQBlock,
  AboutMeBlock,
} from '@components/page-builder';
import * as BannerBlock from './banner-block';
import * as PricingBlock from './pricing-block';
import { useContext, useState } from 'preact/hooks';
import { SalesBlockState, SalespageContent } from 'server/types';
import { showError } from '@components/app-error';
import { PriceChooser } from './price-points-chooser';
import { EmptyGuidePage } from '@components/empty-page';
import { IcoSlides } from '@components/icons';
import { GuideCoursePage, GuideProductPage } from '@components/guide-course-page';
import { URLS } from 'shared/urls';
import { completeCourseStep } from '@components/course-checklist';
import { rpx } from 'client/lib/rpx-client';
import { blockDefinitions } from './blocks';
import { ModalFormContext, showModalForm } from '@components/modal-form';
import { PriceRow } from 'server/types';
import { NewPriceForm } from '../pmts/components/new-price-form';
import { globalConfig } from 'client/lib/auth';
import { h as htm } from 'minidoc-editor';
import { intl } from 'shared/intl/use-intl';
import { courseLabel } from 'shared/terminology';
import { PageEditor, usePageAutosaver } from '@components/page-builder/page-editor';
import { StandardDialog } from '@components/dialog';
import { LoadedProps, RouteLoadProps, errRedirect } from '@components/router';

const store = rpx.manageSalesPage;
const priceStore = rpx.prices;

type Props = LoadedProps<typeof load>;

export function showNewPriceModal(props: {
  courseId: UUID;
  productId: string;
  supportsStripe: boolean;
  supportsPaypal: boolean;
  setPrice(price: PriceRow): void;
}) {
  return showModalForm(() => {
    const { resolve } = useContext(ModalFormContext);
    const hide = () => resolve(undefined);

    return (
      <StandardDialog onClose={hide}>
        <NewPriceForm
          courseId={props.courseId}
          onCancel={hide}
          supportsStripe={props.supportsStripe}
          supportsPaypal={props.supportsPaypal}
          createPrice={async (opts) => {
            const price = await priceStore.createPrice({
              ...(opts as any),
              isCorePrice: false,
              productId: props.productId,
            });

            props.setPrice(price);
            hide();
            return price;
          }}
        />
      </StandardDialog>
    );
  });
}

export async function load(route: RouteLoadProps) {
  const { courseId } = route.params;
  try {
    const [{ course, prices, content, product, supportsPaypal, supportsStripe }] =
      await Promise.all([store.getInitialState({ courseId })]);

    return {
      course,
      prices,
      product,
      content,
      currentUser: route.auth.user!,
      supportsStripe,
      supportsPaypal,
      tenant: globalConfig().tenant,
    };
  } catch (err) {
    // These error codes *probably* indicate that a guide has shared
    // the sales page editor URL when they intended to share the
    // sales page "view" URL. This gracefully redirects to the public
    // URL in that scenario. We use a throw so that TypeScript doesn't
    // complain about the return types being wrong.
    if (err.statusCode === 403 || err.statusCode === 401) {
      throw errRedirect(`/courses/${courseId}/salespage`);
    }
    throw err;
  }
}

/**
 * Generate a template sales page with placeholder content for the various block types,
 *  including a sales block with the course's current pricepoints.
 */
function generateInitialContent(opts: {
  currentUser: Props['state']['currentUser'];
  course: Props['state']['course'];
  tenant: Props['state']['tenant'];
  prices: Props['state']['prices'];
  pricePointIds?: string[];
}): SalespageContent {
  const { course, tenant, currentUser } = opts;
  const prices: PriceRow[] = (opts.pricePointIds || []).map(
    (id) => opts.prices.find((p) => p.id === id)!,
  );
  const [summaryPrefix, summary, summarySuffix] = intl.split('Brief <>sales pitch</> or summary.', {
    courseLabel: courseLabel({
      course,
      tenant,
    }),
  });
  const blockState = (d: SalesBlockDefinition, initialState: any = d.initialState) => {
    const state: SalesBlockState = {
      id: `${d.type}-${Date.now()}`,
      type: d.type,
      state:
        typeof initialState === 'function' ? initialState({ currentUser, tenant }) : initialState,
    };
    if (d.initialBlockState) {
      Object.assign(state, d.initialBlockState);
    }
    return state;
  };

  const blocks: SalesBlockState<unknown>[] = [
    blockState(
      BannerBlock,
      BannerBlock.generateState({
        richText: htm(
          '.tmp',
          htm('h1', course.title),
          htm('p', {
            innerHTML: `${summaryPrefix} <em><strong>${summary}</strong></em> ${summarySuffix}`,
          }),
        ).innerHTML,
      }),
    ),
    blockState(ChecklistBlock),
    blockState(FocusedTestimonialBlock),
    blockState(
      PricingBlock,
      PricingBlock.generateState({
        courseId: course.id,
        isProduct: course.isProduct,
        prices,
      }),
    ),
    blockState(SplitBlock),
    blockState(TestimonialBlock),
    blockState(FAQBlock),
    blockState(AboutMeBlock, AboutMeBlock.generateState({ currentUser })),
  ];

  return {
    blockIds: blocks.map((x) => x.id),
    blocks: blocks.reduce((acc, x) => {
      acc[x.id] = x;
      return acc;
    }, {} as Record<string, SalesBlockState>),
  };
}

async function copyCourseContent(content: SalespageContent, courseId: UUID) {
  const { content: copiedContent } = await rpx.manageSalesPage.getInitialState({ courseId });
  const pricingTable = Object.values(content.blocks).find((x) => x.type === PricingBlock.type)!;
  if (!copiedContent?.blockIds.length) {
    return content;
  }
  copiedContent.blockIds.forEach((id) => {
    const block = copiedContent.blocks[id];
    if (block?.type === PricingBlock.type) {
      copiedContent.blocks[id] = { ...pricingTable, id };
    }
  });
  return copiedContent;
}

function findPrice(content: SalespageContent) {
  return Object.values(content.blocks).find(
    (x) => x.type === PricingBlock.type && (x.state as PricingBlock.State).sections.length,
  );
}

function Content(props: Props) {
  const { course, tenant, prices, product, supportsStripe, supportsPaypal, currentUser } =
    props.state;

  const [state, setState] = useState(() => {
    return {
      selectedId: props.state.content?.blockIds[0] || '',
      content: props.state.content || {
        blockIds: [],
        blocks: {},
      },
    };
  });

  const { content } = state;
  const hasContent = !!content?.blockIds.length;
  const hasPrices = !!prices.length;

  const autosaver = usePageAutosaver({
    content,
    onSave({ content }) {
      return rpx.courses.saveSalesPage({ id: course.id, content });
    },
  });

  /**
   * Resets the course sales page to the original placeholder default state.
   */
  async function onResetSalesPage() {
    try {
      setState({ content: { blockIds: [], blocks: {} }, selectedId: '' });
    } catch (err) {
      showError(err);
    }
  }

  return (
    <>
      {!hasPrices && (
        <EmptyGuidePage
          Ico={IcoSlides}
          actionText={course.level === 'guide' ? 'Create your first price point' : ''}
          description="At least one price point is required in order to create a sales page."
          title="Sales Page"
          onClick={() => {
            showNewPriceModal({
              courseId: course.id,
              productId: product.id,
              supportsStripe,
              supportsPaypal,
              setPrice() {
                location.reload();
              },
            });
          }}
        />
      )}
      {hasPrices && !hasContent && (
        <PriceChooser
          prices={prices}
          isProduct={course.isProduct}
          onCreate={async (pricePointIds, copyFromCourseId) => {
            try {
              let newContent = generateInitialContent({
                course,
                tenant,
                prices,
                currentUser,
                pricePointIds,
              });

              if (copyFromCourseId) {
                newContent = await copyCourseContent(newContent, copyFromCourseId);
              }

              await rpx.courses.saveSalesPage({ id: course.id, content: newContent });
              setState({ content: newContent, selectedId: content.blockIds[0] });
              completeCourseStep(props.router, 'salesPage');
            } catch (err) {
              showError(err);
            }
          }}
        />
      )}
      {hasPrices && hasContent && (
        <PageEditor
          title="Sales page"
          state={state}
          setState={setState}
          isProduct={course.isProduct}
          publicURL={URLS.student.salespage({
            course,
            domain: location.host,
          })}
          isConnected={autosaver.isConnected}
          isDirty={autosaver.isDirty}
          blockDefinitions={blockDefinitions}
          onReset={onResetSalesPage}
          header={
            !findPrice(state.content) && (
              <span class="text-yellow-700 border border-yellow-400 bg-yellow-50 inline-flex px-2 rounded-sm">
                This sales page does not have a valid pricing table.
              </span>
            )
          }
        />
      )}
    </>
  );
}

export function Page(props: Props) {
  const { course } = props.state;
  if (course.isProduct) {
    return (
      <GuideProductPage
        page="sales"
        product={course}
        viewLink={URLS.student.salespage({
          isProduct: true,
          course,
        })}
      >
        <Content {...props} />
      </GuideProductPage>
    );
  }

  return (
    <GuideCoursePage
      course={course}
      viewLink={URLS.student.salespage({
        course,
      })}
      type="salespage"
    >
      <Content {...props} />
    </GuideCoursePage>
  );
}
