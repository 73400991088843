import { showError } from '@components/app-error';
import { UserProfileIcon } from '@components/avatars';
import { BtnSecondary } from '@components/buttons';
import { defCoursesRoute } from '@components/courses-app-router';
import { showDialog } from '@components/dialog';
import { ErrorPage } from '@components/error-page';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { useAuth } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { getRedirectUrl } from 'client/utils/url';
import { hasLevel } from 'shared/auth';

type Props = LoadedProps<typeof loadGuide>;

// 404 error that handles all unknown routes
export const page404 = {
  route: defCoursesRoute({
    isPublic: true,
    Page() {
      return <ErrorPage title="404 | Not found" />;
    },
  }),
};

// Course error
export const pageCourseErr = {
  route: defCoursesRoute({
    isPublic: true,
    Page: CourseErrorPage,
    load: loadGuide,
  }),
};

// Course error
export const pageCourse401 = {
  route: defCoursesRoute({
    isPublic: true,
    Page: NotAuthorizedCourse,
    load: async (route: RouteLoadProps) => {
      const { auth } = route;
      const canMimic =
        hasLevel(auth.user, 'superadmin') || hasLevel(auth.config.coreUser, 'superadmin');

      if (!canMimic) {
        return;
      }

      return rpx.courses.getCourseGuide({
        id: route.params.courseId,
      });
    },
  }),
};

function CourseErrorPage(props: Props) {
  const { type } = props.params;

  if (type === 'canceled') {
    return (
      <ErrorPage
        title="Membership canceled"
        subtitle="Your membership has been canceled for this course."
        guide={props.state}
      />
    );
  }

  return (
    <ErrorPage
      title="Access expired"
      guide={props.state}
      subtitle={
        <span>
          Your access has expired for this course. Please contact your guide with questions.
        </span>
      }
    />
  );
}

function NotAuthorizedCourse({ state: guide }: { state?: Props['state'] }) {
  const { user, config } = useAuth();
  const { coreUser } = config;
  const canMimic = hasLevel(user, 'superadmin') || hasLevel(coreUser, 'superadmin');

  async function mimicAndView() {
    if (!guide) {
      return;
    }

    if (coreUser) {
      const accepted = await showDialog({
        mode: 'warn',
        title: 'You are already mimicking a user',
        children: `Do you want to stop mimicking '${
          user?.name || 'current user'
        }' and start mimicking '${guide.name || 'this guide'}?`,
        confirmButtonText: 'Yes',
      });
      if (!accepted) {
        return;
      }
    }

    try {
      if (coreUser) {
        await rpx.admin.unmimicUser();
      }

      await rpx.admin.mimicUser({ userId: guide.userId });
      location.assign(getRedirectUrl());
    } catch (err) {
      showError(err);
    }
  }

  return (
    <ErrorPage title="Not authorized" subtitle="You are not authorized to view this page.">
      {guide && canMimic && (
        <div class="flex lg:flex-col items-center border rounded-lg relative px-8 py-4 m-4 lg:w-96">
          <div>
            <UserProfileIcon user={guide} size="w-20 h-20 lg:w-24 lg:h-24 text-2xl" />
          </div>
          <div class="ml-6 lg:ml-0 lg:mt-2">
            <h2 class="text-lg font-bold lg:text-2xl mr-2 mb-1">{guide.name}</h2>
            <div class="mt-4">
              <BtnSecondary class="text-inherit" onClick={mimicAndView}>
                Mimic guide and proceed
              </BtnSecondary>
            </div>
          </div>
        </div>
      )}
    </ErrorPage>
  );
}

function loadGuide(route: RouteLoadProps) {
  return rpx.courses.getCourseGuide({
    id: route.params.courseId,
  });
}
