import { Checkbox } from '@components/checkbox';
import { EmptyGuidePage } from '@components/empty-page';
import { IcoClipboard, IcoPlus, IcoSlides } from '@components/icons';
import { useState } from 'preact/hooks';
import { PriceRow } from 'server/types';
import { PriceSummary } from '../pmts/components/price-summary';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { showCoursePickerModal } from '@components/course-picker';
import { useRouteParams } from '@components/router';

interface Props {
  isProduct?: boolean;
  prices: PriceRow[];
  onCreate(pricePointIds: string[], copyFromCourseId?: string): void;
}

function NewSalesPageForm(props: Props) {
  const { courseId } = useRouteParams();
  const [selectedIds, setSelectedIds] = useState(
    () => new Set(props.prices.map((p) => p.id).slice(0, 3)),
  );

  return (
    <section class="flex flex-col gap-6">
      <p class="text-gray-600">Select up to 3 price points for your sales page.</p>
      <div class="border rounded-sm inline-flex flex-col min-w-3/4 divide-y">
        {props.prices.map((p) => {
          const checked = selectedIds.has(p.id);
          const disabled = !checked && selectedIds.size >= 3;
          return (
            <label
              key={p.id}
              class={`cursor-pointer p-2 px-4 text-left hover:bg-gray-50 flex ${
                disabled ? 'opacity-50' : ''
              }`}
            >
              <Checkbox
                checked={checked}
                disabled={disabled}
                onClick={() =>
                  setSelectedIds((s) => {
                    const result = new Set(s);
                    if (result.has(p.id)) {
                      result.delete(p.id);
                    } else {
                      result.add(p.id);
                    }
                    return result;
                  })
                }
              />
              <span class="ml-4">
                <strong class="block">{p.name}</strong>
                <PriceSummary price={p} />
              </span>
            </label>
          );
        })}
      </div>
      <footer class="flex justify-center gap-2 whitespace-nowrap">
        <BtnPrimary class="gap-1.5" onClick={() => props.onCreate(Array.from(selectedIds))}>
          <IcoPlus />
          <span>Create sales page</span>
        </BtnPrimary>

        <BtnSecondary
          class="gap-1.5"
          onClick={async () => {
            const course = await showCoursePickerModal((c) => c.id !== courseId && c.hasSalespage);
            if (course) {
              return props.onCreate(Array.from(selectedIds), course.id);
            }
          }}
        >
          <IcoClipboard />
          <span>Copy from another {props.isProduct ? 'product' : 'course'}</span>
        </BtnSecondary>
      </footer>
    </section>
  );
}

export function PriceChooser(props: Props) {
  return (
    <EmptyGuidePage
      Ico={IcoSlides}
      centeredDescription
      title="Sales Page"
      description={<NewSalesPageForm {...props} />}
    />
  );
}
