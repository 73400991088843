/**
 * The main front-end entry point for the courses app.
 */

import { useRouteDef, useRouteParams } from '@components/router';
import { AcctBanner, MaintenanceBanner } from '@components/banners';
import { ChatWidget } from '@components/chat-widget';
import { globalConfig } from 'client/lib/auth';
import { HelpscoutMiniButton, useBeacon } from '@components/helpscout-beacon';
import { useEffect } from 'preact/hooks';
import { initSentry } from 'client/lib/sentry';
import dayjs from 'dayjs';
import type { Locales } from 'server/types';
import { courseStyleCache } from '@components/courses-app-router';

const dayjsLocales: Record<Locales, any> = {
  en: () => import('dayjs/locale/en'),
  es: () => import('dayjs/locale/es'),
};

/**
 * Render the tracker scripts.
 */
function Trackers() {
  const def = useRouteDef();
  const configuration = globalConfig();
  const enableBeacon = def.authLevel === 'guide';

  useBeacon({
    user: configuration.user,
    enable: enableBeacon,
    beaconId: configuration?.helpscoutBeaconId,
  });

  if (!enableBeacon) {
    return null;
  }

  // Load beacon script for non-student pages
  // if it's not already loaded
  return (
    <div id="trackers">
      {!window.Beacon && (
        <script type="text/javascript">{`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}</script>
      )}
      <HelpscoutMiniButton />
    </div>
  );
}

/**
 * Render the course CSS link tag if appropriate.
 */
function CourseCSS() {
  const params = useRouteParams();
  const def = useRouteDef();

  const themedCourseId =
    params.courseId && (def.isPublic || def.authLevel === 'student') ? params.courseId : '';

  return themedCourseId && <style>{courseStyleCache[themedCourseId]}</style>;
}

/**
 * Initialize all of our various trackers, and return the config.
 */
function useLoadConfiguration() {
  const configuration = globalConfig();

  useEffect(() => {
    if (!configuration) {
      return;
    }
    if (configuration) {
      initSentry(configuration);
    }

    // Load the appropriate locale for the current route.
    // if the tenant has a non-english locale.
    if (configuration.tenant.locale !== 'en') {
      const newLocale = configuration.tenant.locale || 'en';

      if (dayjs.locale() !== newLocale) {
        dayjsLocales[newLocale]().then(() => {
          dayjs.locale(newLocale);
        });
      }
    }
  }, [configuration]);

  return configuration;
}

/**
 * Render the root component, and wire up the application context.
 */
export function CoursesApp() {
  useLoadConfiguration();

  return (
    <>
      <CourseCSS />
      <MaintenanceBanner />
      <AcctBanner />
      <ChatWidget />
      <Trackers />
    </>
  );
}
