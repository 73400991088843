import { ComponentChildren, createContext } from 'preact';
import { AppRouter, PageDef, RouteParams, RouteLoadProps } from './async-router';
import { Auth, emptyContext, useAuth } from './session-context';
import { Dispatch, StateUpdater, useState, useContext } from 'preact/hooks';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { toQueryString } from 'shared/urls';
import { hasLevel } from 'shared/auth';
import { showError } from '@components/app-error';
import { LoadingIndicator } from '@components/loading-indicator';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';

type LoadingRoute = {
  url: string;
  params: RouteParams;
  loadModule(): Promise<{ route: PageDef }>;
};

type CurrentRoute = LoadingRoute & {
  data: any;
  router: AppRouter;
  auth: Auth;
  def: PageDef;
};

type RouteState = {
  current?: CurrentRoute;
  loading?: LoadingRoute;
};

type RouteContext = {
  data: any;
  router: AppRouter;
  def: PageDef;
  url: string;
  auth: Auth;
  params: RouteParams;
};

export function errRedirect(url: string, hard?: boolean) {
  const err: any = new Error(`Redirect ${url}`);
  err.data = { redirectTo: url, hard };
  return err;
}

/**
 * The context which drives all of our useRoute* hooks.
 */
export const RouteContext = createContext<RouteContext>({
  router: {} as any,
  auth: emptyContext,
  url: '',
  params: {},
  data: undefined,
  def: {
    Page() {
      return null;
    },
  },
});

/*
 * Returns all the route params.
 */
export function useRouteParams() {
  return useContext(RouteContext).params;
}

/**
 * Return the active router.
 */
export function useRouter() {
  return useContext(RouteContext).router;
}

/*
 * Returns the route data.
 */
export function useRouteData<T>() {
  return useContext(RouteContext).data as T;
}

/*
 * Returns the route definition.
 */
export function useRouteDef() {
  return useContext(RouteContext).def;
}

/*
 * Returns the route context.
 */
export function useRouteContext() {
  return useContext(RouteContext);
}

function useRouteLoader(
  router: AppRouter,
  auth: Auth,
  state: RouteState,
  setState: Dispatch<StateUpdater<RouteState>>,
) {
  useAsyncEffect(async () => {
    const loading = state.loading;
    if (!loading) {
      return;
    }
    const { url, params, loadModule } = loading;
    const module = await loadModule();
    const def = module.route;
    if (!def) {
      throw new Error(`Could not find page "${url}"`);
    }
    def.processParams?.(params);
    await tryLoad({
      url,
      params,
      auth,
      def,
      router,
      async load() {
        if (!def.load) {
          setState({
            current: { url, data: {}, params, router, auth, def, loadModule },
          });
          return;
        }
        const data = await def.load({ url, params, auth, router });
        setState((s) => {
          // If the route changed while we were loading, we won't modify
          // the state, since it belongs to a different route.
          if (s.current !== state.current || s.loading !== state.loading) {
            return s;
          }
          return { current: { url, data, params, router, auth, def, loadModule } };
        });
      },
    });
  }, [state.loading?.url]);
}

/**
 * Handle standard loading errors.
 */
async function tryLoad({
  def,
  url,
  params,
  router,
  auth,
  load,
}: Pick<RouteLoadProps, 'url' | 'params' | 'router' | 'auth'> & {
  def: PageDef<any>;
  load(): Promise<unknown>;
}) {
  try {
    if (!def.isPublic && !auth.user) {
      // If login is required, redirect
      router.goto(
        `/login?${toQueryString({
          ...params,
          redirect: encodeURIComponent(location.href.slice(location.origin.length)),
        })}`,
      );
    } else if (def.authLevel && !hasLevel(auth.user, def.authLevel)) {
      // Unauthorized to view this page.
      router.goto('/');
    } else {
      // Good to go... perform the load
      return await load();
    }
  } catch (err) {
    if (err.data?.domain) {
      // Certain Boom errors contain a domain; do a hard redirect.
      const url = new URL(location.href);
      url.hostname = err.data.domain;
      location.assign(url);
    } else if (err.data?.redirectTo) {
      // If Boom data contains a redirect URL respect it.
      if (err.data.hard) {
        location.assign(err.data.redirectTo);
      } else {
        router.goto(err.data.redirectTo);
      }
    } else if (
      url !== '' &&
      url !== '/' &&
      (['Forbidden', 'Unauthorized'].includes(err?.error) || [401, 403].includes(err?.statusCode))
    ) {
      router.goto('/');
    } else if (err?.href) {
      router.goto(err.href);
    } else {
      showError(err);
    }
  }
}

/**
 * If the route params change, and we have a laodSubroute defined,
 * this hook loads the sub route and updates the state accordingly.
 */
function useSubrouteLoader(props: RouteContext, setState: Dispatch<StateUpdater<any>>) {
  const [isLoading, setIsLoading] = useState(false);

  useDidUpdateEffect(async () => {
    const loadSubroute = props.def.loadSubroute;
    let isCanceled = false;
    if (!loadSubroute) {
      window.scrollTo(0, 0);
      return;
    }
    try {
      setIsLoading(true);
      await tryLoad({ ...props, load: () => loadSubroute(props, setState) });
    } finally {
      if (!isCanceled) {
        window.scrollTo(0, 0);
        setIsLoading(false);
      }
    }
    return () => {
      isCanceled = true;
    };
  }, [props.params]);

  return isLoading;
}

function Page(props: RouteContext) {
  const Child = props.def.Page;
  const [state, setState] = useState<any>(props.data);
  const isLoading = useSubrouteLoader(props, setState);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Child {...props} state={state} setState={setState} router={props.router} />
    </>
  );
}

/**
 * Derive the router page state from the previous state and the new url and params.
 */
function makeState(
  route: [url: string, params: RouteParams],
  router: AppRouter,
  state: RouteState,
) {
  const [url, params] = route;
  const { loadModule } = router.definition(url);
  // We compare the loadModule function rather than the URL pattern,
  // since a single page may have multiple URLs map to it, but should
  // only have one loadModule function defined, so this prevents us
  // from re-loading pages unnecessarily.
  if (state.current?.loadModule === loadModule) {
    state.current?.def.processParams?.(params);
    return { ...state, current: { ...state.current, url, params } };
  }
  return { ...state, loading: { url, params, loadModule } };
}

/**
 * Handle routing and render the app based on the current / loading route.
 */
export function RouterPage({
  router,
  children,
}: {
  router: AppRouter;
  children: ComponentChildren;
}) {
  const auth = useAuth();
  const [state, setState] = useState<RouteState>(() => {
    const result = makeState(router.init(), router, {});
    router.onLocationChange((url, params) => setState((s) => makeState([url, params], router, s)));
    return result;
  });

  useRouteLoader(router, auth, state, setState);

  if (!state.current) {
    return (
      <>
        <LoadingIndicator />
        {children}
      </>
    );
  }

  return (
    <RouteContext.Provider value={state.current}>
      {state.loading && <LoadingIndicator />}
      {children}
      <Page key={state.current.def.key?.(state.current) || state.current.url} {...state.current} />
    </RouteContext.Provider>
  );
}
