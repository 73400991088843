import { defCoursesRoute } from '@components/courses-app-router';
import * as EditPage from './guide-course-salespage';

// This is a private page, but we're leaving
// the route definition public, because guides
// tend to share this URL when they're trying
// to share the salespage, so we leave the authorization
// logic up to the load function and it's RPCs.
export const route = defCoursesRoute({
  isPublic: true,
  Page: EditPage.Page,
  load: EditPage.load,
});
