/**
 * The course grid block renders a grid of courses for students to purchase.
 * It needs to be aware of the course state *including* any memberships the
 * current user (if there is one) has.
 *
 * Some cases to consider:
 *
 * - What if the course has been deleted?
 * - What if the price has been archived?
 * - What if the coupon has been archived?
 * - What if the current user is already an active member?
 * - What if the course, price, or coupon is full?
 *
 * When a course is added to the sales page, we'll cache the details, including
 * image, title, price, discount, etc. This way, we can render the courses
 * immediately on load, and then fetch the status asynchronously.
 */
import { showModalForm } from '@components/modal-form';
import {
  EditorProps,
  SalesBlockInitialState,
  SubsectionDragProvider,
  SubsectionEditor,
  ViewerProps,
} from '@components/page-builder';
import { useImageUrl } from 'client/utils/cdn';
import { useContext } from 'preact/hooks';
import { CourseModal } from './course-modal';
import { CourseBlockContext } from './types';
import { useIntl } from 'shared/intl/use-intl';
import { Pill } from '@components/pill';
import { useCurrentTenant } from '@components/router/session-context';
import { URLS } from 'shared/urls';
import { PriceSummary } from '@components/checkout/price-summary';
import { Color } from 'shared/colors';
import { IcoPlus } from '@components/icons';

export interface CoursePriceReference {
  courseId: string;
  priceId?: string;
  couponId?: string;
}

export interface State {
  courses: CoursePriceReference[];
}

export const initialState: State = {
  courses: [],
};

export const name = 'CourseGrid';

export const type = 'coursegrid';

export const initialBlockState: SalesBlockInitialState = {
  type,
  bgcolor: Color.white,
};

export function MiniView() {
  return (
    <span class="grid grid-cols-3 gap-4 rounded-md">
      <span class="block bg-gray-200 rounded-sm p-6"></span>
      <span class="block bg-gray-200 rounded-sm p-6"></span>
      <span class="block bg-gray-200 rounded-sm p-6"></span>
    </span>
  );
}

export function DescribeAvailability(props: CoursePriceReference) {
  const { state } = useContext(CourseBlockContext);
  const { terminology } = useCurrentTenant();
  const course = state.courses[props.courseId];
  const price = props.priceId ? state.prices[props.priceId] : undefined;
  const coupon = props.couponId ? state.coupons[props.couponId] : undefined;
  const signupsClosed = course?.status !== 'published';
  const intl = useIntl();

  if (!course || !price) {
    return null;
  }

  return (
    <span class="font-medium">
      {(() => {
        switch (true) {
          case course.isMember:
            return intl(`View {courseType} ➜`, { courseType: terminology.course });
          case signupsClosed:
            return <Pill color="gray">{intl('Signups closed')}</Pill>;
          case !price.priceInCents:
            return <Pill color="green">{intl('Free!')}</Pill>;
          default:
            return <PriceSummary price={price} coupon={coupon} />;
        }
      })()}
    </span>
  );
}

function CourseCard(props: CoursePriceReference & { onPriceClick?(): void }) {
  const { state } = useContext(CourseBlockContext);
  const course = state.courses[props.courseId];
  const posterImg = useImageUrl(course?.imagePath, {
    maxWidth: 600,
  });
  const signupsClosed = course?.status !== 'published';

  if (!course) {
    return null;
  }

  return (
    <span class={`flex flex-col gap-4 leading-snug ${signupsClosed ? 'opacity-75' : ''}`}>
      <span class="w-full rounded-lg overflow-hidden aspect-square bg-gray-100 flex items-center justify-center">
        <img src={posterImg} class={`object-fit ${signupsClosed ? 'grayscale' : ''}`} />
      </span>
      <span class="flex flex-col gap-2 font-medium text-gray-900">
        <span>{course.title}</span>
        {props.onPriceClick && (
          <button class="text-left" onClick={props.onPriceClick}>
            {' '}
            <DescribeAvailability {...props} />
          </button>
        )}
        {!props.onPriceClick && <DescribeAvailability {...props} />}
      </span>
    </span>
  );
}

export function Editor({ state, setState }: EditorProps<State>) {
  const ctx = useContext(CourseBlockContext);
  return (
    <div class="grid grid-cols-3 justify-center gap-8 text-sm">
      <button
        class="flex flex-col gap-4 leading-snug"
        onClick={() =>
          showModalForm(({ resolve }) => (
            <CourseModal
              onCancel={() => resolve()}
              onPick={(result) => {
                ctx.setState((s) => ({
                  ...s,
                  courses: { ...s.courses, [result.course.id]: result.course },
                  prices: result.price
                    ? { ...s.prices, [result.price.id]: result.price }
                    : s.prices,
                  coupons: result.coupon
                    ? { ...s.coupons, [result.coupon.id]: result.coupon }
                    : s.coupons,
                }));
                setState((s) => ({
                  ...s,
                  courses: [
                    {
                      courseId: result.course.id,
                      couponId: result.coupon?.id,
                      priceId: result.price?.id,
                    },
                    ...s.courses,
                  ],
                }));
                resolve(result);
              }}
            />
          ))
        }
      >
        <span class="w-full bg-gray-100 hover:bg-gray-200 aspect-h-3 aspect-w-4 rounded-lg p-2">
          <span class="flex items-center justify-center font-semibold w-full">
            <IcoPlus class="mr-2" />
            Add Course
          </span>
        </span>
      </button>

      {!state.courses.length && (
        <p class="opacity-75 flex items-center p-4 col-span-2">
          Add any number of courses. They'll show up here as cards that will take your customers to
          a checkout screen.
        </p>
      )}

      <SubsectionDragProvider
        table={type}
        prop="courseId"
        onReorder={(reorder) =>
          setState((s) => ({
            ...s,
            courses: reorder(s.courses),
          }))
        }
      >
        {state.courses.map((c, i) => {
          if (!ctx.state.courses[c.courseId]) {
            return null;
          }
          return (
            <SubsectionEditor
              key={`${c.courseId}:${c.priceId}:${c.couponId}`}
              id={c.courseId}
              table={type}
              onDelete={() =>
                setState((s) => ({
                  ...s,
                  courses: s.courses.filter((_, x) => x !== i),
                }))
              }
            >
              <CourseCard
                {...c}
                onPriceClick={() => {
                  showModalForm(({ resolve }) => (
                    <CourseModal
                      course={ctx.state.courses[c.courseId]}
                      onCancel={() => resolve()}
                      onPick={(result) => {
                        ctx.setState((s) => ({
                          ...s,
                          prices: result.price
                            ? { ...s.prices, [result.price.id]: result.price }
                            : s.prices,
                          coupons: result.coupon
                            ? { ...s.coupons, [result.coupon.id]: result.coupon }
                            : s.coupons,
                        }));
                        setState((s) => ({
                          ...s,
                          courses: s.courses.map((x, index) =>
                            i === index
                              ? {
                                  courseId: result.course.id,
                                  couponId: result.coupon?.id,
                                  priceId: result.price?.id,
                                }
                              : x,
                          ),
                        }));
                        resolve(result);
                      }}
                    />
                  ));
                }}
              />
            </SubsectionEditor>
          );
        })}
      </SubsectionDragProvider>
    </div>
  );
}

export function Viewer({ state }: ViewerProps<State>) {
  const ctx = useContext(CourseBlockContext);
  return (
    <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-6 text-sm">
      {state.courses.map((c) => {
        const course = ctx.state.courses[c.courseId];
        if (!course) {
          return null;
        }
        return (
          <a
            key={`${c.courseId}:${c.priceId}:${c.couponId}`}
            href={URLS.student.salespage({ course, priceId: c.priceId, couponId: c.couponId })}
            class="border border-transparent hover:shadow-lg hover:border-gray-200 rounded-lg p-2 hover:scale-105 transition-transform"
          >
            <CourseCard key={`${c.courseId}:${c.priceId}:${c.couponId}`} {...c} />
          </a>
        );
      })}
    </div>
  );
}
