/**
 * This is a parameterized manage coupons page. It is used for managing both
 * core coupons and course coupons. The only difference is in the params.
 */

import { useRouteParams, useRouter } from '@components/router';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { AssignToPrices, NewCouponForm } from './new-coupon-form';
import { NavBar } from './nav-bar';
import * as fmt from 'shared/payments/fmt';
import { useState } from 'preact/hooks';
import { ListItem } from './paginated-list';
import { ItemTitle } from './item-title';
import { ArchiveToggle } from './archive-toggle';
import { showError } from '@components/app-error';
import { PmtTabs } from './price-tabs';
import { CouponLink, LinksTab } from './links-tab';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { Spinner } from '@components/spinner';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { SignupsTab } from './signups-tab';
import { ExpandedCoupon } from 'server/payments/service';
import { AsyncForm } from '@components/async-form';
import { showToast } from '@components/toaster';
import { EmptyCouponScreen } from './empty-screen';
import { setUrlSearchParam } from 'client/utils/url';
import { IcoPencil, IcoTag } from '@components/icons';
import { useIntl } from 'shared/intl/use-intl';
import { showUpdateCouponModal } from './update-coupon-modal';
import { CORE_PRODUCT_ID } from 'shared/ids';
import { useCurrentUser } from '@components/router/session-context';
import { MetadataTab } from './metadata-tab';
import { AvailableDates } from './signup-limits-tab';
import { Checkbox } from '@components/checkbox';

const store = rpx.coupons;

type Props = RpxResponse<typeof store.init> & {
  supportsStripe: boolean;
  supportsPaypal: boolean;
  isCoreCoupon: boolean;
  courseId?: string; // The id of the course, if this is a course-based screen
  couponUrl(opts: { couponId: string }): string;
  priceUrl(opts: { priceId: string }): string;
  checkoutUrl(opts: { priceId: string; couponId: string }): string;
  couponsUrl: string;
  newUrl: string;
  signupUrl(opts: { priceId: string; userId: string }): string;
};

type LoadedCoupon = Props['coupons'][0];

type TabName = 'links' | 'limits' | 'signups' | 'prices' | 'metadata';

type OnPricesChanged = (result: Parameters<typeof store.allowPricePoints>[0]) => void;

/**
 * A helper to build the tab props.
 */
const tab = (name: TabName, text: string) => ({
  text,
  tab: name,
  href: setUrlSearchParam('tab', name),
});

function NewCouponPage(props: Props) {
  const productId = props.product.id;
  return (
    <div class="grow p-4 md:p-8 max-w-4xl mx-auto">
      <NewCouponForm
        productId={productId}
        cancelHref={props.couponsUrl}
        createCoupon={async (opts) => {
          const coupon = await store.createCoupon({ ...opts, isCoreCoupon: props.isCoreCoupon });
          location.assign(props.couponUrl({ couponId: coupon.id }));
        }}
      />
    </div>
  );
}

function CouponLinks({
  coupon,
  checkoutUrl,
  showDisabled,
}: {
  coupon: LoadedCoupon;
  showDisabled: boolean;
  checkoutUrl(opts: { priceId: string; couponId?: string }): string;
}) {
  return (
    <LinksTab>
      {!coupon.applyTo && (
        <div class="p-8 text-center">
          <Spinner class="border-indigo-400 w-16 h-16 inline-block" />
        </div>
      )}
      {coupon.applyTo?.length === 0 && (
        <p>This coupon is not applied to any active price points.</p>
      )}
      <div class="space-y-8 inline-block">
        {coupon.applyTo?.map((p) => (
          <CouponLink
            key={p.id}
            title="Price"
            subtitle={p.name}
            price={p}
            coupon={coupon}
            checkoutUrl={checkoutUrl}
            showDisabled={showDisabled}
          />
        ))}
      </div>
    </LinksTab>
  );
}

function PricesTab({ coupon, onSave }: { coupon: ExpandedCoupon; onSave: OnPricesChanged }) {
  const [key, setKey] = useState(0);

  return (
    <AsyncForm
      key={key}
      onSubmit={async (formData) => {
        const result = {
          productId: coupon.productId,
          couponId: coupon.id,
          applyTo: formData.applyTo || [],
        };
        await store.allowPricePoints(result);
        showToast({
          type: 'ok',
          title: 'Saved',
          message: `Saved changes.`,
        });
        onSave(result);
      }}
    >
      <AssignToPrices
        productId={coupon.productId}
        coupon={{ ...coupon, type: coupon.percentOff ? '%' : '$' }}
      />
      <footer class="flex items-center py-4">
        <BtnPrimary class="mr-2 capitalize">Save</BtnPrimary>
        <BtnSecondary type="button" onClick={() => setKey((k) => ++k)}>
          Cancel
        </BtnSecondary>
      </footer>
    </AsyncForm>
  );
}

export function CouponDetail({
  coupon,
  updateCoupon,
  checkoutUrl,
  signupUrl,
  priceUrl,
  couponUrl,
  onPricesChanged,
  showDisabled,
}: Pick<Props, 'checkoutUrl' | 'signupUrl' | 'priceUrl' | 'couponUrl'> & {
  onPricesChanged: OnPricesChanged;
  coupon: LoadedCoupon;
  showDisabled: boolean;
  updateCoupon(id: string, f: (coupon: LoadedCoupon) => Partial<LoadedCoupon>): void;
}) {
  const user = useCurrentUser();
  const intl = useIntl();
  const routeParams = useRouteParams();
  const { tab: routeTab } = routeParams;
  const selectedTab = (routeTab || 'links') as TabName;
  return (
    <div class="grow p-4 md:p-8">
      <header class="flex justify-between">
        <ItemTitle
          ico={<IcoTag class="w-12 h-12 bg-indigo-400 rounded-full text-white p-2" />}
          title={coupon.code}
          subtitle={
            <div class="flex flex-col space-y-2">
              <AvailableDates item={coupon} />
              <div class="space-x-2">
                <span>
                  {fmt.describeCoupon({
                    coupon,
                    intl,
                  })}
                </span>

                {!!coupon.freeTrialPeriod && (
                  <>
                    <span>•</span>
                    <span>{coupon.freeTrialPeriod} day free trial</span>
                  </>
                )}
              </div>
            </div>
          }
        />
        <nav class="ml-4 space-x-2">
          <BtnSecondary
            onClick={async () => {
              await showUpdateCouponModal({
                coupon,
                onSave(updatedCoupon) {
                  updateCoupon(updatedCoupon.id, () => updatedCoupon);
                  showToast({
                    type: 'ok',
                    title: 'Coupon saved',
                    message: 'Your changes have been saved.',
                  });
                },
              });
            }}
          >
            <IcoPencil />
            <span class="ml-1.5">Edit Coupon</span>
          </BtnSecondary>
          <ArchiveToggle
            isEnabled={coupon.isEnabled}
            itemName="coupon"
            onToggle={async () => {
              try {
                const isEnabled = !coupon.isEnabled;
                await store.updateCoupon({
                  productId: coupon.productId,
                  couponId: coupon.id,
                  isEnabled,
                });
                updateCoupon(coupon.id, (c) => ({ ...c, isEnabled }));
              } catch (err) {
                showError(err);
              }
            }}
          />
        </nav>
      </header>
      <PmtTabs
        selectedTab={selectedTab}
        tabs={[
          tab('links', 'Links'),
          tab('signups', `Signups (${coupon.numSignups || 0})`),
          tab('prices', `Prices (${coupon.applyTo ? coupon.applyTo.length : ''})`),
          coupon.productId === CORE_PRODUCT_ID && user?.level === 'superadmin'
            ? tab('metadata', 'Metadata')
            : undefined,
        ]}
      />
      {selectedTab === 'links' && (
        <CouponLinks coupon={coupon} checkoutUrl={checkoutUrl} showDisabled={showDisabled} />
      )}
      {selectedTab === 'signups' && (
        <SignupsTab
          productId={coupon.productId}
          couponId={coupon.id}
          mode="price"
          userHref={signupUrl}
          priceHref={priceUrl}
          couponHref={couponUrl}
        />
      )}
      {selectedTab === 'prices' && coupon.applyTo && (
        <PricesTab coupon={coupon} onSave={onPricesChanged} />
      )}
      {selectedTab === 'metadata' && (
        <MetadataTab
          metadata={coupon.metadata || {}}
          hideTier
          onSave={async (metadata) => {
            try {
              await store.updateCoupon({
                productId: coupon.productId,
                couponId: coupon.id,
                metadata,
              });
              updateCoupon(coupon.id, (c) => ({ ...c, metadata }));
              showToast({
                type: 'ok',
                title: 'Saved',
                message: `Saved changes.`,
              });
            } catch (err) {
              showError(err);
            }
          }}
        />
      )}
    </div>
  );
}

function CouponsPage(props: Props) {
  const intl = useIntl();
  const routeParams = useRouteParams();
  let { couponId } = routeParams;
  const productId = props.product.id;
  const router = useRouter();

  const [state, setState] = useState(() => {
    return {
      showDisabled: false,
      coupons: props.coupons,
    };
  });

  const updateCoupon = (id: string, fn: (c: LoadedCoupon) => LoadedCoupon) => {
    setState((s) => ({ ...s, coupons: s.coupons.map((c) => (c.id === id ? fn(c) : c)) }));
  };

  if (!couponId && state.coupons.length) {
    couponId = state.coupons[0].id;
    router.rewrite(props.couponUrl({ couponId }));
  }

  const coupon = state.coupons.find((c) => c.id === couponId);

  const hasDisabled = state.coupons.some((c) => !c.isEnabled);
  const couponList = state.coupons
    .filter((x) => x && (state.showDisabled || x.isEnabled))
    .map((c) => ({
      id: c.id,
      title: c.code,
      subtitle: fmt.describeCoupon({
        coupon: c,
        intl,
      }),
      numPurchases: c.numSignups || 0,
      isEnabled: c.isEnabled,
      isSelected: c.id === coupon?.id,
      href: props.couponUrl({ couponId: c.id }),
    }));

  const reloadCoupon = async (couponId: string) => {
    try {
      const coupon = await store.loadCoupon({
        productId,
        couponId,
      });
      if (coupon) {
        updateCoupon(coupon.id, () => coupon);
      }
    } catch (err) {
      showError(err);
    }
  };

  useAsyncEffect(async () => {
    if (couponId && !coupon?.applyTo) {
      await reloadCoupon(couponId);
    }
  }, [couponId, coupon?.applyTo]);

  return (
    <div class="flex flex-col md:flex-row grow">
      <NavBar itemName="coupon" newItemHref={props.newUrl}>
        <div class="flex flex-col gap-2 pt-4">
          {hasDisabled && (
            <Checkbox
              wrapperClass="p-2"
              onClick={() => setState((s) => ({ ...s, showDisabled: !s.showDisabled }))}
              checked={state.showDisabled}
            >
              Show archived coupons
            </Checkbox>
          )}
          {couponList.map((x) => (
            <ListItem key={x.id} {...x} />
          ))}
        </div>
      </NavBar>
      {coupon && (
        <CouponDetail
          key={coupon.id}
          onPricesChanged={() => {
            reloadCoupon(coupon.id);
          }}
          coupon={coupon}
          couponUrl={props.couponUrl}
          priceUrl={props.priceUrl}
          signupUrl={props.signupUrl}
          checkoutUrl={props.checkoutUrl}
          updateCoupon={updateCoupon}
          showDisabled={hasDisabled}
        />
      )}
      {!coupon && <EmptyCouponScreen newUrl={props.newUrl} hasDisabled />}
    </div>
  );
}

export function ManageCouponsPage(props: Props) {
  const { couponId } = useRouteParams();
  const hasCoupons = !!props.coupons.length;

  if (couponId === 'new') {
    return NewCouponPage(props);
  }
  if (hasCoupons) {
    return CouponsPage(props);
  }
  return EmptyCouponScreen({
    newUrl: props.newUrl,
    supportsPaypal: props.supportsPaypal,
    supportsStripe: props.supportsStripe,
  });
}
