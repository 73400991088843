import { defRoute } from '@components/router';
import { useEffect } from 'preact/hooks';

async function clearSession() {
  // Clear cache storage
  try {
    // This throws an exception in Firefox private browsing mode.
    // So it's okay to swallow the error.
    const cacheNames = await window.caches.keys();
    cacheNames.forEach((name) => {
      window.caches.delete(name);
    });
  } catch (err) {
    console.log(err);
  }

  // Clear localStorage
  window.localStorage.clear();

  // Clear sessionStorage
  window.sessionStorage.clear();
}

export const route = defRoute({
  isPublic: true,
  Page({ auth, router }) {
    useEffect(() => {
      auth.logout().then(() => {
        router.goto('/login');
        clearSession();
      });
    }, []);
    return null;
  },
});
