/**
 * URL generation helper functions. We should move all hard-coded URLs here, so that we
 * have a central place to fix things when we change routes, etc.
 */

import { AssessmentType } from 'server/types';

const baseMyPaymentsRoute = '/pmts/products/:productId/my-payments';
const basePricesRoute = '/pmts/products/:productId/prices';
const coursePricesRoute = '/manage/courses/:courseId/prices';
const courseCheckoutRoute = '/courses/:courseId/checkout/:priceId';
const productCheckoutRoute = '/products/:courseId/checkout/:priceId';
const courseCouponsRoute = '/manage/courses/:courseId/coupons';
const baseCouponsRoute = '/pmts/products/:productId/coupons';

export interface Domain {
  domain?: string;
}
interface IsProduct {
  isProduct?: boolean;
}
interface CourseId {
  courseId: UUID;
}
interface PriceId {
  priceId: string;
}
interface CouponId {
  couponId: string;
}
interface LessonId {
  lessonId: UUID;
}
interface UserId {
  userId: UUID;
}
export interface MeetingId {
  meetingId: UUID;
}
interface DiscussionCategoryId {
  categoryId: UUID;
}
interface DiscussionId {
  discussionId: UUID;
}
interface StudentCourseOpts {
  course: IsProduct & {
    id: UUID;
    title: string;
  };
}
interface StudentLessonOpts {
  lesson: {
    id: UUID;
    title: string;
  };
}
interface StudentMeetingOpts {
  meeting: {
    id: UUID;
    title: string;
  };
}
interface StudentProfileOpts {
  user: {
    id: UUID;
    name: string;
  };
}
interface StudentCommentOpts {
  commentId: UUID;
  parentCommentId?: UUID;
}

export const baseUrl = (opts: Domain) => {
  if (!opts.domain) {
    return '';
  }
  if (opts.domain.startsWith('http')) {
    return opts.domain;
  }
  return opts.domain === 'localhost' ? 'http://localhost:3000' : `https://${opts.domain}`;
};

function titleToSlug(title?: string) {
  if (!title) {
    return '';
  }

  const dashed = title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');

  // Slugs end with two hyphens
  return `${dashed}--`;
}

export const URLS = {
  isProduct(url: string) {
    return url.startsWith('products/') || url.startsWith('manage/products');
  },
  student: {
    course: (opts: StudentCourseOpts & Domain) =>
      `${baseUrl(opts)}/${opts.course.isProduct ? 'products' : 'courses'}/${titleToSlug(
        opts.course.title,
      )}${opts.course.id}`,
    product: (opts: StudentCourseOpts & Domain) =>
      `${baseUrl(opts)}/products/${titleToSlug(opts.course.title)}${opts.course.id}`,
    coursePage: (
      opts: StudentCourseOpts &
        Domain & {
          page: string;
        },
    ) => `${URLS.student.course(opts)}/${opts.page}`,
    invitation: (
      opts: StudentCourseOpts & Domain & { invitation: { id: string; code: string } },
    ) => {
      return `${URLS.student.course(opts)}/invitations/${opts.invitation.id}I${
        opts.invitation.code
      }`;
    },
    lessons: (opts: { courseId: UUID; isProduct?: boolean } & Domain) =>
      opts.isProduct ? `/products/${opts.courseId}/content` : `/courses/${opts.courseId}/lessons`,
    productHome: (opts: { courseId: UUID } & Domain) => `/products/${opts.courseId}/content`,
    module: (opts: { courseId: UUID; moduleId: UUID } & Domain) =>
      `/courses/${opts.courseId}/modules/${opts.moduleId}`,
    lesson: (opts: StudentCourseOpts & StudentLessonOpts & Domain) =>
      `${URLS.student.course(opts)}/${opts.course.isProduct ? 'content' : 'lessons'}/${titleToSlug(
        opts.lesson.title,
      )}${opts.lesson.id}`,
    certificate: (opts: StudentCourseOpts & Domain & { userId: UUID }) =>
      `${URLS.student.course(opts)}/certificate/${opts.userId}`,
    meeting: (opts: StudentCourseOpts & StudentMeetingOpts & Domain) =>
      `${URLS.student.course(opts)}/meetings/${titleToSlug(opts.meeting.title)}${opts.meeting.id}`,
    discussions: (opts: StudentCourseOpts & DiscussionCategoryId & Domain) =>
      `${URLS.student.course(opts)}/discussions/${opts.categoryId}`,
    discussion: (opts: StudentCourseOpts & DiscussionCategoryId & DiscussionId & Domain) =>
      `${URLS.student.discussions(opts)}/${opts.discussionId}`,
    discussionComment: (
      opts: StudentCourseOpts & DiscussionCategoryId & DiscussionId & StudentCommentOpts & Domain,
    ) => {
      const base = URLS.student.discussion(opts);
      if (opts.parentCommentId) {
        return `${base}?commentId=${opts.parentCommentId}&replyId=${opts.commentId}`;
      }
      return `${base}?commentId=${opts.commentId}`;
    },
    members: (opts: StudentCourseOpts & Domain) => `${URLS.student.course(opts)}/people`,
    guideProfile: (opts: StudentCourseOpts & Domain) =>
      `${URLS.student.members(opts)}?redirectToGuide=true`,
    memberProfile: (opts: StudentCourseOpts & StudentProfileOpts & Domain) =>
      `${URLS.student.members(opts)}/${titleToSlug(opts.user.name)}${opts.user.id}`,
    comment: (opts: StudentCourseOpts & StudentLessonOpts & StudentCommentOpts & Domain) => {
      const base = URLS.student.lesson(opts);
      if (opts.parentCommentId) {
        return `${base}?commentId=${opts.parentCommentId}&replyId=${opts.commentId}`;
      }
      return `${base}?commentId=${opts.commentId}`;
    },
    salespage: ({
      priceId,
      couponId,
      isProduct,
      ...opts
    }: StudentCourseOpts & Domain & { isProduct?: boolean; priceId?: string; couponId?: string }) =>
      `${
        isProduct ? URLS.student.product(opts) : URLS.student.course(opts)
      }/salespage${toQueryString({ priceId, couponId }, '?')}`,
  },
  guide: {
    baseUrl: (opts: { id: string; isProduct?: boolean }) =>
      `/manage/${opts.isProduct ? 'products' : 'courses'}/${opts.id}`,
    course: (opts: CourseId & Domain & IsProduct) =>
      `${baseUrl(opts)}/manage/${opts.isProduct ? 'products' : 'courses'}/${opts.courseId}`,
    coursePage: (
      opts: CourseId &
        Domain & {
          isProduct?: boolean;
          page: string;
        },
    ) => `${URLS.guide.course(opts)}/${opts.page}`,
    student: (opts: CourseId & UserId & Domain) =>
      `${URLS.guide.course(opts)}/students/${opts.userId}`,
    students: (opts: CourseId & Domain) => `${URLS.guide.course(opts)}/students`,
    progress: (opts: CourseId & Domain) => `${URLS.guide.course(opts)}/progress`,
    prices: (opts: CourseId & Domain) => `${URLS.guide.course(opts)}/prices`,
    price: (opts: CourseId & PriceId & Domain) =>
      `${URLS.guide.course(opts)}/prices/${opts.priceId}`,
    coupon: (opts: CourseId & CouponId & Domain) =>
      `${URLS.guide.course(opts)}/coupons/${opts.couponId}`,
    lesson: (opts: CourseId & IsProduct & LessonId & Domain) =>
      `${URLS.guide.course(opts)}/${opts.isProduct ? 'content' : 'lessons'}/${opts.lessonId}`,
    lessons: (opts: CourseId & IsProduct & Domain) =>
      `${URLS.guide.course(opts)}/${opts.isProduct ? 'content' : 'lessons'}`,
    module: (opts: { courseId: UUID; moduleId: UUID } & Domain) =>
      `${URLS.guide.course(opts)}/modules/${opts.moduleId}`,
    meeting: (opts: CourseId & MeetingId & Domain) =>
      `${URLS.guide.course(opts)}/meetings/${opts.meetingId}`,
    instantCourses: (opts: Domain = {}) => `${baseUrl(opts)}/instant-courses`,
    assessmentReview(opts: {
      type: AssessmentType;
      courseId: string;
      lessonId: string;
      userId: string;
    }) {
      return `${URLS.guide.course(opts)}/assessments?${opts.type}=${opts.lessonId}&user=${
        opts.userId
      }`;
    },
  },
  admin: {
    course: (
      opts: Domain & {
        courseId: UUID;
      },
    ) => `${baseUrl(opts)}/admin/courses/${opts.courseId}`,
    price: (
      opts: Domain & {
        productId: string;
        priceId: string;
      },
    ) => `${baseUrl(opts)}/admin/pricing/${opts.productId}?priceId=${opts.priceId}`,
    bundle: (
      opts: Domain & {
        bundleId: UUID;
      },
    ) => `${baseUrl(opts)}/admin/course-bundles/${opts.bundleId}`,
  },
};

/**
 * Convert the specified key/value map to a URL query-string.
 */
export function toQueryString(m: Record<string, any>, prefix: '?' | '' = '') {
  const params = new URLSearchParams();
  for (const k in m) {
    const val = m[k];
    if (val != null) {
      params.set(k, val);
    }
  }
  const value = params.toString();
  return value ? `${prefix}${value}` : value;
}

/**
 * reverseRoute takes a URL pattern and returns a URL with the named
 * parameters substituted.
 *
 * Examples:
 * reverseRoute('hello/:name', { name: 'world', foo: 'bar' })
 * => 'hello/world?foo=bar'
 * reverseRoute('hello/:name', { foo: 'bar' })
 * => Error - name parameter is missing
 */
export function reverseRoute(url: string, args: Record<string, any>) {
  const opts = { ...args };
  const substituted = url
    .split('/')
    .map((s) => {
      if (!s.startsWith(':')) {
        return s;
      }
      const name = s.slice(1);
      if (args[name] === undefined) {
        throw new Error(`Expected value for route parameter "${name}".`);
      }
      delete opts[name];
      return `${args[name]}`;
    })
    .join('/');

  let q = toQueryString(opts);
  q = q ? `?${q}` : q;
  return substituted + q;
}

export const products = {
  customerView({ courseId, lessonId }: { courseId: string; lessonId?: string }) {
    return `/products/${courseId}/content/${lessonId || ''}`;
  },
};

export const corePrices = {
  baseRoute: basePricesRoute,
  checkoutRoute: '/checkout/:priceId',
  provisionRoute: '/checkout/:priceId/provision',
  priceRoute: `${basePricesRoute}/:priceId`,
  pricesUrl: <T extends { productId: string }>(opts: T) => reverseRoute(basePricesRoute, opts),
  priceUrl: <T extends { priceId: string; productId: string }>(opts: T) =>
    reverseRoute(corePrices.priceRoute, opts),
  newUrl: <T extends { productId: string }>(opts: T) =>
    reverseRoute(corePrices.priceRoute, { ...opts, priceId: 'new' }),
  checkoutUrl: <T extends { priceId: string }>(opts: T) =>
    reverseRoute(corePrices.checkoutRoute, opts),
};

export const coursePrices = {
  baseRoute: coursePricesRoute,
  checkoutRoute: courseCheckoutRoute,
  productCheckoutRoute,
  priceRoute: `${coursePricesRoute}/:priceId`,
  pricesUrl: <T extends { courseId: string }>(opts: T) => reverseRoute(coursePricesRoute, opts),
  priceUrl: <T extends { courseId: string; priceId: string }>(opts: T) =>
    reverseRoute(coursePrices.priceRoute, opts),
  newUrl: <T extends { courseId: string }>(opts: T) =>
    reverseRoute(coursePrices.priceRoute, { ...opts, priceId: 'new' }),
  checkoutUrl: <T extends { courseId: string; isProduct?: boolean; priceId: string }>({
    isProduct,
    ...opts
  }: T) =>
    reverseRoute(isProduct ? coursePrices.productCheckoutRoute : coursePrices.checkoutRoute, opts),
};

export const myPayments = {
  baseRoute: baseMyPaymentsRoute,
  baseUrl: <T extends { productId: string }>(opts: T) => reverseRoute(baseMyPaymentsRoute, opts),
};

export const coreCoupons = {
  baseRoute: baseCouponsRoute,
  couponRoute: `${baseCouponsRoute}/:couponId`,
  couponsUrl: <T extends { productId: string }>(opts: T) => reverseRoute(baseCouponsRoute, opts),
  couponUrl: <T extends { couponId: string; productId: string }>(opts: T) =>
    reverseRoute(coreCoupons.couponRoute, opts),
  newUrl: <T extends { productId: string }>(opts: T) =>
    reverseRoute(coreCoupons.couponRoute, { ...opts, couponId: 'new' }),
  checkoutUrl: <T extends { priceId: string; couponId: string }>(opts: T) =>
    reverseRoute(corePrices.checkoutRoute, opts),
};

export const courseCoupons = {
  baseRoute: courseCouponsRoute,
  couponRoute: `${courseCouponsRoute}/:couponId`,
  couponsUrl: <T extends { courseId: string }>(opts: T) => reverseRoute(courseCouponsRoute, opts),
  couponUrl: <T extends { couponId: string; courseId: string }>(opts: T) =>
    reverseRoute(courseCoupons.couponRoute, opts),
  newUrl: <T extends { courseId: string }>(opts: T) =>
    reverseRoute(courseCoupons.couponRoute, { ...opts, couponId: 'new' }),
  checkoutUrl: <
    T extends { courseId: string; priceId: string; couponId: string; isProduct?: boolean },
  >({
    isProduct,
    ...opts
  }: T) =>
    reverseRoute(isProduct ? coursePrices.productCheckoutRoute : coursePrices.checkoutRoute, opts),
};

export const ruzcal = {
  newBookingUrl({ urlPrefix, urlSuffix }: { urlPrefix: string; urlSuffix: string }) {
    return `/meet/${urlPrefix}/${urlSuffix}`;
  },
  existingBookingUrl({ domain, id, success }: { id: string; success?: boolean; domain?: string }) {
    const q = toQueryString({ success }, '?');
    return `${baseUrl({ domain })}/calendar/bookings/${id}${q}`;
  },
};
