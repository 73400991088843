import { useDocumentTitle } from 'client/utils/use-document-title';
import { rpx } from 'client/lib/rpx-client';
import { UserProfileIcon } from '@components/avatars';
import { IcoClock, IcoInfo, IcoMapPin, IcoVideoCamera } from '@components/icons';
import { ruzcal } from 'shared/urls';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router/async-router';

type Props = LoadedProps<typeof load>;

export const route = defRoute({ isPublic: true, load, Page });

async function load(route: RouteLoadProps) {
  const { urlPrefix } = route.params;
  const result = await rpx.ruzcal.getPublicEventTypes({
    urlPrefix,
  });
  return { ...result, host: { ...result.host, urlPrefix } };
}

function Page({ state: { host, eventTypes } }: Props) {
  useDocumentTitle([host.name, 'Events']);
  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-2xl shadow-lg">
        <div class="flex flex-col gap-8">
          <section>
            <div class="flex items-center gap-3">
              <UserProfileIcon user={host} size="size-14" />
              <div class="flex flex-col justify-center leading-3 gap-3">
                <span class="font-semibold">Book a meeting with</span>
                <span class="text-base leading-3 text-gray-600">{host.name}</span>
              </div>
            </div>
          </section>
          <hr />
          {!eventTypes.length && <section>There are currently no events available.</section>}
          {!!eventTypes.length && (
            <section class="flex flex-col gap-6 -mt-2">
              {eventTypes.map((evt) => (
                <a
                  href={ruzcal.newBookingUrl({
                    urlPrefix: host.urlPrefix,
                    urlSuffix: evt.urlSuffix,
                  })}
                  class="flex flex-col gap-1 text-inherit hover:bg-gray-100 transition-all p-2 rounded-lg"
                  key={evt.id}
                >
                  <span class="flex gap-2 justify-between">
                    <span class="flex items-center gap-1 font-semibold">{evt.name}</span>
                    <span class="inline-flex items-center gap-1">
                      <IcoClock /> {evt.duration}m
                    </span>
                  </span>
                  {evt.description && (
                    <span class="flex items-center gap-1">
                      <IcoInfo /> {evt.description}
                    </span>
                  )}
                  <span class="flex items-center gap-1">
                    {evt.location === 'external' ? <IcoMapPin /> : <IcoVideoCamera />}
                    {evt.location === 'external'
                      ? evt.locationDetail?.external || 'In person'
                      : 'Video conference'}
                  </span>
                </a>
              ))}
            </section>
          )}
        </div>
      </section>
    </div>
  );
}
