import { UpsellsPage } from './upsells-page';
import { UpsellWizard, WizardSubtitle } from './upsell-wizard';
import { BtnPreWarning, BtnPrimary, Button } from '@components/buttons';
import { IcoArrowLeft, IcoInfo } from '@components/icons';
import { UpsellProductPicker } from './pickers';
import { showError } from '@components/app-error';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { LoadingIndicator } from '@components/loading-indicator';
import { ComponentChildren } from 'preact';
import { PriceSummary } from '@components/checkout';
import { ProductAndPricePreview } from './product-price-preview';
import { useCurrentTenant } from 'client/lib/auth';
import type { FullUpsell, UpsellProduct } from './types';
import { Pill } from '@components/pill';
import { showDialog } from '@components/dialog';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defCoursesRoute } from '@components/courses-app-router';

type Pane = 'pickproduct' | 'pickprice';

type State = {
  page: 'new' | 'edit';
  pane: Pane;
  upsell: FullUpsell;
  appliedTo: {
    product?: UpsellProduct;
    prices: string[];
  };
};

export const newUpsellAppliedPage = {
  route: defCoursesRoute({
    authLevel: 'guide',
    Page,
    load: loadNew,
  }),
};

export const existingUpsellAppliedToPage = {
  route: defCoursesRoute({
    authLevel: 'guide',
    Page,
    load: loadExisting,
  }),
};

async function loadNew(props: RouteLoadProps): Promise<State> {
  const upsell = await rpx.upsells.getUpsell({ upsellId: props.params.id });
  if (!upsell) {
    throw new Error(`Upsell not found!`);
  }
  return {
    page: 'new',
    upsell,
    pane: 'pickproduct',
    appliedTo: {
      prices: [],
    },
  };
}

async function loadExisting(props: RouteLoadProps): Promise<State> {
  const { upsell } = await loadNew(props);
  const product = upsell.appliedProducts?.find((x) => x.id === props.params.productId);
  if (!product) {
    throw new Error(`Applied to item not found!`);
  }
  return {
    page: 'edit',
    upsell,
    pane: 'pickprice',
    appliedTo: {
      product,
      prices: await rpx.upsells.getAppliedPrices({ upsellId: upsell.id, productId: product.id }),
    },
  };
}

type Props = LoadedProps<typeof loadNew>;

function UpsellPrice({
  onClick,
  isSelected,
  children,
}: {
  children: ComponentChildren;
  isSelected: boolean;
  onClick(): void;
}) {
  return (
    <label
      class={`flex items-center border-t last:rounded-b-2xl first:rounded-t-2xl first:border-transparent p-4 gap-4 ring-1 cursor-pointer ${
        isSelected
          ? 'bg-indigo-50 ring-indigo-200 z-10 border-transparent hover:bg-indigo-100'
          : 'ring-transparent hover:bg-gray-50'
      }`}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onClick={onClick}
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-sm"
      />
      <span
        class={`inline-flex size-8 items-center justify-center rounded-full font-semibold ${
          isSelected ? ' bg-indigo-500 text-white' : 'bg-gray-100 text-gray-500'
        }`}
      >
        $
      </span>
      {children}
    </label>
  );
}

function PricePicker(props: Props) {
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const { product, prices } = state.appliedTo;
  const { isLoading, data } = useTryAsyncData(async () => {
    const prices = await rpx.prices.getProductPrices({
      productId: product!.id,
      excludeArchived: true,
    });
    return prices.filter((x) => !x.isUpsellOffer && x.allowStripe);
  }, []);
  const allSelected = prices.length === data?.length;
  const productType = product?.isProduct ? 'product' : terminology.course;
  const numReplacements = state.appliedTo.prices.filter((id) =>
    data?.find((x) => id === x.id && x.upsellId && x.upsellId !== state.upsell.id),
  ).length;

  return (
    <section class="flex flex-col gap-6 overflow-auto">
      {state.appliedTo.product && <ProductAndPricePreview product={state.appliedTo.product} />}
      <WizardSubtitle>Choose price points which will show this upsell</WizardSubtitle>
      {isLoading && <LoadingIndicator />}
      {data?.length === 0 && (
        <section>
          <b>No applicable prices found.</b>
          <p>
            If you want to apply this upsell to a {productType}, the {productType} needs at least
            one Stripe price. Go to the{' '}
            <a
              href={`/manage/${product?.isProduct ? 'products' : 'courses'}/${
                product?.courseId
              }/prices`}
            >
              Price Point
            </a>{' '}
            page to create one.
          </p>
        </section>
      )}
      {!!data?.length && (
        <div class="border rounded-2xl flex flex-col">
          <UpsellPrice
            onClick={() => {
              setState((s) => {
                if (allSelected) {
                  return { ...s, appliedTo: { ...s.appliedTo, prices: [] } };
                }
                return { ...s, appliedTo: { ...s.appliedTo, prices: data.map((x) => x.id) } };
              });
            }}
            isSelected={allSelected}
          >
            <span class="font-semibold">Select all price points</span>
          </UpsellPrice>
          {data.map((p) => {
            const isSelected = !!prices.find((priceId) => priceId === p.id);
            return (
              <UpsellPrice
                key={p.id}
                onClick={() => {
                  setState((s) => {
                    if (!isSelected) {
                      return {
                        ...s,
                        appliedTo: { ...s.appliedTo, prices: [...s.appliedTo.prices, p.id] },
                      };
                    }
                    return {
                      ...s,
                      appliedTo: {
                        ...s.appliedTo,
                        prices: s.appliedTo.prices.filter((priceId) => priceId !== p.id),
                      },
                    };
                  });
                }}
                isSelected={isSelected}
              >
                {p.name}
                <span
                  class={`rounded text-xs px-1 border ${
                    isSelected ? 'bg-indigo-100 border-indigo-200' : 'bg-gray-50'
                  }`}
                >
                  <PriceSummary price={p} />
                </span>
                {p.upsellId && p.upsellId !== state.upsell.id && (
                  <span class="ml-auto pl-2">
                    <Pill color="yellow">Has another upsell</Pill>
                  </span>
                )}
              </UpsellPrice>
            );
          })}
        </div>
      )}

      {numReplacements > 0 && (
        <footer class="border-l-8 border-yellow-600 bg-yellow-50 p-4 px-6 text-yellow-700">
          <span class="font-semibold flex items-center gap-1.5">
            <IcoInfo class="size-5" />
            Note
          </span>
          <p>
            You are about to replace <b>{numReplacements}</b> applied upsells with this one.
          </p>
        </footer>
      )}
    </section>
  );
}

function Page(props: Props) {
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const { upsell, appliedTo } = state;
  const paneSequence: Pane[] = ['pickproduct', 'pickprice'];
  const paneIndex = paneSequence.indexOf(state.pane);
  const title = 'Apply to course or product';
  const cancelURL = `/upsells/${upsell.id}?tab=applyto`;
  const firstPaneIndex = state.page === 'new' ? 0 : 1;

  const showDeleteApplyToModal = async () => {
    try {
      const ok = await showDialog({
        title: 'Remove upsell?',
        children: `This upsell will no longer be displayed on this ${
          appliedTo.product?.isProduct ? 'product' : terminology.course
        }'s checkout pages.`,
        confirmButtonText: `Remove upsell from ${
          appliedTo.product?.isProduct ? 'product' : terminology.course
        }`,
        mode: 'warn',
      });
      if (!ok) {
        return;
      }
      if (appliedTo.product) {
        await rpx.upsells.unapplyPrices({ upsellId: upsell.id, productId: appliedTo.product.id });
      }
      props.router.goto(cancelURL);
    } catch (err) {
      showError(err);
    }
  };

  const saveChanges = async () => {
    try {
      if (appliedTo.product) {
        await rpx.upsells.applyPrices({
          upsellId: upsell.id,
          productId: appliedTo.product!.id,
          prices: appliedTo.prices,
        });
      }
      props.router.goto(cancelURL);
    } catch (err) {
      showError(err);
    }
  };

  return (
    <UpsellsPage title={title}>
      <UpsellWizard
        cancelURL={cancelURL}
        upsell={upsell}
        title={title}
        paneIndex={paneIndex}
        hasUnsavedChanges={() => false}
        header={
          <>
            {state.page === 'new' && firstPaneIndex < paneIndex && (
              <span>
                <Button
                  class="inline-flex gap-2 items-center text-indigo-600 font-semibold"
                  onClick={() => setState((s) => ({ ...s, pane: 'pickproduct' }))}
                >
                  <IcoArrowLeft class="size-4" />
                  Back
                </Button>
              </span>
            )}
          </>
        }
      >
        {state.pane === 'pickproduct' && (
          <UpsellProductPicker
            title={`Apply this upsell to a ${terminology.course} or product`}
            onPick={(product) =>
              setState((s) => ({ ...s, pane: 'pickprice', appliedTo: { product, prices: [] } }))
            }
            filter={(product) => !upsell.appliedProducts.find((x) => x.id === product.id)}
          />
        )}
        {state.pane === 'pickprice' && <PricePicker {...props} />}
        <footer class="pt-4 sticky bottom-0 w-full bg-white empty:hidden">
          {appliedTo.prices.length > 0 && (
            <BtnPrimary class="rounded-full px-4 gap-2" onClick={saveChanges}>
              Apply upsell to the selected price points
              <IcoArrowLeft class="rotate-180" />
            </BtnPrimary>
          )}
          {state.page === 'edit' && appliedTo.prices.length === 0 && (
            <BtnPreWarning class="rounded-full px-4 gap-2" onClick={showDeleteApplyToModal}>
              Remove upsell from this{' '}
              {appliedTo.product?.isProduct ? 'product' : terminology.course}
              <IcoArrowLeft class="rotate-180" />
            </BtnPreWarning>
          )}
        </footer>
      </UpsellWizard>
    </UpsellsPage>
  );
}
