import { RootPageLayout } from '@components/root-page-layout';
import { GuideStats } from './guide-stats';
import { RecentActivities } from './activities-feed';
import { rpx } from 'client/lib/rpx-client';
import { DownloadCsvButton } from '@components/download-csv-modal';
import { LoadedProps } from '@components/router';
import { defCoursesRoute } from '@components/courses-app-router';

type Props = LoadedProps<typeof load>;

export const route = defCoursesRoute({
  Page,
  load,
  authLevel: 'guide',
});

function Page(props: Props) {
  return (
    <RootPageLayout
      activeTab="overview"
      title="Your Overview"
      actions={<DownloadCsvButton type="guide" />}
    >
      <GuideStats data={props.state.stats} />
      <RecentActivities />
    </RootPageLayout>
  );
}

async function load() {
  const stats = await rpx.guideOverview.getGuideStats();
  return {
    stats,
  };
}
