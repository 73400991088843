import { ComponentChildren } from 'preact';
import { Dispatch, StateUpdater, useContext, useMemo } from 'preact/hooks';
import { FullLesson, Course, State } from '@components/module-helpers';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { DownloadsViewer } from './downloads';
import { LessonFooterNav } from './buttons';
import { Divider } from '@components/breadcrumbs';
import { SideNavContext } from '@components/student-page/student-side-nav';
import { Button } from '@components/buttons';
import { Quiz } from '@components/quiz';
import { Poll } from '@components/poll';
import { Assignment } from '@components/assignment';
import { useIntl } from 'shared/intl/use-intl';
import { emptyLessonTitle } from 'shared/terminology';
import { useCurrentTenant } from '@components/router/session-context';
import { DiscussionSection } from './discussion-section';
import { useQuizStats, QuizStats } from '@components/quiz/quiz-results';

interface Props {
  lesson: FullLesson;
  course: Course;
  state: State;
  setState: Dispatch<StateUpdater<State>>;
  canManageCourse: boolean;
  infoBox?: ComponentChildren;
  setLessonComplete(lessonId: UUID, comlpete: boolean): Promise<unknown>;
}

function LessonTitle({ title }: { title: string }) {
  return (
    <header class="mb-9">
      <h1 class="font-display text-4xl leading-snug tracking-tight text-gray-900 dark:text-white break-words">
        {title}
      </h1>
    </header>
  );
}

function shouldDisableNextButton({
  hasQuiz,
  quizStats,
  lesson,
  isAssessmentCompleted,
}: {
  hasQuiz: boolean;
  quizStats: QuizStats;
  lesson: Pick<FullLesson, 'assignmentQuestions' | 'requireAssignmentApproval' | 'minQuizScore'>;
  isAssessmentCompleted: boolean;
}) {
  if (hasQuiz) {
    return lesson.minQuizScore ? !quizStats.isPassed : !isAssessmentCompleted;
  }

  if (lesson.assignmentQuestions) {
    // Assignment status is not important if it doesn't require approval
    // so the next button should be enabled
    if (!lesson.requireAssignmentApproval) {
      return false;
    }
    return lesson.assignmentQuestions.some((x) => x.isRequired && x.status !== 'approved');
  }

  return !isAssessmentCompleted;
}

export function Lesson({
  course,
  lesson,
  canManageCourse,
  state,
  infoBox,
  setState,
  setLessonComplete,
}: Props) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const { setIsMenuVisible, isPinned } = useContext(SideNavContext);
  const { assignmentQuestions, pollQuestions, quiz } = lesson;
  // Client receives only one of the assessment types
  // So it's safe to use the first one available
  const moduleTitle = state.modules[lesson.moduleId].title;

  const isAssessmentCompleted = useMemo(() => {
    const questions = pollQuestions || assignmentQuestions;
    if (questions && questions.length > 0) {
      return questions.some((q) => q.isAnswered);
    }

    if (quiz?.questions && quiz.questions.length > 0) {
      return !!quiz.submittedAt;
    }

    return true;
  }, [pollQuestions, assignmentQuestions, quiz?.questions, quiz?.submittedAt]);

  const quizStats = useQuizStats(quiz?.questions, lesson);

  return (
    <div
      class={`flex grow min-h-full xl:pl-8 font-studentcontent bg-white dark:bg-transparent justify-center ${
        isPinned ? 'xl:justify-start' : ''
      }`}
    >
      <div class="px-6 xl:px-0 max-w-2xl w-full pb-8 lg:m-0">
        <span class="flex items-center text-zinc-500 dark:text-gray-400 w-full pb-2 space-x-2">
          <Button
            class={`inline-flex items-center ${isPinned ? 'lg:cursor-default' : ''}`}
            onClick={() => setIsMenuVisible((x) => !x)}
          >
            {intl('Modules')}{' '}
          </Button>
          <Divider />
          <span>{moduleTitle}</span>
        </span>
        <LessonTitle title={lesson.title || emptyLessonTitle(tenant)} />
        {infoBox}
        <div class="leading-7 text-base student-lesson-content mb-8">
          <ReadonlyMinidoc
            content={lesson.content}
            id={lesson.id}
            shouldRenderPdfViewer
            hideCaptions={course.hideCaptions && !canManageCourse}
          />
        </div>
        {quiz?.questions && quiz.questions.length > 0 && (
          <Quiz
            questions={quiz.questions}
            lesson={lesson}
            isSubmitted={!!quiz.submittedAt}
            allowRetake={lesson.assessmentPreferences.allowRetake}
            onChange={(updater) => {
              setState((s) => {
                const lessonState = s.lessons[lesson.id] as FullLesson;
                const quizState = lessonState?.quiz;
                if (!quizState) {
                  return s;
                }
                const newQuizState = updater({
                  isSubmitted: !!quizState.submittedAt,
                  questions: quizState.questions,
                });
                const newLessonState = {
                  ...lessonState,
                  quiz: {
                    ...lessonState.quiz,
                    questions: newQuizState.questions,
                    submittedAt: newQuizState.isSubmitted ? new Date() : undefined,
                  },
                };
                return {
                  ...s,
                  lessons: {
                    ...s.lessons,
                    [lessonState.id]: newLessonState,
                  },
                };
              });
            }}
          />
        )}
        {pollQuestions && pollQuestions.length > 0 && (
          <Poll
            questions={pollQuestions}
            isSubmitted={isAssessmentCompleted}
            allowRetake={lesson.assessmentPreferences.allowRetake}
            onStatusChange={(questions) => {
              setState((s) => {
                const lessonState = s.lessons[lesson.id] as FullLesson;
                const pollQuestions =
                  questions ||
                  lessonState?.pollQuestions?.map((x) => ({ ...x, isAnswered: false }));
                if (!pollQuestions) {
                  return s;
                }
                return {
                  ...s,
                  lessons: { ...s.lessons, [lesson.id]: { ...lessonState, pollQuestions } },
                };
              });
            }}
          />
        )}
        {assignmentQuestions && assignmentQuestions.length > 0 && (
          <Assignment
            lesson={lesson}
            questions={assignmentQuestions}
            onSubmit={(questions) => {
              setState((s) => {
                const lessonState = s.lessons[lesson.id] as FullLesson;
                const assignmentQuestions = questions?.map((x) => ({ ...x, isAnswered: true }));
                return {
                  ...s,
                  lessons: { ...s.lessons, [lesson.id]: { ...lessonState, assignmentQuestions } },
                };
              });
            }}
          />
        )}
        <DownloadsViewer downloads={lesson.downloads} />

        <section class="w-full text-zinc-700">
          <div class="pb-20 space-y-8 m-auto lg:m-0">
            <LessonFooterNav
              lesson={lesson}
              canManageCourse={canManageCourse}
              nextDisabled={shouldDisableNextButton({
                hasQuiz: !!quiz?.questions?.length,
                quizStats,
                lesson,
                isAssessmentCompleted,
              })}
              state={state}
              setLessonComplete={setLessonComplete}
            />
            <DiscussionSection course={course} discussion={lesson.discussion} />
          </div>
        </section>
      </div>
    </div>
  );
}
