import { groupBy } from 'shared/utils';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { CourseItem } from './course-item';
import { BuyCreditsBanner } from './buy-credits-banner';
import { RootPageLayout } from '@components/root-page-layout';
import { LoadedProps } from '@components/router';
import { defCoursesRoute } from '@components/courses-app-router';

const store = rpx.instantCourses;
const PURCHASED_CATEGORY = 'Purchased';

export const route = defCoursesRoute({
  Page,
  load,
  authLevel: 'guide',
});

export async function load() {
  const [courses, creditsInfo] = await Promise.all([
    store.getInstantCourses({
      isGlobal: false,
    }),
    store.getInstantCourseCredits(),
  ]);
  const categories = groupBy(
    (course) => (course.isPurchased ? PURCHASED_CATEGORY : course.category),
    courses,
  );
  return {
    categories: Object.values(categories),
    creditsInfo,
  };
}

export type InstantCourse = RpxResponse<typeof store.getInstantCourses>[0];

function Page({ state }: LoadedProps<typeof load>) {
  const { categories, creditsInfo } = state;

  return (
    <RootPageLayout activeTab="instantCourses" title="Instant Courses">
      <BuyCreditsBanner
        credits={creditsInfo.credits}
        usage={creditsInfo.usage}
        isUnlimited={creditsInfo.isUnlimited}
      />
      <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">Instant Courses</h1>
      {categories.map((courses) => {
        const firstCourse = courses[0];
        const category = firstCourse.isPurchased ? PURCHASED_CATEGORY : firstCourse.category;

        return (
          <section key={category} class="pt-8">
            <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 mb-6">
              {category} Instant Courses
            </h2>
            <div class="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-12 flex flex-col space-y-6 md:space-y-0">
              {courses.map((course) => (
                <CourseItem key={course.id} course={course} />
              ))}
            </div>
          </section>
        );
      })}
    </RootPageLayout>
  );
}
