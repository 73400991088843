import { AccessFormatForm } from '@components/access-format-radios';
import { BtnPrimary, Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { ProgressBar } from '@components/progress-bar';
import { useRouteParams, useRouter } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { createContext } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { CourseStep, FullCourse } from 'server/types';
import { COURSE_CHECKLIST_STEPS } from 'shared/consts';
import { URLS } from 'shared/urls';
import { EnrollmentStatusStep } from './enrollment-status-step';
import { completeCourseStep, onCourseStepCompleted } from './events';
import { ShareCourseStep } from './share-course-step';
import { StepItem } from './step-item';
import { StripeIntegrationStep } from './stripe-integration-step';
import { IcoExternalLink } from '@components/icons';

interface Props {
  course: Pick<
    FullCourse,
    | 'id'
    | 'title'
    | 'status'
    | 'accessFormat'
    | 'checklistStatus'
    | 'isAbsoluteSchedule'
    | 'checklistCompletedSteps'
    | 'isBundle'
  >;
  minimal?: boolean;
  onHide: () => void;
  showDismissText?: boolean; // should we show a help text to dismiss the slideover
}

const store = rpx.checklist;

export const ChecklistContext = createContext<{
  step?: CourseStep;
  setStep: (step?: CourseStep) => void;
  completedSteps: Set<CourseStep>;
}>({
  step: undefined,
  setStep: () => {},
  completedSteps: new Set(),
});

export function CourseChecklist({ course, minimal, showDismissText = true, onHide }: Props) {
  const { courseId } = useRouteParams();
  const { terminology } = useCurrentTenant();
  const router = useRouter();

  const [completedSteps, setCompletedSteps] = useState(
    () => new Set<CourseStep>(course.checklistCompletedSteps),
  );
  const [step, setStep] = useState<CourseStep | undefined>(() =>
    minimal ? undefined : COURSE_CHECKLIST_STEPS.find((s) => !completedSteps.has(s)),
  );
  const progress = (completedSteps.size / COURSE_CHECKLIST_STEPS.length) * 100;

  async function onStepCompleted({ step }: { step: CourseStep }) {
    if (completedSteps.has(step)) {
      return;
    }

    const newCompletedSteps = new Set(completedSteps);
    newCompletedSteps.add(step);
    setCompletedSteps(newCompletedSteps);

    const nextStep = COURSE_CHECKLIST_STEPS.find((s) => !newCompletedSteps.has(s));
    setStep(nextStep);

    // Checklist is completed if there is no next step
    if (!nextStep) {
      store.setStatus({
        id: courseId,
        status: 'complete',
      });
    }
  }

  function completeStep(step: CourseStep) {
    completeCourseStep(router, step, false);
  }

  function goToUrl(url: string) {
    router.goto(url);
    onHide();
  }

  useEffect(() => onCourseStepCompleted((step) => onStepCompleted(step)), [completedSteps]);

  return (
    <ChecklistContext.Provider
      value={{
        step,
        setStep,
        completedSteps,
      }}
    >
      {showDismissText && (
        <div class="flex justify-end">
          <Button class="my-2 text-center text-xs font-light text-gray-700" onClick={onHide}>
            Already familiar with the basics?{' '}
            <span class="underline text-indigo-400">Hide checklist</span>
          </Button>
        </div>
      )}
      <Case
        when={completedSteps.size < COURSE_CHECKLIST_STEPS.length}
        fallback={
          <div class="flex items-center text-gray-900 mb-8">
            <span class="text-6xl mr-8">🚀</span>
            <div>
              <p class="text-2xl font-bold mb-2">You've completed all the steps.</p>
              <p class="text-lg">
                Finalize your {terminology.course} content and {course.title} is ready to go!
              </p>
            </div>
          </div>
        }
      >
        <div class="flex justify-between mb-8 text-gray-900">
          <h2 class="text-xl font-bold">🚀 Your quick launch guide</h2>
          <ProgressBar progress={progress} height="h-4" width="w-64" showPercent />
        </div>
      </Case>
      <div class="rounded-lg shadow-sm divide-y text-gray-900 whitespace-normal">
        <StepItem
          title="Getting Started with Ruzuku (optional)"
          stepKey="watchVideo"
          onSkip={() => completeStep('watchVideo')}
        >
          <a
            class="inline-flex items-center text-base"
            href="https://support.ruzuku.com/article/837-getting-started-with-ruzuku-guide-version"
            target="_blank"
            rel="noreferrer"
          >
            Tutorial: Get started with Ruzuku's course templates
            <IcoExternalLink class="w-4 h-4 ml-1" />
          </a>
          <div class="flex justify-center">
            <iframe
              src="https://player.vimeo.com/video/845861404?h=4e222ae5de&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="w-full h-48 lg:min-h-128"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Ruzuku course setup"
            ></iframe>
          </div>
        </StepItem>
        <StepItem title={`Control the pace of your ${terminology.course}`} stepKey="accessFormat">
          <p>
            You can control how you want your {terminology.Modules} to be released to your students.
            Don’t worry if you’re unsure — you can change this at any time.
          </p>
          <AccessFormatForm
            courseId={course.id}
            accessFormat={course.accessFormat}
            isAbsoluteSchedule={!!course.isAbsoluteSchedule}
            horizontal
            onSuccess={() => completeStep('accessFormat')}
          >
            <BtnPrimary class="w-full sticky top-0">Save & Continue</BtnPrimary>
          </AccessFormatForm>
        </StepItem>
        <StepItem title={`Create your first ${terminology.module}`} stepKey="firstModule">
          <p>
            Modules are the skeleton of your {terminology.course}: they help you organize your
            content in a step-by-step framework for your students to follow. {terminology.Modules}{' '}
            themselves don't contain any content. Instead, they contain {terminology.Lessons}. And
            each {terminology.Lesson} holds specific content.
          </p>
          <BtnPrimary
            class="w-full"
            onClick={() =>
              goToUrl(
                URLS.guide.coursePage({
                  courseId,
                  page: 'lessons?tour=firstModule',
                }),
              )
            }
          >
            Create a new {terminology.Module}
          </BtnPrimary>
        </StepItem>
        <StepItem title="Add your first lesson" stepKey="firstLesson">
          <p>
            {terminology.Lessons} contain the content for your {terminology.course}. You can include
            text, audio, videos, images, and files (such as PDFs). You can also engage with your
            participants through
            {terminology.discussions}, quizzes, surveys, and homework assignments.
          </p>
          <BtnPrimary
            class="w-full"
            onClick={() =>
              goToUrl(
                URLS.guide.coursePage({
                  courseId,
                  page: 'lessons?tour=firstLesson',
                }),
              )
            }
          >
            Create a new {terminology.Lesson}
          </BtnPrimary>
        </StepItem>
        <StepItem
          title={`Create a ${terminology.Meeting} (optional)`}
          stepKey="createMeeting"
          onSkip={() => completeStep('createMeeting')}
        >
          <p>
            {terminology.Meetings} are a great way to engage with your students. You can host a
            video conference or presentation on Ruzuku's meeting platform. You may also host a{' '}
            {terminology.meeting} on Zoom or any other external platforms.
          </p>
          <BtnPrimary
            class="w-full"
            onClick={() =>
              goToUrl(
                URLS.guide.coursePage({
                  courseId,
                  page: 'meetings/new',
                }),
              )
            }
          >
            Create a new {terminology.Meeting}
          </BtnPrimary>
        </StepItem>
        <StepItem
          title={`Style your ${terminology.course} (optional)`}
          stepKey="courseStyle"
          onSkip={() => completeStep('courseStyle')}
        >
          <p>
            Customize how your {terminology.course} looks for your students by choosing colors,
            typography and images.
          </p>
          <BtnPrimary
            class="w-full"
            onClick={() => {
              completeStep('courseStyle');
              goToUrl(
                URLS.guide.coursePage({
                  courseId,
                  page: 'style',
                }),
              );
            }}
          >
            Go to Style page
          </BtnPrimary>
        </StepItem>
        <StripeIntegrationStep
          courseId={course.id}
          onComplete={() => completeStep('paymentIntegration')}
        />
        <StepItem
          title={`Create a price point for your ${terminology.course}`}
          stepKey="pricePoint"
        >
          <p>
            You create price points so students can register and pay for your {terminology.course}.
            You can have as many different price points as you like. You can choose from different
            payment types based on how you’d like to charge your customers: free, single payment,
            payment plan or subscription.
          </p>
          <BtnPrimary
            class="w-full"
            onClick={() =>
              goToUrl(
                URLS.guide.coursePage({
                  courseId,
                  page: 'prices?tour',
                }),
              )
            }
          >
            Create a new Price Point
          </BtnPrimary>
        </StepItem>
        <StepItem
          title="Customize your sales page (optional)"
          stepKey="salesPage"
          onSkip={() => completeStep('salesPage')}
        >
          <p>
            You can customize the sales page of your {terminology.course} by adding descriptive
            text, videos, pricing options, testimonials, and more. This is optional — if you prefer,
            you can host your sales page on your own website, and just link to Ruzuku for payment
            and signups.
          </p>
          <BtnPrimary
            class="w-full"
            onClick={() =>
              goToUrl(
                URLS.guide.coursePage({
                  courseId,
                  page: 'salespage?tour',
                }),
              )
            }
          >
            Create a Sales Page
          </BtnPrimary>
        </StepItem>
        <StepItem
          title="Customize your welcome email (optional)"
          stepKey="welcomeEmail"
          onSkip={() => completeStep('welcomeEmail')}
        >
          <p>
            You can customize the welcome email that your students receive when they register for
            your {terminology.course}.
          </p>
          <BtnPrimary
            class="w-full"
            onClick={() =>
              goToUrl(
                URLS.guide.coursePage({
                  courseId,
                  page: 'messages/welcome',
                }),
              )
            }
          >
            Customize welcome email
          </BtnPrimary>
        </StepItem>
        <EnrollmentStatusStep course={course} isEnabled={completedSteps.has('pricePoint')} />
        <ShareCourseStep course={course} onComplete={() => completeStep('share')} />
      </div>
    </ChecklistContext.Provider>
  );
}

export * from './events';
