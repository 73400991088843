import { Attachments } from '@components/attachments';
import { BtnPrimary, Button } from '@components/buttons';
import { AsyncForm, AsyncFormContext, FormGroup } from '@components/async-form';
import { Case } from '@components/conditional';
import {
  AssignmentQuestion,
  AssignmentQuestionWithAnswer,
  AssignmentSubmissionStatus,
} from 'server/types';
import { useMinidoc } from '@components/minidoc';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { ManualDom } from '@components/manual-dom';
import { useContext, useEffect, useState } from 'preact/hooks';
import { validationErrorWithMessage } from 'shared/utils';
import { validateAssessmentAnswers } from 'shared/assessments';
import { showError } from '@components/app-error';
import { useRouteParams } from '@components/router';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { IcoBan, IcoCheck, IcoClock, IcoPencil } from '@components/icons';
import { showToast } from '@components/toaster';
import { Pill } from '@components/pill';
import { useIntl } from 'shared/intl/use-intl';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useUnsavedWarning } from '@components/autosaver';
import { uploadCount } from '@components/media-card/media-uploader';
import { rpx } from 'client/lib/rpx-client';
import { FullLesson } from '@components/module-helpers';

interface Props {
  questions: AssignmentQuestionWithAnswer[];
  lesson: Pick<FullLesson, 'requireAssignmentApproval'>;
  onSubmit: (questions: AssignmentQuestionWithAnswer[]) => void;
}

function Status({
  lesson,
  status,
}: {
  lesson: Props['lesson'];
  status: AssignmentSubmissionStatus;
}) {
  const intl = useIntl();

  if (status === 'pending') {
    if (!lesson.requireAssignmentApproval) {
      return (
        <Pill color="gray" centered>
          <IcoCheck class="size-4" /> {intl('Submitted')}
        </Pill>
      );
    }

    return (
      <>
        <IcoClock class="size-4 mr-1" /> {intl('Waiting for review')}
      </>
    );
  }

  if (status === 'rejected') {
    return (
      <Pill color="red" centered>
        <IcoBan class="size-4" /> {intl('Rejected')}
      </Pill>
    );
  }

  return (
    <Pill color="green" centered>
      <IcoCheck class="size-4" /> {intl('Approved')}
    </Pill>
  );
}

function AnswerEditor({
  question,
  answer,
  onChange,
}: {
  question: AssignmentQuestion;
  answer: string;
  onChange: (doc: string) => void;
}) {
  const intl = useIntl();

  const editor = useMinidoc({
    doc: answer,
    middleware: () => [
      placeholder(intl('Type your answer here...')),
      minidocToolbar([
        ...defaultToolbarActions.filter((a) => a.id !== 'h1'),
        toolbarDropdown({
          id: question.id,
          mediaOnly: true,
          intl,
        }),
      ]),
      cardMiddleware([mediaCard]),
      mediaMiddleware({
        isDownloadable: (fileType) => fileType === 'application/pdf',
      }),
    ],
    onChange: (doc) => {
      onChange(doc);
    },
  });

  useEffect(() => {
    (editor.root as any).focus();
  }, [editor.root]);

  return (
    <div>
      <ManualDom el={editor.toolbar.root} />
      <div class="bg-white dark:bg-gray-900 p-4 rounded-b-lg">
        <EditorWrapper class="prose dark:prose-invert" editor={editor} />
      </div>
    </div>
  );
}

function QuestionItem({
  question,
  lesson,
  answer,
  fromCache,
  index,
  submissionDate,
  onChange,
}: {
  question: AssignmentQuestionWithAnswer;
  lesson: Props['lesson'];
  answer: string;
  fromCache: boolean;
  index: number;
  submissionDate?: number;
  onChange: (doc: string) => void;
}) {
  const intl = useIntl();
  const { clearErrors } = useContext(AsyncFormContext);
  const [isEditing, setIsEditing] = useState(fromCache);
  const [status, setStatus] = useState<AssignmentSubmissionStatus | undefined>(question.status);

  useEffect(() => {
    if (!fromCache && submissionDate && isEditing) {
      setIsEditing(false);
      setStatus('pending');
    }
  }, [submissionDate]);

  return (
    <FormGroup prop={question.id}>
      <p class="whitespace-pre-line mb-4">
        {index + 1}. {question.content || 'Question'}
        {question.isRequired && <span class="text-red-600 ml-2 text-2xl">*</span>}
      </p>
      {question.file && <Attachments attachments={[question.file]} />}
      <div class="dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
        <Case
          when={!isEditing}
          fallback={
            <div>
              <AnswerEditor
                question={question}
                answer={answer || ''}
                onChange={(doc) => {
                  if (doc.length) {
                    clearErrors(question.id);
                  }
                  onChange(doc);
                }}
              />
              {fromCache && (
                <div class="px-4 py-2 border-t italic">{intl('Not submitted yet')}</div>
              )}
            </div>
          }
        >
          <Case
            when={!!answer}
            fallback={
              <Button
                class="w-full h-full p-6 text-gray-600 dark:text-gray-300 text-left"
                onClick={() => setIsEditing(true)}
                onFocus={() => setIsEditing(true)}
              >
                {intl('Type your answer')}
              </Button>
            }
          >
            <ReadonlyMinidoc class="px-6 pt-6" content={answer} id={question.id} />
            <div class="px-4 mt-6 py-2 text-sm border-t dark:border-gray-700 space-x-8">
              <div class="flex flex-col opacity-90 space-y-2">
                <div class="flex items-center">
                  <Case
                    when={!fromCache}
                    fallback={<span class="italic">{intl('Not submitted yet')}</span>}
                  >
                    {status !== 'pending' && (
                      <span class="font-bold mr-2">{intl('Guide Feedback')}:</span>
                    )}
                    {status !== undefined && <Status lesson={lesson} status={status} />}
                  </Case>
                  <Case when={status !== 'approved'}>
                    <div class="flex grow justify-end">
                      <Button
                        class="flex items-center ml-1 text-indigo-600 dark:text-sky-400"
                        onClick={() => setIsEditing(true)}
                      >
                        <IcoPencil class="w-4 h-4 mr-1 opacity-75" />
                        {intl('Edit your answer')}
                      </Button>
                    </div>
                  </Case>
                </div>
                {status !== 'pending' && question.feedback && (
                  <ReadonlyMinidoc content={question.feedback} />
                )}
              </div>
            </div>
          </Case>
        </Case>
      </div>
    </FormGroup>
  );
}

function countAllMediaUploads() {
  let count = 0;
  document.querySelectorAll('.minidoc-editor').forEach((el: any) => {
    count += uploadCount(el.$editor);
  });
  return count;
}

const CACHE_KEY = 'assignment-answers';

function getAnswerCache() {
  const answerCache: Record<UUID, string> = JSON.parse(localStorage.getItem(CACHE_KEY) || '{}');
  return answerCache;
}

export function Assignment({ questions, lesson, onSubmit }: Props) {
  const intl = useIntl();
  const { lessonId } = useRouteParams();
  const [numUploads, setNumUploads] = useState(0);
  const [submissionDate, setSubmissionDate] = useState<number | undefined>(() =>
    questions.some((q) => q.answer) ? Date.now() : undefined,
  );
  const [hasChanged, setHasChanged] = useState(false);
  const [answers, setAnswers] = useState(() => {
    const cache = getAnswerCache();
    return questions.reduce(
      (acc, q) => {
        const cacheVal = cache[q.id];
        acc[q.id] = {
          questionId: q.id,
          content: cacheVal || q.answer || '',
          fromCache: !!cacheVal,
        };
        return acc;
      },
      {} as Record<
        UUID,
        {
          questionId: UUID;
          content: string;
          fromCache: boolean;
        }
      >,
    );
  });

  async function submit() {
    const answersArr = Object.values(answers).filter((a) => a.content.length > 0);

    const errors = validateAssessmentAnswers({
      questions,
      answers: answersArr,
      intl,
    });
    if (errors.length) {
      throw validationErrorWithMessage({
        errors,
        message: intl('Please answer all required questions'),
      });
    }

    try {
      await rpx.assessments.answerAssignmentQuestions({
        lessonId,
        answers: answersArr.map((a) => ({
          questionId: a.questionId,
          content: a.content,
        })),
      });
      showToast({
        type: 'ok',
        title: intl('Assignments submitted'),
        message: lesson.requireAssignmentApproval
          ? intl('You will get a notification when the assignment is graded.')
          : '',
      });
      setSubmissionDate(Date.now());
      setHasChanged(false);

      // We can safely delete the answers from local storage
      const newCache = getAnswerCache();
      const newAnswers = { ...answers };
      questions.forEach((q) => {
        delete newCache[q.id];
        answers[q.id].fromCache = false;
      });

      setAnswers(newAnswers);
      localStorage.setItem(CACHE_KEY, JSON.stringify(newCache));

      onSubmit(
        questions.map((q) => ({
          ...q,
          answer: answers[q.id].content,
        })),
      );
    } catch (err) {
      showError(err);
    }
  }

  useUnsavedWarning(() => countAllMediaUploads() > 0);

  const canSubmit = numUploads === 0 && (!submissionDate || hasChanged);

  return (
    <AsyncForm
      class="drop-shadow-xl shadow-xs bg-white dark:bg-gray-800 border border-gray-100 dark:border-transparent md:-mx-8 rounded-lg dark:border-gray-700 my-10 p-10"
      onSubmit={submit}
    >
      <header class="flex flex-col">
        <h2 class="text-lg font-medium dark:text-gray-200">{intl('Assignment')}</h2>
        <p class="text-xs text-gray-600">{intl('Only the course guide can see your answers.')}</p>
      </header>

      <section
        class="mt-6 dark:text-gray-400"
        ref={(el: any) => {
          if (!el || el.$init) {
            return;
          }
          el.$init = true;
          el.addEventListener('mini:media', () => setNumUploads(countAllMediaUploads()));
        }}
      >
        <div class="space-y-16">
          {questions.map((question, index) => (
            <QuestionItem
              key={question.id}
              question={question}
              lesson={lesson}
              index={index}
              answer={answers[question.id]?.content || ''}
              fromCache={answers[question.id]?.fromCache}
              submissionDate={submissionDate}
              onChange={(doc) => {
                setAnswers({
                  ...answers,
                  [question.id]: {
                    questionId: question.id,
                    content: doc,
                    fromCache: true,
                  },
                });
                setHasChanged(true);
                const answerCache = getAnswerCache();
                localStorage.setItem(
                  CACHE_KEY,
                  JSON.stringify({
                    ...answerCache,
                    [question.id]: doc,
                  }),
                );
              }}
            />
          ))}
        </div>
        <footer class="flex justify-end pt-10">
          <BtnPrimary class="w-full p-4 dark:bg-gray-900" disabled={!canSubmit} type="submit">
            {numUploads > 0 &&
              intl('Waiting for {numUploads:number} {numUploads | pluralize upload uploads}', {
                numUploads,
              })}
            {numUploads === 0 &&
              (submissionDate ? intl('Resubmit Assignment') : intl('Submit Assignment'))}
          </BtnPrimary>
        </footer>
      </section>
    </AsyncForm>
  );
}
