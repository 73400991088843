import { IcoCheck, IcoGripper, IcoPaperclip, IcoTrash, IcoX } from '@components/icons';
import { useEffect, useRef, useState } from 'preact/hooks';
import { filepicker } from 'client/components/filepicker';
import { AssessmentChoice } from './index';
import { showError } from '@components/app-error';
import { AttachmentContent } from '@components/attachments/attachment-item';
import { Button } from '@components/buttons';

interface Props {
  choice: AssessmentChoice;
  placeholder?: string;
  type: 'radio' | 'checkbox';
  canDelete: boolean;
  autoFocus: boolean;
  draggable?: boolean;
  isQuiz?: boolean;
  onDelete: () => void;
  onEnter: () => void;
  toggleChoice?: (choiceId: UUID) => void;
  onChange: (updates: Partial<AssessmentChoice>) => void;
}

export function ChoiceItem({
  choice,
  type,
  isQuiz,
  placeholder,
  draggable,
  canDelete,
  autoFocus,
  toggleChoice,
  onDelete,
  onEnter,
  onChange,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  async function attachFile() {
    try {
      const result = await filepicker();
      if (result) {
        onChange({
          file: {
            id: result.fileId,
            type: result.type,
            name: result.name,
            url: result.filePath,
          },
        });
      }
    } catch (err) {
      showError(err);
    }
  }

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [inputRef, autoFocus]);

  return (
    <div class="group mb-2">
      <div class="flex items-center relative mb-1" onClick={() => inputRef.current?.focus()}>
        <span
          class={`invisible ${draggable ? 'group-hover:visible' : ''} text-gray-400 cursor-move`}
          draggable={draggable}
        >
          <IcoGripper />
        </span>
        <input
          type={type}
          class="ml-1 mr-2 h-5 w-5 border-gray-500"
          checked={isQuiz && choice.isCorrect}
          tabIndex={-1}
          onChange={isQuiz && toggleChoice ? () => toggleChoice(choice.id) : undefined}
        />
        <input
          ref={inputRef}
          type="text"
          class="block grow md:pr-16 sm:text-sm border-transparent group-hover:border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          value={choice.content}
          placeholder={placeholder}
          maxLength={2048}
          onInput={(e: any) => onChange({ content: e.target.value })}
          onBlur={() => setIsEditing(false)}
          onFocus={() => {
            setIsEditing(true);

            // "Option 1" is what we assign to first choice content automatically.
            // So we're selecting the whole text to edit it when the user clicks on it.
            if (choice.content === 'Option 1') {
              inputRef.current?.select();
            }
          }}
          onKeyDown={(e: any) => {
            if (e.code === 'Enter') {
              onEnter();
              return;
            }

            // Delete the choice if the user presses the delete key
            // when the content is empty.
            if (canDelete && choice.content === '') {
              if (e.code === 'Backspace' || e.code === 'Delete') {
                onDelete();
              }
            }
          }}
        />
        {isQuiz && choice.isCorrect && (
          <div class="pl-2 pr-1">
            <IcoCheck class="w-6 h-6 text-green-900" />
          </div>
        )}
        <div
          class={`${
            isEditing ? '' : 'invisible'
          } group-hover:visible group-focus:visible absolute inset-y-0 right-8 pr-1 flex items-center`}
        >
          <Button onClick={attachFile} tabIndex={-1} class="hover:bg-zinc-100 p-2 rounded-sm">
            <IcoPaperclip class="w-4 h-4 opacity-75" />
          </Button>
          {canDelete && (
            <Button
              type="button"
              tabIndex={-1}
              onClick={onDelete}
              class="hover:bg-zinc-100 p-2 rounded-sm"
            >
              <IcoTrash class="w-4 h-4 opacity-75" />
            </Button>
          )}
        </div>
      </div>
      {choice.file && (
        <div class="flex justify-center items-center h-60 w-60 max-h-60 mt-4 ml-12 bg-gray-50 rounded-sm p-2 relative">
          <AttachmentContent attachment={choice.file} />
          <Button
            class="absolute invisible group-hover:visible -top-2 -right-2 p-2 rounded-full bg-gray-300 shadow-sm"
            onClick={() => onChange({ file: undefined })}
          >
            <IcoX class="w-5 h-5 text-black" />
          </Button>
        </div>
      )}
    </div>
  );
}
