/**
 * This file contains the UI for editing a course's payments.
 */

import { coursePrices, URLS } from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { GuideCoursePage, GuideProductPage } from '@components/guide-course-page';
import { ManagePricesPage } from './components/prices-page';
import { urls as manageStudents } from 'client/pages/guide-course-students/urls';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defCoursesRoute } from '@components/courses-app-router';
const store = rpx.prices;

export const route = defCoursesRoute({
  load,
  Page,
  authLevel: 'guide',
});

async function load(route: RouteLoadProps) {
  const { courseId, priceId } = route.params;
  const isNew = priceId === 'new';
  const result = await store.initForCourse({ courseId, priceId: isNew ? undefined : priceId });
  if (result.course.level !== 'guide') {
    route.router.goto(URLS.guide.lessons({ courseId }));
  }
  return result;
}

function PageContent(props: LoadedProps<typeof load>) {
  const { course } = props.state;
  const courseId = course.id;
  const baseUrl = URLS.guide.baseUrl(course);
  const pricesUrl = `${baseUrl}/prices`;

  return (
    <ManagePricesPage
      {...props.state}
      courseId={courseId}
      supportsStripe={props.state.supportsStripe}
      supportsPaypal={props.state.supportsPaypal}
      isCorePrice={false}
      priceUrl={({ priceId }) => `${pricesUrl}/${priceId}`}
      couponUrl={({ couponId }) => `${baseUrl}/coupons/${couponId}`}
      checkoutUrl={({ priceId, couponId }) =>
        coursePrices.checkoutUrl({ courseId, priceId, couponId })
      }
      pricesUrl={pricesUrl}
      newUrl={`${pricesUrl}/new`}
      signupUrl={({ userId }) =>
        manageStudents.showStudent({
          userId,
          courseId,
          baseUrl: course.isProduct
            ? manageStudents.productsBaseUrl
            : manageStudents.coursesBaseUrl,
        })
      }
      stripeMetadata={{
        courseTitle: course.title,
        courseId: course.id,
      }}
    />
  );
}

function Page(props: LoadedProps<typeof load>) {
  if (props.state.course.isProduct) {
    return (
      <GuideProductPage product={props.state.course} page="prices">
        <PageContent {...props} />
      </GuideProductPage>
    );
  }
  return (
    <GuideCoursePage course={props.state.course} type="prices">
      <PageContent {...props} />
    </GuideCoursePage>
  );
}
