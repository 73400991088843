import { IcoBook, IcoCheckCircle, IcoLogo, IcoVideoCamera } from '@components/icons';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';
import { GuideGoals } from './guide-goals';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { AuthForm } from '@components/checkout';
import { TestimonialWall } from './testimonial-wall';
import { TestimonialWithFeature, type FeatureTestimonial } from './testimonial-with-feature';
import { showModalForm } from '@components/modal-form';
import { StandardDialog } from '@components/dialog';
import { CurrentUser, useAuth } from 'client/lib/auth';
import { rpx } from 'client/lib/rpx-client';
import { FREE_CORE_PRICE_ID } from 'shared/ids';
import { defRoute } from '@components/router';

const steps = ['incentives', 'goals', 'account', 'success'] as const;
type Step = (typeof steps)[number];

export const route = defRoute({
  isPublic: true,
  Page,
});

const StepConfig: Record<
  Step,
  {
    id: Step;
    index: number;
    title?: string;
    iconPath?: string;
    isBigger?: boolean;
    featureTestimonial?: FeatureTestimonial;
  }
> = {
  incentives: {
    id: 'incentives',
    index: 0,
    title: 'The Simple, Fun Way to Build Your Online Course & Community',
    iconPath: 'undraw_welcoming_re_x0qo.svg',
  },
  goals: {
    id: 'goals',
    index: 1,
    title: 'What do you want to create?',
    isBigger: true,
    featureTestimonial: {
      body: `Ruzuku truly is the best, it has everything you need in one place and makes it <strong>super easy to take an idea and blow it out into a successful class.</strong>`,
      imagePath: 'checklist.png',
      author: {
        name: 'Shannon Kaiser',
        imagePath: 'shannon-kaiser.png',
        description: `Uses Ruzuku to help people fall in love with their lives at <a href="https://www.playwiththeworld.com" target="_blank" rel="noopener noreferrer">playwiththeworld.com</a>`,
      },
    },
  },
  account: {
    id: 'account',
    index: 2,
    featureTestimonial: {
      body: `Building the course inside Ruzuku has been simple and enjoyable… the <strong>clean look and user-friendly interface for course participants</strong> is a total dream.`,
      imagePath: 'modules-nav.png',
      author: {
        name: 'Joan Dempsey',
        imagePath: 'joan-dempsey.png',
        description: `Uses Ruzuku to help writers learn how to create valuable, publishable work`,
      },
    },
  },
  success: {
    id: 'success',
    index: 3,
    featureTestimonial: {
      body: `I was able to <strong>create and launch a new course in less than an hour</strong>! It is SO easy to use, and the ability to make a copy of an existing course saves a ton of time!`,
      imagePath: 'course-pace.png',
      author: {
        name: 'Susan Mazza',
        imagePath: 'susan-mazza.jpg',
        description: `Uses Ruzuku to help leaders create workplaces where people thrive at <a href="https://randomactsofleadership.com" target="_blank" rel="noopener noreferrer">randomactsofleadership.com</a>`,
      },
    },
  },
};

function Incentive({
  title,
  icon,
  description,
  smaller,
}: {
  title: string;
  icon: ComponentChildren;
  description: string;
  smaller?: boolean;
}) {
  return (
    <div class="flex items-center gap-3">
      <div
        class={`flex items-center justify-center bg-blue-50 text-blue-600 rounded-full ${
          smaller ? 'p-2' : 'p-3'
        }`}
      >
        {icon}
      </div>
      <div>
        <p class={`${smaller ? 'text-sm' : 'text-base'} font-bold leading-5`}>{title}</p>
        <p class="text-gray-600">{description}</p>
      </div>
    </div>
  );
}

function StepContainer({
  step,
  children,
  onStepSelected,
}: {
  step: Step;
  children: ComponentChildren;
  onStepSelected: (step: Step) => void;
}) {
  const config = StepConfig[step];

  return (
    <div
      class={`flex flex-col items-center border-t h-screen bg-white left-0 right-0 sm:border-none gap-4 p-8 relative w-full lg:w-1/2 mx-auto`}
    >
      <div class="flex flex-col gap-4 w-full md:w-lg md:max-w-lg">
        <IcoLogo class="h-8 mb-4" />
        {config.id !== 'success' && (
          <div class="mx-auto flex justify-evenly w-full gap-3">
            {steps.map((step, index) => {
              if (step === 'success') {
                return null;
              }
              return (
                <Button
                  key={index}
                  class={`rounded-full h-1 w-full ${
                    config.index >= index ? 'bg-blue-500' : 'bg-gray-200'
                  }`}
                  disabled={config.index < index}
                  onClick={() => onStepSelected(step)}
                />
              );
            })}
          </div>
        )}
      </div>
      <div
        class={`flex flex-col w-full grow bg-white py-4 sm:rounded-lg relative ${
          config.isBigger ? '' : 'max-w-2xl'
        }`}
      >
        {config.iconPath && (
          <img
            class="w-48 h-48 mb-8"
            data-attribution="https://undraw.co"
            src={`${RUZUKU_ASSETS_BASE_URL}/${config.iconPath}`}
          />
        )}
        {!!config.title && <h3 class="text-3xl font-bold text-gray-900 mb-2">{config.title}</h3>}
        {children}
      </div>
    </div>
  );
}

function SuccessStep() {
  function showScheduleCallModal() {
    showModalForm(({ resolve }) => {
      return (
        <StandardDialog title="Book an appointment" contentWidth onClose={resolve}>
          <iframe
            src="https://ruzuku.appointlet.com/s/7360/peter-smiley"
            width="640"
            height="1024"
            frameBorder="0"
          ></iframe>
        </StandardDialog>
      );
    });
  }

  return (
    <>
      <img
        class="w-64 h-64 mb-4"
        data-attribution="https://undraw.co"
        src={`${RUZUKU_ASSETS_BASE_URL}/undraw_party_re_nmwj.svg`}
      />
      <h3 class="text-3xl font-bold text-gray-900 mb-4">Welcome to Ruzuku!</h3>
      <div class="space-y-2 text-base">
        <p>
          You're all set! Your free account is ready and you can start building your course right
          away.
        </p>
        <p>
          Any questions? We're here to help! You may{' '}
          <Button class="text-indigo-600 underline" onClick={showScheduleCallModal}>
            schedule a call with us
          </Button>
          .
        </p>
      </div>
      <footer class="flex items-start gap-4 mt-8">
        <BtnSecondary class="rounded-full gap-2 pl-12 pr-10" onClick={showScheduleCallModal}>
          <span>Book a call</span>
          <IcoVideoCamera class="w-5 h-5" />
        </BtnSecondary>
        <BtnPrimary
          class="rounded-full gap-3 pl-12 pr-10"
          onClick={() => {
            // A full page reload to make sure the user data is fetched correctly
            location.assign('/welcome');
          }}
        >
          <span>Start building</span>⤑
        </BtnPrimary>
      </footer>
    </>
  );
}

const INCENTIVES_TEXT = `We're excited to help you launch your course or coaching program. To help you succeed, we'll share three gifts with you when you sign up…`;

function Page() {
  const auth = useAuth();
  const isGuide = auth.user && auth.user.level !== 'student';
  const [step, setStep] = useState<Step>(isGuide ? 'success' : 'incentives');
  const [goalItems, setGoalItems] = useState<string[]>([]);
  const stepConfig = StepConfig[step];

  async function createGuideAccount(user: CurrentUser) {
    await rpx.myCorePayments.purchase({
      priceId: FREE_CORE_PRICE_ID,
    });
    await rpx.myCorePayments.provisionGuideAccount({
      priceId: FREE_CORE_PRICE_ID,
      goals: goalItems,
    });
    auth.setUser(user);
    setStep('success');
  }

  return (
    <div class="min-h-screen bg-linear-to-b from-indigo-100 to-white relative">
      <div class="grow w-full">
        <section class="flex">
          <StepContainer step={step} onStepSelected={(selection) => setStep(selection)}>
            {step === 'incentives' && (
              <>
                <p class="text-base">{INCENTIVES_TEXT}</p>
                <div class="flex flex-col gap-8 my-8">
                  <div class="flex flex-col gap-4">
                    <Incentive
                      title="Training"
                      description="5 Steps to Your Online Course"
                      icon={<IcoBook class="w-6 h-6" />}
                    />
                    <Incentive
                      title="Course Clarity Call"
                      description="A 30-minute call with a Course Expert"
                      icon={<IcoVideoCamera class="w-6 h-6" />}
                    />
                    <Incentive
                      title="Course Launch Checklist"
                      description="A step-by-step guide to launching your course"
                      icon={<IcoCheckCircle class="w-6 h-6" />}
                    />
                  </div>
                </div>
                <footer class="flex mt-4">
                  <BtnPrimary
                    class="rounded-full gap-3 px-12 pr-10"
                    onClick={() => setStep('goals')}
                  >
                    <span>Get Started Now - It's Free!</span>⤑
                  </BtnPrimary>
                </footer>
              </>
            )}
            {step === 'goals' && (
              <GuideGoals
                selectedItems={goalItems}
                setSelectedItems={setGoalItems}
                onContinue={() => {
                  if (auth.user?.level === 'student') {
                    createGuideAccount({
                      ...auth.user,
                      level: 'guide',
                    });
                  } else {
                    setStep('account');
                  }
                }}
              />
            )}
            {step === 'account' && (
              <div class="p-8">
                <div class="p-4 bg-gray-50 rounded-lg mb-8">
                  <p class="mb-4">{INCENTIVES_TEXT}</p>
                  <div class="flex flex-col gap-8 mt-8">
                    <div class="flex flex-col gap-4">
                      <Incentive
                        title="Training"
                        description="5 Steps to Your Online Course"
                        icon={<IcoBook class="w-5 h-5" />}
                        smaller
                      />
                      <Incentive
                        title="Course Clarity Call"
                        description="A 30-minute call with a Course Expert"
                        icon={<IcoVideoCamera class="w-5 h-5" />}
                        smaller
                      />
                      <Incentive
                        title="Course Launch Checklist"
                        description="A step-by-step guide to launching your course"
                        icon={<IcoCheckCircle class="w-5 h-5" />}
                        smaller
                      />
                    </div>
                  </div>
                </div>
                <AuthForm
                  // Initiate the auth user with the guide level.
                  // This is needed as changing the user level later is complicated.
                  loginLevel="guide"
                  onSubmit={createGuideAccount}
                />
              </div>
            )}
            {step === 'success' && <SuccessStep />}
          </StepContainer>
          <div class="hidden lg:flex flex-col gap-8 p-6 w-1/2 items-center">
            {!stepConfig.featureTestimonial && <TestimonialWall />}
            {stepConfig.featureTestimonial && (
              <TestimonialWithFeature testimonial={stepConfig.featureTestimonial} />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
