import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabWrapper } from '@components/admin-nav';
import { TenantsList } from './tenants-list';
import { defRoute } from '@components/router';

function Page() {
  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="tenants" />
        <AdminTabWrapper>
          <h2 class="text-2xl mb-4">Tenants</h2>
          <TenantsList />
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

export const route = defRoute({
  authLevel: 'superadmin',
  Page,
});
