import { AsyncForm, FormGroup } from '@components/async-form';
import { Password } from '@components/password';
import { rpx } from 'client/lib/rpx-client';
import { useAuth, useCurrentUser } from '@components/router/session-context';
import { UserProfileIcon } from '@components/avatars';
import { ErrorPage } from '@components/error-page';
import { URLS } from 'shared/urls';
import { detectTimezone } from 'shared/dateutil';
import { Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { DefaultSpinner } from '@components/spinner';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { TopBar } from '@components/top-bar';
import { useTryAsyncData } from 'client/lib/hooks';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router';

type Props = LoadedProps<typeof load>;

export const route = defRoute({
  isPublic: true,
  load({ params }) {
    return rpx.auth.getOneClickUser({ resetCode: params.code });
  },
  Page(props: Props) {
    const intl = useIntl();
    const user = useCurrentUser();
    const isError = !user && !props.state?.email;

    if (isError) {
      return <ErrorPage title={intl('Wrong or expired login code.')} />;
    }
    if (user) {
      return <AuthenticatedPage {...props} />;
    }
    if (props.params.skipReset) {
      return <AutoLoginPage {...props} />;
    }
    return <PasswordResetPage {...props} />;
  },
});

function load(route: RouteLoadProps) {
  return rpx.auth.getOneClickUser({ resetCode: route.params.code });
}

function performRedirect(props: Props) {
  const { courseId, courseTitle, redirect } = props.params;
  props.router.goto(
    courseId && courseTitle
      ? URLS.student.course({
          course: {
            id: courseId,
            title: courseTitle,
          },
        })
      : redirect || '/',
  );
}

/**
 * Login with the one-click code without requiring password reset. This is done when linking
 * users from core to a tenant on which they have content.
 */
function AutoLoginPage(props: Props) {
  const intl = useIntl();
  const auth = useAuth();

  useTryAsyncData(async () => {
    await auth.login(rpx.auth.oneClickAutoLogin({ resetCode: props.params.code }));
    performRedirect(props);
  }, []);

  return (
    <FixedPage title={intl('Logging in...')}>
      <FixedContent class="bg-white">
        <TopBar />
        <DefaultSpinner />
      </FixedContent>
    </FixedPage>
  );
}

function PasswordResetPage(props: Props) {
  const intl = useIntl();
  const auth = useAuth();

  const { newUser } = props.params;
  // This page is used by Zapier's `enrollStudent` action
  // and demo guide signup flow, too.
  // It means the user has never used Ruzuku in that case
  // so we need to modify some wordings.
  const isNewUser = !!newUser;
  const passwordLabel = isNewUser ? intl('Password') : intl('New password');

  return (
    <FixedPage title={intl('Create a new password')}>
      <FixedContent class="bg-white">
        <TopBar />

        <AsyncForm
          class="max-w-96 m-auto"
          onSubmit={async (data) => {
            await auth.login(
              rpx.auth.oneClickLogin({
                ...data,
                timezone: isNewUser ? detectTimezone() : undefined,
              }),
            );
            performRedirect(props);
          }}
        >
          <div class="text-gray-500 flex items-center mb-8 border rounded-md p-4 bg-gray-50">
            <UserProfileIcon size="h-12 w-12" user={props.state} />
            <span class="ml-4">
              <strong class="block">{props.state.name}</strong>
              <span>{props.state.email}</span>
            </span>
          </div>

          <h2 class="text-xl text-gray-600 mb-4 text-center">
            {isNewUser ? intl('Welcome!') : intl('Welcome Back!')}
          </h2>
          <p class="text-gray-600 mb-4 text-center">
            {intl('To continue, please create a {passwordLabel:string}.', {
              passwordLabel: passwordLabel.toLowerCase(),
            })}
          </p>

          <FormGroup prop="newPassword" class="w-full mb-4">
            <Password
              name="newPassword"
              placeholder={passwordLabel}
              class="bg-transparent"
              autocomplete="new-password"
            />
          </FormGroup>
          <FormGroup prop="confirmPassword" class="w-full mb-4">
            <Password
              name="confirmPassword"
              placeholder={intl('Confirm {passwordLabel:string}', {
                passwordLabel,
              })}
              class="bg-transparent"
              autocomplete="new-password"
            />
          </FormGroup>
          <input type="hidden" name="resetCode" value={props.params.code} />
          <Button class="btn-primary w-full">{intl('Save password & sign in')}</Button>
        </AsyncForm>
      </FixedContent>
    </FixedPage>
  );
}

function AuthenticatedPage(props: Props) {
  useAsyncEffect(async () => {
    performRedirect(props);
  }, []);

  return (
    <div class="w-screen h-screen flex items-center justify-center">
      <DefaultSpinner />
    </div>
  );
}
