/**
 * Helper components for handling form inputs and validation.
 */

import { FormGroup } from '@components/async-form';
import { autoFocusSelf } from 'client/utils/autofocus';
import { ComponentChildren, h } from 'preact';
import { StateUpdater } from 'preact/hooks';
import { Dispatch } from 'react';

export function Field(props: {
  name: string;
  title?: ComponentChildren;
  subtitle?: ComponentChildren;
  children: ComponentChildren;
}) {
  return (
    <FormGroup prop={props.name}>
      <label class="flex flex-col gap-1">
        {props.title && <span>{props.title}</span>}
        {props.subtitle && <span class="text-gray-500 block">{props.subtitle}</span>}
        {props.children}
      </label>
    </FormGroup>
  );
}

export function eventToState<T>(setState: Dispatch<StateUpdater<T>>) {
  return (e: any) => {
    setState((s) => ({ ...s, [e.target.name]: e.target.value }));
  };
}

export function InputField({
  name,
  title,
  subtitle,
  suffix,
  prefix,
  fullWidth,
  inputWidth,
  autoFocus,
  ...props
}: Omit<h.JSX.InputHTMLAttributes<HTMLInputElement>, 'title'> & {
  name: string;
  fullWidth?: boolean;
  inputWidth?: string;
  title?: ComponentChildren;
  subtitle?: ComponentChildren;
  suffix?: ComponentChildren;
  prefix?: ComponentChildren;
}) {
  return (
    <Field name={name} title={title} subtitle={subtitle}>
      <span
        class={`border border-gray-300 rounded-sm inline-flex ${fullWidth ? 'w-full' : 'w-fit'}`}
      >
        {prefix && (
          <span class="flex items-center whitespace-nowrap px-4 rounded-l text-gray-600 bg-gray-50 border-r border-gray-300">
            {prefix}
          </span>
        )}
        <input
          type="text"
          name={name}
          ref={autoFocus ? autoFocusSelf : undefined}
          class={`ruz-input px-4 p-3 border-0 focus:z-10 focus:ring-2 transition-all ${
            suffix ? 'rounded-r-none' : ''
          } ${prefix ? 'rounded-l-none' : ''} ${inputWidth || ''}`}
          {...props}
        />
        {suffix && (
          <span class="flex items-center whitespace-nowrap px-4 rounded-r text-gray-600 bg-gray-50 border-l border-gray-300">
            {suffix}
          </span>
        )}
      </span>
    </Field>
  );
}
