import { render } from 'preact-render-to-string';
import {
  Line,
  BasicEmailWrapper,
  DoubleLine,
  PrimaryAction,
  Heading,
  SupportFooter,
  SubHeading,
  Mailto,
  Italic,
} from './shared';
import { CourseRow, Tenant, UserProfileRow } from 'server/types';
import { URLS } from 'shared/urls';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

/**
 * Welcome email sent to user after course signup.
 */
export function WelcomeToCourse({
  course,
  guide,
  tenant,
}: {
  course: Pick<CourseRow, 'id' | 'title'>;
  guide: Pick<UserProfileRow, 'name' | 'email' | 'displayName'>;
  tenant: Pick<Tenant, 'name' | 'domain'>;
}) {
  const courseUrl = URLS.student.course({
    course,
    domain: tenant.domain,
  });
  return render(
    // Not sure if we want to include the support footer here; we already have the support link in the content
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>
        Thanks for joining {course.title} with {guide.name || ''}!
      </Heading>
      <SubHeading>How to access your course</SubHeading>
      <Line>You can access your course at any time, here:</Line>
      <PrimaryAction href={courseUrl}>Visit "{course.title}"</PrimaryAction>
      <Line>This course is hosted on the Ruzuku course platform.</Line>
      <SubHeading>How to use Ruzuku</SubHeading>
      <Line>
        In case you’re new to Ruzuku, here’s a quick video on how to get around. It’s completely
        optional to watch, but you may find it handy!
      </Line>
      <Line>
        <a href="https://support.ruzuku.com/article/834-how-to-navigate-a-course-on-the-ruzuku-platform">
          <img
            src={`${RUZUKU_ASSETS_BASE_URL}/play-tutorial-video.png`}
            title="Play tutorial video"
            style="width:679px;max-width:100%;margin:auto;"
          />
        </a>
      </Line>

      <DoubleLine> </DoubleLine>
      <SubHeading>Need any help?</SubHeading>
      <Line>
        If you have any questions about your course, you can reach your course guide here:{' '}
        <Mailto email={guide.email} />
      </Line>
      <Line>
        And if you need any help with Ruzuku, drop us a line anytime:{' '}
        <Mailto email="support@ruzuku.com" />
      </Line>
      <Line>Thanks for using Ruzuku!</Line>
      <Line>—The Ruzuku Team</Line>
      <Line>
        <Italic>
          P.S. --
          <br />
          If you’re considering running your very own online course, Ruzuku can help :). You can
          learn more, and get started with a 100% free account, here:{' '}
        </Italic>
      </Line>
      <Line>
        <a href="https://www.ruzuku.com">https://www.ruzuku.com</a>
      </Line>
    </BasicEmailWrapper>,
  );
}

/**
 * Welcome email for Ruzuku Free tier.
 */
export function WelcomeToFreeTier({
  tenant,
  ctaUrl,
  shouldResetPassword,
}: {
  tenant: Pick<Tenant, 'name' | 'domain'>;
  ctaUrl: string;
  shouldResetPassword: boolean;
}) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>
        Thanks for joining the Ruzuku community — we're delighted to have you with us!
      </Heading>
      <Line>With our free account...</Line>
      <ul>
        <li>You can create unlimited courses and lessons at no charge.</li>
        <li>You can also enroll up to 5 students (free or paid).</li>
      </ul>

      <SubHeading>Next steps</SubHeading>
      <ul style="margin-bottom:8px;">
        <li>
          <a href={`https://${tenant.domain}/account/billing`}>Upgrade your account</a> to start
          signing up more than 5 students.
        </li>
        <li>
          Or <a href="https://support.ruzuku.com/">contact us</a> for help anytime! Just reply to
          this email, we'd love to hear from you :)
        </li>
        {shouldResetPassword && (
          <li>
            You can <a href={`https://${tenant.domain}/login`}>log into your account</a> by having a
            link emailed to you whenever you need one (just use the "forgot password" link when
            logging in).
          </li>
        )}
      </ul>
      {shouldResetPassword && (
        <Line>
          You may also find it convenient to set a password for your account so you can log in
          quickly in the future.
        </Line>
      )}
      <PrimaryAction href={ctaUrl}>
        {shouldResetPassword
          ? 'Set a password and access your account'
          : 'Start building your course for free'}
      </PrimaryAction>
    </BasicEmailWrapper>,
  );
}

/**
 * Welcome email for one of Ruzuku paid tiers.
 */
export function WelcomeToPaidTier({
  ctaUrl,
  shouldResetPassword,
}: {
  ctaUrl: string;
  shouldResetPassword: boolean;
}) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>
        Thanks for joining the Ruzuku community — we're delighted to have you with us!
      </Heading>
      {shouldResetPassword && (
        <>
          <Line>
            You may find it convenient to set a password for your account so you can log in quickly
            in the future.
          </Line>
          <Line>P.S. Any questions or need help? Just hit reply, we'd love to hear from you!</Line>
        </>
      )}
      {!shouldResetPassword && (
        <Line>
          <a href="https://support.ruzuku.com/">Contact us</a> for help anytime! Just reply to this
          email, we'd love to hear from you :)
        </Line>
      )}
      <PrimaryAction href={ctaUrl}>
        {shouldResetPassword
          ? 'Set a password and access your account'
          : 'Start building your courses'}
      </PrimaryAction>
    </BasicEmailWrapper>,
  );
}
