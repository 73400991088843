import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { IcoDownload } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { useRouteParams } from '@components/router';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { assessmentReviewUrl } from 'client/pages/guide-course-assessments/urls';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { LessonRow } from 'server/types';
import { timeago } from 'shared/dateutil';
import { pluralize } from 'shared/formatting';

type Submission = RpxResponse<typeof rpx.assessments.getAssignmentSubmissions>[0];

interface Props {
  lesson: Pick<LessonRow, 'id' | 'title'>;
}

export function AssignmentSubmissions({ lesson }: Props) {
  const { courseId } = useRouteParams();
  const [isLoading, setIsLoading] = useState(true);
  const [submissions, setSubmissions] = useState<Submission[]>([]);

  useAsyncEffect(async () => {
    try {
      const submissions = await rpx.assessments.getAssignmentSubmissions({
        lessonId: lesson.id,
      });
      setSubmissions(submissions);
    } catch (e) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div class="space-y-8">
      <div class="flex justify-between mt-4">
        <h3 class="text-lg font-bold">
          {submissions.length} {pluralize('submission', submissions.length)}
        </h3>
        {submissions.length > 0 && (
          <Button
            class="inline-flex items-center gap-1"
            download={`assignments-${lesson.title}.csv`}
            href={`/csv/lessons/${lesson.id}/assignments.csv`}
          >
            <IcoDownload />
            Download CSV
          </Button>
        )}
      </div>
      <div class="space-y-2">
        {submissions.map((submission) => (
          <Button
            key={submission.student.id}
            class="block text-left text-base text-inherit bg-white shadow-sm px-8 py-4 rounded-sm group relative hover:bg-indigo-50"
            href={assessmentReviewUrl({
              courseId,
              lessonId: lesson.id,
              userId: submission.student.id,
              type: 'assignment',
            })}
          >
            {submission.student.name} submitted an assignment {timeago(submission.updatedAt)}.
          </Button>
        ))}
      </div>
    </div>
  );
}
