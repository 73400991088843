import { useCurrentUser } from 'client/lib/auth';
import { AvailableBlock, Props as AvailableProps } from './availability-summary';
import { RuzcalMgmtPage } from './mgmt-page';
import { ListAddAction, ListContainer, PageContent, PageHeading, PageSection } from './common';
import { rpx } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { Button } from '@components/buttons';
import { IcoTrash } from '@components/icons';
import { useState } from 'preact/hooks';
import { showDialog } from '@components/dialog';
import { showError } from '@components/app-error';
import { LoadedProps, defRoute } from '@components/router';

type Props = LoadedProps<typeof load>;

type Data = Props['state'];

export const route = defRoute({ authLevel: 'superadmin', load, Page });

async function load() {
  const [availability, overrides] = await Promise.all([
    rpx.ruzcal.getAvailabilityList(),
    rpx.ruzcal.getOverridesList(),
  ]);
  return { availability, overrides };
}

function EditAvailabilityLink(props: AvailableProps & { id: string }) {
  return (
    <a
      href={`/calendar/availability/${props.id}`}
      class="block p-3 hover:bg-gray-100 transition-all text-inherit rounded-md"
    >
      <AvailableBlock {...props} />
    </a>
  );
}

function OverrideItem({
  override,
  onDelete,
}: {
  override: Data['overrides'][0];
  onDelete: () => void;
}) {
  const user = useCurrentUser()!;

  async function handleDelete() {
    const confirmed = await showDialog({
      mode: 'warn',
      title: 'Delete override',
      children: 'Are you sure you want to delete this override? This action cannot be undone.',
      confirmButtonText: 'Delete',
    });

    if (!confirmed) {
      return;
    }

    try {
      await rpx.ruzcal.deleteOverride({ id: override.id });
      onDelete();
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div class="flex items-center justify-between p-3 hover:bg-gray-100 transition-all rounded-md">
      <a href={`/calendar/overrides/${override.id}`} class="text-inherit">
        <span>{dayjs(override.start).tz(user.timezone).format('MMM DD, YYYY hh:mma')}</span>
        {' - '}
        <span>{dayjs(override.end).tz(user.timezone).format('MMM DD, YYYY hh:mma')}</span>
      </a>
      <Button
        onClick={handleDelete}
        title="Delete override"
        class="text-red-600 hover:bg-red-600 hover:text-white rounded-full p-2 transition-all"
      >
        <IcoTrash />
      </Button>
    </div>
  );
}

function Page(props: Props) {
  const user = useCurrentUser()!;
  const [overrides, setOverrides] = useState(props.state.overrides);
  const { availability } = props.state;

  return (
    <RuzcalMgmtPage title="Availability" currentPage="availability">
      <PageContent>
        <PageSection>
          <PageHeading
            title="Manage your availability"
            subtitle="Define blocks of time when you're available for booking."
            action={<ListAddAction href="/calendar/availability/new" content="Add availability" />}
          />
          {availability.length > 0 && (
            <ListContainer>
              {availability.map((x) => (
                <EditAvailabilityLink
                  key={x.id}
                  id={x.id}
                  label={x.name}
                  isDefault={x.isDefault}
                  renderTimeZone={user.timezone}
                  scheduleTimeZone={user.timezone}
                  timeslots={x.timeslots}
                />
              ))}
            </ListContainer>
          )}
        </PageSection>
        <PageSection>
          <PageHeading
            title="Date overrides"
            subtitle="Remove specific dates and times from your public availability."
            action={<ListAddAction href="/calendar/overrides/new" content="Add override" />}
          />
          {overrides.length > 0 && (
            <ListContainer>
              {overrides.map((x) => (
                <OverrideItem
                  key={x.id}
                  override={x}
                  onDelete={() => setOverrides(overrides.filter((o) => o.id !== x.id))}
                />
              ))}
            </ListContainer>
          )}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
