import { showError } from '@components/app-error';
import { FormSection, FormSubSection } from '@components/async-form';
import { BtnPrimary, Button } from '@components/buttons';
import { Dropdown } from '@components/dropdown';
import { IcoDotsVertical, IcoTrash } from '@components/icons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { Note } from '@components/note';
import { DefaultSpinner } from '@components/spinner';
import { useAsyncState, useTryAsyncData } from 'client/lib/hooks';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { OfferSummary, OffersTab } from 'client/pages/guide-upsells/offers-tab';
import { FullUpsell } from 'client/pages/guide-upsells/types';
import { UpsellHeader } from 'client/pages/guide-upsells/upsell-header';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { PriceRow } from 'server/types';
import { pluralize } from 'shared/formatting';

type Props = {
  price: Pick<PriceRow, 'id' | 'upsellId' | 'productId'>;
  updatePrice<T extends PriceRow>(fn: (p: T) => T): void;
};

function UpsellStats({ upsell }: { upsell: Pick<FullUpsell, 'numAppliedPrices' | 'numOffers'> }) {
  return (
    <span class="flex items-center gap-2 text-gray-500">
      {upsell.numOffers} {pluralize('offer', upsell.numOffers)}
      <span class="border-l h-4"></span>
      {pluralize(`Applied to ${upsell.numAppliedPrices} price point`, upsell.numAppliedPrices)}
    </span>
  );
}

function showConfirmUpsellModal(props: {
  upsell: RpxResponse<typeof rpx.upsells.getMyUpsells>[0];
  priceId: string;
  onApplied(): void;
}) {
  return showModalForm(() => {
    const { data: fullUpsell } = useTryAsyncData(
      () => rpx.upsells.getUpsell({ upsellId: props.upsell.id }),
      [props.upsell.id],
    );
    return (
      <ModalForm
        title="Apply upsell to this price point?"
        subtitle="This will show the following offers to customers during checkout."
        confirmButtonText="Yes, apply this upsell"
        onSubmit={async () => {
          await rpx.upsells.applyPrice({
            upsellId: props.upsell.id,
            priceId: props.priceId,
            apply: true,
          });
          props.onApplied();
        }}
      >
        <section>
          {!fullUpsell && <DefaultSpinner />}
          {fullUpsell?.offers.map((o) => (
            <OfferSummary key={o.priceId} offer={o} />
          ))}
        </section>
      </ModalForm>
    );
  });
}

function EmptyUpsellTab(props: { price: Props['price']; onApplied(upsellId: string): void }) {
  const { data: upsells } = useTryAsyncData(() => rpx.upsells.getMyUpsells(), []);

  if (!upsells) {
    return <DefaultSpinner />;
  }

  return (
    <fieldset class="flex flex-col gap-6 relative">
      <Note>There are no upsells applied to this price point.</Note>
      <header>
        <BtnPrimary
          class="px-4 rounded-full"
          href={`/upsells/new?applyto=${props.price.id}&product=${
            props.price.productId
          }&redirect=${encodeURIComponent(location.pathname + location.search)}`}
        >
          Create an upsell
        </BtnPrimary>
      </header>
      {upsells.length > 0 && (
        <>
          <p class="text-gray-500">...or apply an existing upsell</p>
          <section class="flex flex-col border rounded-2xl divide-y overflow-hidden">
            {upsells.map((u) => (
              <div class="flex flex-col" key={u.id}>
                <Button
                  type="button"
                  class="flex flex-col gap-1 text-left p-4 hover:bg-gray-50"
                  onClick={() => {
                    showConfirmUpsellModal({
                      upsell: u,
                      priceId: props.price.id,
                      onApplied() {
                        props.onApplied(u.id);
                      },
                    });
                  }}
                >
                  <span class="font-semibold">{u.title}</span>
                  <UpsellStats upsell={u} />
                </Button>
              </div>
            ))}
          </section>
        </>
      )}
    </fieldset>
  );
}

function UpsellTab(props: { price: Props['price']; onUnapplied(): void }) {
  const upsellId = props.price.upsellId!;
  const [removing, setRemoving] = useState(false);
  const [upsell, setUpsell] = useAsyncState(() => rpx.upsells.getUpsell({ upsellId }), [upsellId]);

  useAsyncEffect(async () => {
    if (!removing) {
      return;
    }
    await rpx.upsells.applyPrice({
      upsellId,
      priceId: props.price.id,
      apply: false,
    });
    props.onUnapplied();
  }, [removing]);

  if (!upsell) {
    return <DefaultSpinner />;
  }

  return (
    <fieldset class="flex flex-col gap-6 relative" disabled={removing}>
      {removing && (
        <div class="absolute inset-0 flex items-center justify-center">
          <DefaultSpinner />
        </div>
      )}
      <section class="flex flex-col gap-8">
        <div>
          <div class="flex items-center gap-2">
            <UpsellHeader upsell={upsell} setUpsell={(fn) => setUpsell((x) => x && fn(x))} />
            <Dropdown
              triggerClass="hover:bg-gray-100 size-8 rounded-full inline-flex items-center justify-center transition-all"
              hideDownIcon
              noPadding
              renderMenu={() => (
                <section class="p-2 flex flex-col">
                  <Button
                    class="p-4 py-2 whitespace-nowrap hover:bg-red-50 hover:text-red-600 rounded-sm flex gap-2 items-center"
                    onClick={() => setRemoving(true)}
                  >
                    <IcoTrash />
                    Remove upsell from this price point
                  </Button>
                </section>
              )}
            >
              <IcoDotsVertical />
            </Dropdown>
          </div>
          <header class="flex gap-3 items-center">
            <span class="text-gray-500">
              {pluralize(
                `Applied to ${upsell.numAppliedPrices} price point`,
                upsell.numAppliedPrices,
              )}
            </span>
            <span class="border-l h-4"></span>
            <a href={`/upsells/${upsell.id}`} class="text-indigo-600 hover:underline">
              Manage upsell
            </a>
          </header>
        </div>

        <OffersTab
          upsell={upsell}
          offers={upsell.offers.map((o) => ({ ...o, id: o.productId }))}
          onReorder={(offers) => {
            rpx.upsells
              .reorderOffers({
                upsellId: upsell.id,
                productIds: offers.map((o) => o.productId),
              })
              .catch(showError);
            setUpsell((s) => (s ? { ...s, offers } : s));
          }}
        />
      </section>
    </fieldset>
  );
}

export function UpsellsTab({ price, updatePrice }: Props) {
  return (
    <FormSection>
      <FormSubSection class="w-3xl">
        {price.upsellId && (
          <UpsellTab
            price={price}
            onUnapplied={() => updatePrice((p) => ({ ...p, upsellId: undefined }))}
          />
        )}
        {!price.upsellId && (
          <EmptyUpsellTab
            price={price}
            onApplied={(upsellId) => updatePrice((p) => ({ ...p, upsellId }))}
          />
        )}
      </FormSubSection>
    </FormSection>
  );
}
