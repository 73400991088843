/**
 * The overview page for non-course products.
 */

import { rpx } from 'client/lib/rpx-client';
import * as fmt from 'shared/payments/fmt';
import { PageTitle } from '@components/headings';
import { groupBy } from 'shared/utils';
import { timeago } from 'shared/dateutil';
import { UserProfileIcon } from '@components/avatars';
import { GuideProductPage } from '@components/guide-course-page';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defCoursesRoute } from '@components/courses-app-router';

export const route = defCoursesRoute({
  load,
  Page,
  authLevel: 'guide',
});

async function load(route: RouteLoadProps) {
  const { courseId } = route.params;
  const [product, stats, lessonState, recentSignups] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.courseOverview.getCourseStats({ courseId }),
    rpx.lessons.getFullLessonState({ courseId }),
    rpx.courseOverview.getRecentSignups({ courseId, limit: 10 }),
  ]);

  rpx.courses.setLastOpenedAt({ courseId }).catch((err) => console.error(err));

  const lessonMap = groupBy((l) => l.moduleId, lessonState.lessons);
  const lessons = lessonState.modules.flatMap((m) => lessonMap[m.id]);

  return {
    product,
    stats,
    lessons,
    recentSignups,
  };
}

function StatsCard(props: { label: string; value: string; href: string }) {
  return (
    <a
      href={props.href}
      class="inline-flex flex-col p-4 px-0 text-inherit min-w-40 hover:text-indigo-600"
    >
      <span>{props.label}</span>
      <span class="text-2xl font-semibold">{props.value}</span>
    </a>
  );
}

function Page(props: LoadedProps<typeof load>) {
  const { state } = props;
  const {
    product,
    lessons,
    stats,
    recentSignups,
    recentSignups: { signups },
  } = state;

  return (
    <GuideProductPage page="dashboard" product={product}>
      <div class="px-4 p-8 md:px-8 flex flex-col gap-4 w-5xl max-w-full mx-auto">
        <PageTitle>Dashboard</PageTitle>
        <header>
          <nav class="inline-flex flex-wrap">
            {!stats.revenue.length && (
              <StatsCard
                label="Revenue"
                href={`/manage/products/${product.id}/prices`}
                value="$0"
              />
            )}
            {stats.revenue.map((r) => {
              return (
                <StatsCard
                  key={r.currency}
                  label="Revenue"
                  href={`/manage/products/${product.id}/prices`}
                  value={fmt.price({ priceInCents: r.amount, currency: r.currency })}
                />
              );
            })}
            <StatsCard
              label="Customers"
              href={`/manage/products/${product.id}/customers`}
              value={`${stats.enrollments.count}`}
            />
          </nav>
        </header>
        <div class="grid md:grid-cols-2 gap-4">
          <section>
            <div class="flex flex-col rounded-xl border overflow-hidden">
              <h2 class="font-semibold p-4 md:px-6 bg-gray-50 border-b">
                <a href={`/manage/products/${product.id}/content`} class="text-inherit">
                  Content
                </a>
              </h2>
              <section class="p-2 md:p-4 flex flex-col gap-2">
                {!lessons.length && (
                  <p class="p-2">
                    You haven't created any content yet. Go to the{' '}
                    <a href={`/manage/products/${product.id}/content`}>content</a> page to get
                    started.
                  </p>
                )}
                {lessons.map((l) => (
                  <a
                    key={l.id}
                    href={`/manage/products/${product.id}/content/${l.id}`}
                    class="flex items-center gap-3 text-inherit p-2 rounded-md hover:bg-gray-100"
                  >
                    <span class="line-clamp-1">{l.title}</span>
                  </a>
                ))}
              </section>
            </div>
          </section>

          <section>
            <div class="flex flex-col rounded-xl border overflow-hidden">
              <h2 class="font-semibold p-4 md:px-6 bg-gray-50 border-b">
                <a href={`/manage/products/${product.id}/customers`} class="text-inherit">
                  Recent Customers
                </a>
              </h2>

              <section class="p-2 md:p-4 flex flex-col gap-2">
                {!signups.length && <p class="p-2">There are no customers yet.</p>}
                {signups.map((s) => (
                  <a
                    key={s.user.id}
                    href={`/manage/products/${product.id}/customers/${s.user.id}`}
                    class="flex items-center gap-3 text-inherit p-2 rounded-md hover:bg-gray-100"
                  >
                    <span class="shrink-0">
                      <UserProfileIcon user={s.user} size="h-8 w-8" />
                    </span>
                    <span class="font-medium text-gray-800">{s.user.name}</span>
                    <span class="ml-auto text-gray-500">{timeago(s.date, ' ago')}</span>
                  </a>
                ))}
              </section>
              {recentSignups.hasMore && (
                <footer class="border-t border-dashed p-4 md:p-6">
                  <a
                    href={`/manage/products/${product.id}/customers`}
                    class="text-gray-500 font-medium"
                  >
                    View All {stats.enrollments.count} Customers
                  </a>
                </footer>
              )}
            </div>
          </section>
        </div>
      </div>
    </GuideProductPage>
  );
}
