/**
 * This file contains the UI for editing a course's settings.
 */

import dayjs from 'dayjs';
import { useRef, useState } from 'preact/hooks';
import { dissoc, indexBy } from 'shared/utils';
import { Draggable, DraggableProvider, DragState, DropTarget } from '@components/draggable';
import { Calendar, CalendarDay } from '@components/calendar';
import { Menu, MenuItem } from '@components/dropdown';
import { timeWithoutZeroes, toLocalDate, toUTCDate } from 'shared/dateutil';
import { ScheduledItem } from './types';
import { ItemEditor, NewItemModal, saveModule } from './modal-editor';
import { showError } from '@components/app-error';
import { IcoPlus } from '@components/icons';
import { RescheduleModal as RescheduleMeetingModal } from '../guide-course-meetings/reschedule-modal';
import { Case } from '@components/conditional';
import { GuideCoursePage } from '@components/guide-course-page';
import { HeadingPrimary } from '@components/headings';
import { Button } from '@components/buttons';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { isValidDate } from '@components/date-picker';
import { URLS } from 'shared/urls';
import { fixupModule } from 'client/lib/rpx-client/modules-service';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defCoursesRoute } from '@components/courses-app-router';

const store = rpx.messages;

// A map of key -> ScheduledItemState, where key is something like
// the item type + the id (e.g. module-32)
interface PageData {
  course: RpxResponse<typeof rpx.courses.getGuideCourse>;
  items: Record<string, ScheduledItem>;
}

interface CellItemProps {
  item: ScheduledItem;
  onClick(): void;
}

type EditMode =
  | { type: 'none' }
  | { type: 'menu'; date: string }
  | { type: 'module'; date: string }
  | { type: 'message'; date: string }
  | { type: 'meeting'; date: string }
  | { type: 'rescheduleMeeting'; item: ScheduledItem }
  | { type: 'edit'; item: ScheduledItem };

export const route = defCoursesRoute({
  load,
  Page,
  authLevel: 'guide',
});

async function load(props: RouteLoadProps): Promise<PageData> {
  const { courseId } = props.params;
  const [course, rawItems] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.courses.getScheduledItems({ courseId }),
  ]);

  return {
    course,
    items: indexBy(
      (x) => x.key,
      rawItems.map((x) => ({
        ...x,
        key: `${x.type}-${x.id}`,
        // Meeting dates are global so we're not converting them to local date
        date:
          x.type === 'meeting'
            ? new Date(x.date)
            : x.type === 'module'
            ? fixupModule({
                isAbsoluteSchedule: course.isAbsoluteSchedule,
                startDate: x.date,
              }).startDate!
            : toLocalDate(x.date)!,
      })),
    ),
  };
}

function setDate(target: Date, sourceStr: string) {
  const dt = new Date(target);
  const source = new Date(sourceStr);

  // Return the original date when the source is not a valid date
  if (!(source instanceof Date) || !isValidDate(source)) {
    return dt;
  }

  dt.setFullYear(source.getFullYear());
  dt.setMonth(source.getMonth());
  dt.setDate(source.getDate() + 1);

  return dt;
}

function CellItem({ item, onClick }: CellItemProps) {
  let dotClass = 'bg-blue-500';
  if (item.type === 'meeting') {
    dotClass = 'bg-indigo-300';
  } else if (item.type === 'message' || item.type === 'sentMessage') {
    dotClass = 'bg-green-400';
  }

  return (
    <Draggable
      class="flex grow"
      id={item.key}
      table={item.type}
      draggable={item.type !== 'sentMessage'}
    >
      <Button
        class="group relative w-full flex text-left rounded-md hover:bg-gray-100 p-1 -ml-1 box-content"
        data-tooltip={item.title}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
      >
        <span class="inline-block items-center">
          <span class={`w-2 h-2 inline-block rounded-full ${dotClass}`}></span>
        </span>
        <time class="opacity-75 mx-1.5">{timeWithoutZeroes(item.date)}</time>
        <span class="overflow-hidden whitespace-nowrap font-semibold">{item.title}</span>
      </Button>
    </Draggable>
  );
}

function Page(props: LoadedProps<typeof load>) {
  const routeDate = toLocalDate(props.params.date);
  const course = props.state.course;
  const hasEnrolledStudents = course.numStudents ? course.numStudents > 0 : false;
  const { terminology } = useCurrentTenant();
  const [items, setItems] = useState(props.state.items);
  const [editMode, setEditMode] = useState<EditMode>({ type: 'none' });

  const itemsCache = useRef(items);

  const clearEditMode = () => setEditMode({ type: 'none' });
  const revertOrder = () => setItems(itemsCache.current);

  const reorder = (dragState: DragState) => {
    const item = { ...items[dragState.dragging.id] };
    // Overwrite the startDate yyyy, mm, dd with the values from
    // the drop target's id (which is a date string).
    item.date = setDate(item.date, dragState.target.id);
    setItems((s) => ({ ...s, [item.key]: item }));
    return item;
  };

  const saveSchedule = async (dragState: DragState) => {
    try {
      const item = reorder(dragState);
      if (item.type === 'module') {
        await saveModule(item, course);
      } else if (item.type === 'message') {
        await store.scheduleMessage({
          courseId: course.id,
          id: item.id,
          startDate: toUTCDate(item.date),
        });
      } else if (item.type === 'meeting') {
        // Display reschedule meeting modal before saving the new date
        // because the guide may send a notification to enrolled users
        if (hasEnrolledStudents) {
          // Reverting the items order as the user might
          // cancel the rescheduling operation later
          revertOrder();
          setEditMode({
            type: 'rescheduleMeeting',
            item,
          });
          return;
        }

        await rpx.meetings.rescheduleMeeting({
          courseId: course.id,
          meetingId: item.id,
          scheduledAt: item.date.toISOString(),
          sendNotification: false,
        });
      }
    } catch (err) {
      showError(err);
    }
  };

  const onDelete = (item: ScheduledItem) => {
    clearEditMode();
    setItems((s) => dissoc(item.key, s));
  };

  return (
    <GuideCoursePage
      course={course}
      type="calendar"
      viewLink={URLS.student.coursePage({
        course,
        page: 'calendar',
      })}
    >
      {editMode.type === 'rescheduleMeeting' && (
        <RescheduleMeetingModal
          scheduledAt={editMode.item.date}
          courseId={course.id}
          meetingId={editMode.item.id}
          hasEnrolledStudents={hasEnrolledStudents}
          hide={clearEditMode}
          onSuccess={(newDate) => {
            const { item } = editMode;
            item.date = newDate;
            setItems((s) => ({ ...s, [item.key]: item }));
            clearEditMode();
          }}
        />
      )}
      <div class="grow flex flex-col p-8 max-w-7xl mx-auto">
        <HeadingPrimary title="Calendar" />
        <DraggableProvider
          onDragStart={() => {
            itemsCache.current = items;
          }}
          onDragComplete={saveSchedule}
          onTargetChange={reorder}
          canHandleDrop={(_a, table) => table === 'calendar-cell'}
        >
          <Calendar
            currentDate={routeDate}
            onChange={(newDate) =>
              props.router.rewrite(
                `${calendarUrl(course.id)}?date=${dayjs(newDate).format('YYYY-MM-DD')}`,
              )
            }
            items={Object.values(items)}
            renderCellItems={({ items, date, now, dateKey, isPast }) => (
              <DropTarget
                class={`min-h-32 relative ${isPast ? '' : 'cursor-pointer group'}`}
                id={dateKey}
                table="calendar-cell"
                onMouseDown={(e) => {
                  // Prevent the click from showing the menu again...
                  if (editMode.type === 'menu' && editMode.date === dateKey) {
                    e.stopPropagation();
                  }
                }}
                onClick={() => {
                  if (isPast || editMode.type !== 'none') {
                    clearEditMode();
                    return;
                  }
                  setEditMode({ type: 'menu', date: dateKey });
                }}
              >
                {editMode.type === 'menu' && editMode.date === dateKey && (
                  <Menu onHide={clearEditMode} position="left-2">
                    <header class="font-bold px-4 py-2 text-gray-600">Create</header>
                    <Case when={course.accessFormat === 'scheduled'}>
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setEditMode({ type: 'module', date: dateKey });
                        }}
                      >
                        {terminology.Module}
                      </MenuItem>
                    </Case>
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditMode({ type: 'message', date: dateKey });
                      }}
                    >
                      Message
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditMode({ type: 'meeting', date: dateKey });
                      }}
                    >
                      {terminology.meeting}
                    </MenuItem>
                  </Menu>
                )}
                <div class="flex justify-between">
                  <CalendarDay date={date} now={now} />
                  <IcoPlus class="w-4 h-4 text-green-600 invisible group-hover:visible" />
                </div>
                {items.map((item) => (
                  <CellItem
                    key={item.key}
                    item={item}
                    onClick={() => setEditMode({ type: 'edit', item })}
                  />
                ))}
              </DropTarget>
            )}
          />
        </DraggableProvider>
        {(editMode.type === 'module' ||
          editMode.type === 'message' ||
          editMode.type === 'meeting') && (
          <NewItemModal
            type={editMode.type}
            course={course}
            hide={clearEditMode}
            date={editMode.date}
            onUpdate={(item) => setItems((s) => ({ ...s, [item.key]: item }))}
            onDelete={onDelete}
          />
        )}
        {editMode.type === 'edit' && (
          <ItemEditor
            item={editMode.item}
            hide={clearEditMode}
            course={course}
            onUpdate={(item) => {
              setItems({ ...items, [editMode.item.key]: { ...editMode.item, ...item } });
            }}
            onDelete={onDelete}
          />
        )}
      </div>
    </GuideCoursePage>
  );
}

const routePattern = 'manage/courses/:courseId/calendar';

export function calendarUrl(courseId: UUID) {
  return '/' + routePattern.replace(':courseId', courseId.toString());
}
