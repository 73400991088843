import { BtnPrimary } from '../buttons';
import { CheckoutPage, CheckoutPageProps, CheckoutSection } from '../checkout';
import { useCurrentUser } from 'client/lib/auth';
import * as urls from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';
import { CourseBanner } from './course-banner';
import { SubCourses } from './subcourses';
import { CheckoutCourse } from './types';

type Props = Omit<CheckoutPageProps, 'brand'> & {
  editHref?: string;
  course: CheckoutCourse;
};

/**
 * The page wrapper for course checkout pages.
 */
export function CourseCheckoutPage(props: Props) {
  const intl = useIntl();
  const { course, bannerContent, price } = props;
  const editHref = price
    ? urls.coursePrices.priceUrl({ priceId: price.id, courseId: course.id })
    : urls.coursePrices.pricesUrl({ courseId: course.id });
  const user = useCurrentUser();
  const { guide } = course;
  const isGuide = user?.id === guide.id;

  return (
    <CheckoutPage
      {...props}
      docTitle={[props.title || intl('Sign Up'), course.title]}
      subtitle={props.subtitle || price?.name}
      brand={course.brand}
      bannerContent={
        <>
          {isGuide && (
            <header class="bg-white py-4 p-8 mb-16 rounded-xl shadow-sm flex items-center justify-between">
              <span>You are viewing your own {course.isProduct ? 'product' : 'course'}.</span>
              <BtnPrimary href={editHref}>Return to Editing ⤑</BtnPrimary>
            </header>
          )}

          <CheckoutSection>
            <CourseBanner course={course} />
          </CheckoutSection>
          {bannerContent}
        </>
      }
      footerContent={<SubCourses subcourses={course.subcourses} />}
    />
  );
}
