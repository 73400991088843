import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { Dispatch, StateUpdater, useState } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';
import { AutosizeText } from '@components/autosize-text';
import { IcoGift, IcoPencil, IcoTrash } from '@components/icons';
import { AsyncForm, FormGroup, InputLabel } from '@components/async-form';
import { autoFocusSelf } from 'client/utils/autofocus';
import { rpx } from 'client/lib/rpx-client';
import * as terminology from 'shared/terminology';
import { Course } from 'server/types';
import { useCurrentTenant } from 'client/lib/auth';
import { validationError } from 'shared/utils';
import { Toggle } from '@components/toggle';
import { DateInput } from '@components/date-picker';
import { fmtFullTime } from 'shared/dateutil';
import { Checkbox } from '@components/checkbox';
import { PriceRow } from 'server/types';
import { useLocalStorageState } from 'client/lib/hooks';

export interface GiftState {
  email?: string;
  message?: string;
  shouldSendEmail?: boolean;
  emailScheduledAt?: Date | string;
  isOpen: boolean;
}

interface Props {
  course: Pick<Course, 'id' | 'isBundle'>;
  state?: GiftState;
  price: PriceRow;
  setState: Dispatch<StateUpdater<GiftState | undefined>>;
  previousGifts: string[];
}

export function useGiftState(course: Props['course']) {
  return useLocalStorageState<GiftState | undefined>(`gift_state_${course.id}`, undefined);
}

export function GiftForm({ course, price, state, setState, previousGifts }: Props) {
  const tenant = useCurrentTenant();
  const intl = useIntl();
  // We need a local state for the message so the autosize text component
  // renders correctly when the user is editing the message
  const [message, setMessage] = useState(state?.message);
  const [shouldSendEmail, setShouldSendEmail] = useState(state?.shouldSendEmail ?? true);
  const [emailScheduledAt, setEmailScheduledAt] = useState(
    state?.emailScheduledAt ? new Date(state.emailScheduledAt) : undefined,
  );
  const email = state?.email || '';
  const courseLabel = terminology.courseLabel({
    course,
    intl,
    tenant,
  });

  if (!price.isGiftable || price.paymentType !== 'paid') {
    return null;
  }

  return (
    <div>
      {!state?.isOpen && !email && (
        <label class="flex items-center gap-4 cursor-pointer mt-4">
          <Toggle onClick={() => setState((s) => ({ ...s, isOpen: true }))} />
          {intl('Purchase as a gift')}
        </label>
      )}
      {!state?.isOpen && !!email && (
        <div class="bg-green-50 p-4 flex gap-4 rounded-lg relative">
          <Button onClick={() => setState(undefined)} class="absolute top-4 right-4">
            <IcoTrash />
          </Button>
          <span>
            <IcoGift class="w-10 h-10 text-green-600 bg-green-100 p-2 rounded-full" />
          </span>
          <div class="flex flex-col gap-2">
            <header>
              <h3 class="font-medium text-green-600">{intl('Purchase as a gift')}</h3>
              <Button
                class="flex items-center gap-2"
                onClick={() => setState((s) => ({ ...s, isOpen: true }))}
              >
                <span>{intl('For {email}', { email })}</span>
                <IcoPencil />
              </Button>
            </header>
            {state?.shouldSendEmail && (
              <p class="text-xs">
                {state.emailScheduledAt
                  ? intl('The recipient will get an invitation email at {emailDate:string}.', {
                      emailDate: fmtFullTime(state.emailScheduledAt, {
                        includeTimezone: true,
                      }),
                    })
                  : intl(
                      'The recipient will get an invitation email shortly after your payment is processed.',
                    )}
              </p>
            )}
            <div class="flex gap-2 absolute top-2 right-4"></div>
            {shouldSendEmail && !!message && message.length > 0 && (
              <blockquote class="p-4 border-l-4 border-green-500 bg-green-50 text-xs whitespace-pre italic">
                {message}
              </blockquote>
            )}
            {previousGifts.length > 0 && (
              <div class="text-xs flex flex-col gap-2">
                <p>
                  {intl(
                    'Please note that you have already gifted this {courseLabel:string} to {emails:string}.',
                    {
                      courseLabel,
                      emails: previousGifts.join(', '),
                    },
                  )}
                </p>
                <p>
                  <a href="/account/gifts">{intl('Go to gifts tab to manage your gifts.')}</a>
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {state?.isOpen && (
        <div class="mt-4">
          <AsyncForm
            onSubmit={async (data) => {
              const isValid = await rpx.gifts.validateGift({
                email: data.email,
                courseId: course.id,
              });

              if (isValid) {
                setState({ ...data, email: data.email.trim(), emailScheduledAt, isOpen: false });
              } else {
                const message = intl('There is already a gift sent to this email.');
                throw validationError({
                  field: 'email',
                  message,
                });
              }
            }}
          >
            <InputLabel class="text-sm">{intl('Gift recipient email address')}</InputLabel>
            <FormGroup prop="email" class="mb-6">
              <input
                type="email"
                ref={autoFocusSelf}
                required
                placeholder={intl('Email')}
                defaultValue={email}
                name="email"
                class="ruz-input block w-full sm:text-sm sm:leading-5"
                data-private
              />
            </FormGroup>
            <FormGroup class="mb-8" prop="shouldSendEmail">
              <label class="flex gap-3 cursor-pointer text-sm">
                <Toggle
                  class={`${shouldSendEmail ? '' : 'mt-1'}`}
                  name="shouldSendEmail"
                  checked={shouldSendEmail}
                  onClick={() => setShouldSendEmail((shouldSendEmail) => !shouldSendEmail)}
                />
                <span>
                  {intl('Send an invitation email to the recipient.')}
                  {!shouldSendEmail && (
                    <span class="inline-block text-xs">
                      {intl(
                        "We'll give you a link to send to your recipient after you purchase the course.",
                      )}
                    </span>
                  )}
                </span>
              </label>
            </FormGroup>
            {shouldSendEmail && (
              <>
                <div>
                  <Checkbox
                    checked={!!emailScheduledAt}
                    onClick={() => setEmailScheduledAt((v) => (v ? undefined : new Date()))}
                  >
                    {intl('Schedule the gift for a specific date')}
                  </Checkbox>
                  <p class="pl-6 text-xs">
                    {intl(
                      "Scheduling is optional. If you choose not to schedule, we'll send the gift right away.",
                    )}
                  </p>
                </div>
                {!!emailScheduledAt && (
                  <FormGroup class="mt-4" prop="availableOn">
                    <DateInput
                      class="w-full"
                      name="emailScheduledAt"
                      includeTime
                      placeholder={intl('now')}
                      value={emailScheduledAt}
                      allowClear
                      onChange={setEmailScheduledAt}
                    />
                  </FormGroup>
                )}
                <FormGroup class="my-6" prop="giftMessage">
                  <InputLabel class="text-sm">
                    {intl('Write a personalized message (optional)')}
                  </InputLabel>
                  <AutosizeText
                    containerClass="bg-white"
                    maxLength={1024}
                    minHeight="min-h-16"
                    class="p-2 rounded-sm ruz-input text-sm"
                    name="message"
                    placeholder={intl('Write a personalized message')}
                    onInput={(e: any) => setMessage(e.target.value)}
                    value={message}
                  />
                </FormGroup>
              </>
            )}
            <footer class="grid grid-cols-2 gap-4">
              <BtnSecondary
                class="rounded-full"
                onClick={() => setState((s) => ({ ...s, isOpen: false }))}
              >
                {intl('Cancel Gift')}
              </BtnSecondary>
              <BtnPrimary class="rounded-full">{intl('Continue ⤑')}</BtnPrimary>
            </footer>
          </AsyncForm>
        </div>
      )}
    </div>
  );
}
