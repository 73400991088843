/**
 * The main front-end entry point for the calendar app.
 */

import * as cal from './apps/cal/routes';
import * as courses from './apps/courses/routes';
import { Toaster } from '@components/toaster';
import { createHistoryRouter, AppRouter, RouteRule, RouterPage } from '@components/router';
import { render } from 'preact';
import { GlobalComponents } from '@components/dialog';
import { AuthContextProvider } from 'client/lib/auth';
import { CoursesApp } from './apps/courses';

/**
 * Render the root component, and wire up the application context.
 */
function App(props: { router: AppRouter }) {
  return (
    <AuthContextProvider>
      <RouterPage router={props.router}>
        <GlobalComponents />
        <CoursesApp />
      </RouterPage>
      <Toaster />
    </AuthContextProvider>
  );
}

function addRoutes(router: AppRouter, app: { routes: RouteRule[] }) {
  app.routes.forEach(([loadModule, ...patterns]) =>
    patterns.forEach((url) => router.add({ loadModule, url })),
  );
}

/**
 * Mount the application.
 */
function mountApp() {
  const router: AppRouter = createHistoryRouter();
  addRoutes(router, cal);
  addRoutes(router, courses);
  render(<App router={router} />, document.querySelector('main')!);
}

mountApp();
