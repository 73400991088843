import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { completeCourseStep } from '@components/course-checklist';
import { LoadingIndicator } from '@components/loading-indicator';
import { MessageEditor, PreviewModal } from '@components/messages';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { showToast } from '@components/toaster';
import { Toggle } from '@components/toggle';
import { globalConfig } from 'client/lib/auth';
import { useState } from 'preact/hooks';
import { SystemMessageTypes, Email, Course } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import { getDefaultSystemMessages } from '../../../shared/messages';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useRouter } from '@components/router';

const store = rpx.systemMessages;

export type SystemMessageId = Exclude<SystemMessageTypes, 'moduleStart'>;

export type SystemMessage = RpxResponse<typeof store.getSystemMessage> & {
  id: SystemMessageId;
};

interface Props {
  course: Pick<Course, 'id' | 'isBundle' | 'isProduct'>;
  message: SystemMessage;
}

interface Data {
  name: string;
  description: string;
  canToggle: boolean;
}

const { terminology } = globalConfig().tenant;
const TabData: Record<SystemMessageTypes, Data> = {
  welcome: {
    name: 'Welcome',
    description: `If enabled, the Welcome message will be sent after checkout.`,
    canToggle: true,
  },
  moduleStart: {
    name: 'Start of Module',
    description: 'This is sent when a new module is available.',
    canToggle: true,
  },
  invitation: {
    name: 'Invitation',
    description: `This is sent when you invite a student into your ${
      terminology?.course || 'course'
    }.`,
    canToggle: false,
  },
};

export function SystemMessageEditor(props: Props) {
  const { id: courseId, isBundle } = props.course;
  const type = props.message.id as SystemMessageTypes;
  const tenant = useCurrentTenant();
  const intl = useIntl(true);

  const data = TabData[type];
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState(props.message);
  const user = useCurrentUser();
  const [isSending, setIsSending] = useState(false);
  const [previewEmail, setPreviewEmail] = useState<Email | undefined>(undefined);
  const router = useRouter();

  // Test if html contents match, minus a few html tags that are rendered differently after sanitization
  const htmlMatches = (h1: string, h2: string) => {
    // Preact's <br /> tags are rendered as '<br>' in minidoc after xss sanitization
    h1 = h1.replaceAll(/<br[ /]{0,2}>/g, ''); // strip out <br>, <br />, <br/>
    h2 = h2.replaceAll(/<br[ /]{0,2}>/g, '');
    return h1 === h2;
  };
  async function save(content: string, title: string) {
    if (!message.isEnabled) {
      return;
    }

    try {
      const defaultMessage = getDefaultSystemMessages(isBundle, intl, tenant)[message.id];
      const isCustomized =
        !htmlMatches(defaultMessage.title, title) || !htmlMatches(content, defaultMessage.content);

      await store.saveSystemMessage({
        courseId,
        messageType: message.id,
        content,
        title,
        isCustomized,
      });

      setMessage({
        ...message,
        content,
        title,
        isCustomized,
      });

      if (data.name === 'Welcome') {
        completeCourseStep(router, 'welcomeEmail');
      }
    } catch (err) {
      showError(err);
    }
  }

  async function displayPreview() {
    try {
      setIsSending(true);
      const result = await store.getSystemMessagePreview({
        courseId,
        messageType: message.id,
      });
      setPreviewEmail(result.email);
    } catch (err) {
      showError(err);
    } finally {
      setIsSending(false);
    }
  }

  async function sendPreview() {
    setIsSending(true);
    try {
      await store.sendSystemMessagePreview({
        courseId,
        messageType: message.id,
      });
      showToast({
        type: 'ok',
        title: 'Message sent',
        message: `"${message.id}" message sent successfully.`,
      });
      setPreviewEmail(undefined);
    } catch (err) {
      showError(err);
    } finally {
      setIsSending(false);
    }
  }

  async function toggleStatus() {
    if (isSaving || !message) {
      return;
    }

    setIsSaving(true);
    const newStatus = !message.isEnabled;

    // optimistic updating the enabled status
    setMessage({
      ...message,
      isEnabled: newStatus,
    });

    try {
      await store.toggleSystemMessageStatus({
        courseId,
        isEnabled: newStatus,
        messageType: message.id,
      });
    } catch (err) {
      // revert the enabled status
      setMessage({
        ...message,
        isEnabled: !newStatus,
      });
      showError(err);
    } finally {
      setIsSaving(false);
    }
  }

  /**
   * Reset current message to the default system message
   */
  async function resetToDefaultMessage() {
    const defaultMessage = getDefaultSystemMessages(isBundle, intl, tenant)[message.id];
    await store.saveSystemMessage({
      courseId,
      messageType: message.id,
      content: defaultMessage.content,
      title: defaultMessage.title,
      isCustomized: false,
    });

    setMessage({
      ...defaultMessage,
      isCustomized: false,
      id: message.id,
    });
    location.reload();
  }

  // only possible if user manually modifies the url with a non-existing message type
  if (!data) {
    return null;
  }

  return (
    <>
      <Case when={isSaving}>
        <LoadingIndicator />
      </Case>
      <div class="w-full">
        <div class="flex mb-8 md:mb-16 md:pl-3">
          <div
            class="pt-1 relative"
            data-tooltip={!data.canToggle && `This message type can't be disabled.`}
          >
            <Toggle
              class={data.canToggle ? '' : 'opacity-50'}
              checked={message.isEnabled}
              disabled={!data.canToggle}
              onClick={toggleStatus}
            />
          </div>
          <span class="flex flex-col ml-6 max-w-lg" id="availability-label">
            <span class="text-md font-medium text-gray-900">
              {data.name} message is {message.isEnabled ? 'enabled' : 'disabled'}
            </span>
            <span class="text-md text-gray-500">{data.description}</span>
          </span>
        </div>
        <div class={`flex-1 relative ${!message.isEnabled ? 'opacity-20' : ''}`}>
          {previewEmail && (
            <PreviewModal
              email={previewEmail}
              recipientsText={user?.email || ''}
              isSending={isSending}
              onCancel={() => setPreviewEmail(undefined)}
              onSend={sendPreview}
            />
          )}
          <MessageEditor
            isProduct={props.course.isProduct}
            allowPreview={message.isEnabled}
            content={message.content}
            title={message.title}
            isSending={isSending}
            isBundle={isBundle}
            isLimited={message.type === 'invitation'}
            noRecipientName={message.type === 'invitation'}
            templateFields={
              message.type === 'moduleStart'
                ? [
                    {
                      key: 'module-title',
                      title: 'Module Title',
                    },
                  ]
                : []
            }
            onSave={save}
            onSend={displayPreview}
          />
        </div>
        <Case when={message.isCustomized && message.isEnabled}>
          <p class="italic text-right">
            You've customized this template.{' '}
            <Button class="text-blue-400 font-bold" onClick={resetToDefaultMessage}>
              Click here
            </Button>{' '}
            to reset it to the default version.
          </p>
        </Case>
      </div>
    </>
  );
}
