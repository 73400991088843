/**
 * A router that supports asynchronously loading es modules based on the route.
 */

import type { Dispatch, StateUpdater } from 'preact/hooks';
import type { Auth } from './session-context';
import type { UserLevel } from 'server/types';
import { Router } from './app-route';

/**
 * Define an asynchronous route rule which consists of a module-load
 * operation and any number of URL patterns.
 *
 * Example:
 *
 *   [() => import('./hello'), 'hello/:name', 'hello']
 */
export type RouteRule = [() => Promise<{ route: PageDef }>, ...patterns: string[]];

/**
 * The query string parameters and named route parameters.
 * (e.g. for route /users/:userId, this will contain { userId: 'value' })
 */
export type RouteParams = Record<string, string>;

/**
 * An optional error handler that an application can define. If a page
 * load error occurrs, this error handler will be called before the base
 * router error handling occurs. If the handler returns a truthy value,
 * the router ignores the error and assumes it's been properly handled.
 */
export type PageLoadErrorHandler = (props: RouteLoadProps, err: any) => boolean | void | undefined;

/**
 * The object which is passed to the load function for a page.
 */
export type RouteLoadProps = {
  url: string;
  /*
   * All of the params are stored here without any modification.
   */
  params: RouteParams;
  auth: Auth;
  router: Router<any>;
};

/**
 * The load function for a page.
 */
export type Loader<T> = (props: RouteLoadProps) => Promise<T>;

/**
 * The props which are passed to Page (the render function).
 */
export type PageProps<T = any> = RouteLoadProps & {
  state: T;
  setState: Dispatch<StateUpdater<T>>;
};

/**
 * A helper for deriving the page prop type from the page load function.
 */
export type LoadedProps<T extends Loader<any>> = PageProps<Awaited<ReturnType<T>>>;

/**
 * The page definition.
 */
export type PageDef<T = any> = {
  isPublic?: boolean;
  authLevel?: UserLevel;
  /**
   * If specified, this is called any time the route params change so the
   * page can preprocess them.
   */
  processParams?(params: RouteParams): void;
  load?: Loader<T>;
  key?(props: RouteLoadProps): string;
  Page(props: PageProps<T>): null | JSX.Element;
  loadSubroute?(opts: RouteLoadProps, setState: PageProps<T>['setState']): Promise<unknown>;
};

/**
 * TODO: make the types more concrete.
 */
export type RoutePattern = {
  url: string;
  loadModule(): Promise<{ route: PageDef }>;
};

/**
 * The type signature for the application router.
 */
export type AppRouter = Router<RoutePattern>;

/**
 * A helper for defining route pages.
 *
 *   export const route = defRoute({ ... })
 */
export function defRoute<T>(def: PageDef<T>) {
  return def;
}

/**
 * A helper for defining routes. It takes a module loading function as
 * its first parameter (e.g. () => import('./pages')), and any number
 * of route definitions and returns a flattened set of rout defintions.
 */
export function makeRoutes<
  F extends () => Promise<Record<string, { route: PageDef<any> }>>,
  T extends Awaited<ReturnType<F>>,
>(loadApp: F, defs: { [K in keyof T]: string | string[] }) {
  let promise: Promise<any>;
  const cachedLoad = () => {
    if (!promise) {
      promise = loadApp();
    }
    return promise;
  };
  return Object.entries(defs).flatMap(([page, url]) =>
    Array.isArray(url)
      ? url.map((url) => ({ url, page, loadApp: cachedLoad }))
      : { url, page, loadApp: cachedLoad },
  );
}
