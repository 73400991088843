import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { CopyCourseTemplateModal } from '@components/course-action-modals';
import { PurchaseInstantCourseModal } from '@components/course-action-modals/purchase-instant-course-modal';
import { CourseLogo } from '@components/course-image';
import { useCourseGuideMenu } from '@components/guide-course-menu/use-course-guide-menu';
import { IcoList, IcoSearch } from '@components/icons';
import { UserMenu } from '@components/nav/user-menu';
import { useCurrentUser } from '@components/router/session-context';
import { StudentNotifications } from '@components/student-notifications';
import { useStudentSearchModal } from '@components/student-search-modal';
import { StudentThemePicker } from '@components/student-theme-picker';
import { TopBarBanner } from '@components/top-bar';
import { ComponentChildren } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { MembershipLevel } from 'server/types';
import { Props } from './props';
import { SideNavContext } from './student-side-nav';
import { showModalForm } from '@components/modal-form';

function StudentSearchButton({ course }: { course: Props['course'] }) {
  const showSearchModal = useStudentSearchModal(course, {
    listenCtrlKey: true,
  });
  return (
    <Button type="button" class="opacity-75 hover:opacity-100" onClick={showSearchModal}>
      <IcoSearch class="w-6 h-6" />
    </Button>
  );
}

function GuideSearchButton({ course }: { course: Props['course'] }) {
  const showSearchModal = useCourseGuideMenu({ course });
  return (
    <Button type="button" class="opacity-75 hover:opacity-100" onClick={showSearchModal}>
      <IcoSearch class="w-6 h-6" />
    </Button>
  );
}

export function PageHeader({
  course,
  accessLevel,
  manageButton,
}: {
  course: Props['course'];
  accessLevel: MembershipLevel;
  manageButton: ComponentChildren;
}) {
  const user = useCurrentUser();
  const { setIsMenuVisible } = useContext(SideNavContext);
  const [showPurchaseCourseModal, setShowPurchaseCourseModal] = useState(false);
  const showTitle = !course.bannerPath || course.showTitle;

  return (
    <header class="sticky lg:relative -top-12 md:top-0 flex flex-col border-b lg:border-gray-900/5 dark:border-gray-800 pt-4 md:pt-0 z-40 bg-white dark:bg-none dark:bg-gray-900 dark:text-gray-400 font-medium shadow-md shadow-gray-900/5 dark:shadow-none dark:bg-transparent">
      <div class="relative flex flex-col max-w-full justify-center px-4">
        <div class="course-header-title">
          <nav class="flex flex-col md:flex-row items-center justify-between md:py-1 dark:text-gray-300 gap-2">
            <Button
              onClick={() => setIsMenuVisible((x) => !x)}
              class="hidden md:inline-block rounded-sm lg:hidden mr-auto"
            >
              <IcoList class="w-6 h-6 opacity-75" />
            </Button>
            <span class="items-center hidden md:flex">
              {course.bannerPath && (
                <a href={`/courses/${course.id}`} class="inline-flex min-h-10 px-2">
                  <CourseLogo class="object-contain" image={course.bannerPath} />
                </a>
              )}
            </span>

            {showTitle && (
              <a
                class="text-inherit inline-flex overflow-hidden text-ellipsis line-clamp-1 font-medium"
                href={`/courses/${course.id}`}
              >
                {course.title}
              </a>
            )}
            <nav class="sticky lg:relative top-0 inset-x-0 self-start w-full py-2 md:mt-0 md:flex-1 inline-flex justify-end items-center gap-6">
              {manageButton}
              <Button
                onClick={() => setIsMenuVisible((x) => !x)}
                class="opacity-75 hover:opacity-100 rounded-sm md:hidden mr-auto"
              >
                <IcoList class="w-6 h-6" />
              </Button>
              <Case
                when={user?.id !== course.guide.id}
                fallback={<GuideSearchButton course={course} />}
              >
                {!course.isBundle && <StudentSearchButton course={course} />}
              </Case>
              <StudentThemePicker />
              {accessLevel !== 'limited' && accessLevel !== 'preview' && (
                <StudentNotifications course={course} />
              )}
              <UserMenu />
            </nav>
          </nav>
        </div>
      </div>
      {accessLevel === 'limited' && (
        <TopBarBanner
          text="You need to purchase the instant course to see full course content."
          buttonProps={{
            children: 'Purchase Instant Course',
            onClick: () => setShowPurchaseCourseModal(true),
          }}
        />
      )}
      {accessLevel === 'preview' && (
        <TopBarBanner
          text="This is a student preview. Copy the course to make your own."
          buttonProps={{
            children: 'Copy Course',
            onClick: () =>
              showModalForm(({ resolve }) => (
                <CopyCourseTemplateModal onCancel={resolve} course={course} />
              )),
          }}
        />
      )}
      {showPurchaseCourseModal && (
        <PurchaseInstantCourseModal
          course={course}
          onCancel={() => setShowPurchaseCourseModal(false)}
        />
      )}
    </header>
  );
}
