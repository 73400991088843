import { useEffect, useMemo, useState } from 'preact/hooks';
import { BaseDialog, hideModals } from '@components/dialog';
import { showModalForm } from '@components/modal-form';
import { useCtrlKey, useKeyNavigation } from 'client/lib/hooks';
import { SearchBox } from '@components/search-box';
import { LoadingIndicator } from '@components/loading-indicator';
import {
  IcoAcademicCap,
  IcoBook,
  IcoChartBar,
  IcoFolder,
  IcoUserGroup,
  IcoX,
} from '@components/icons';
import { Button } from '@components/buttons';
import { pluralize } from 'shared/formatting';
import { Case } from '@components/conditional';
import { useImageUrl } from 'client/utils/cdn';
import { URLS } from 'shared/urls';
import { FullCourse } from 'server/types';
import { useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import { rpx } from 'client/lib/rpx-client';
import { Upsell } from 'client/pages/guide-upsells/types';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { highlightTerms } from 'client/utils/highlight';
import { useRef } from 'react';

type Item = {
  id: string;
  imagePath?: string;
  title: string;
  status?: 'published' | 'draft';
  numStudents?: number;
};

const resultTypes = ['courses', 'products', 'bundles', 'upsells'] as const;
type ResultType = (typeof resultTypes)[number];
export type Course = Pick<
  FullCourse,
  'id' | 'title' | 'numStudents' | 'status' | 'isBundle' | 'isProduct' | 'level'
> & {
  guide: {
    name?: string;
  };
  lastOpenedAt: Date;
};

type PaletteData = {
  courses: Course[];
  products: Course[];
  bundles: Course[];
  upsells?: Upsell[];
};

type Props = {
  courses?: Course[];
};

type SearchSectionConfig = {
  key: ResultType;
  title: string;
  asGrid?: boolean;
  icon?: () => JSX.Element;
};

const sectionConfig: Record<ResultType, SearchSectionConfig> = {
  courses: { key: 'courses', title: 'Courses', icon: () => <IcoAcademicCap /> },
  products: {
    key: 'products',
    title: 'Products',
    icon: () => <IcoBook />,
  },
  bundles: {
    key: 'bundles',
    title: 'Bundles',
    icon: () => <IcoFolder />,
  },
  upsells: {
    key: 'upsells',
    title: 'Upsells',
    icon: () => <IcoChartBar />,
  },
};

function processCourses(courses: Course[]) {
  return courses
    .sort((a, b) => (b.lastOpenedAt?.getTime() || 0) - a.lastOpenedAt?.getTime() || 0)
    .reduce(
      (acc, c) => {
        if (c.level === 'student') {
          return acc;
        }

        const item = {
          ...c,
          isArchived: false,
          search: `${c.title.toLocaleLowerCase()} ${c.guide.name?.toLocaleLowerCase()} ${c.id}`,
          numStudents: c.numStudents || 0,
        };

        if (item.isBundle) {
          acc.bundles.push(item);
        } else if (item.isProduct) {
          acc.products.push(item);
        } else {
          acc.courses.push(item);
        }
        return acc;
      },
      {
        courses: [],
        bundles: [],
        products: [],
      } as {
        courses: Course[];
        bundles: Course[];
        products: Course[];
      },
    );
}

export function useRootGuideSearch(props: Props) {
  const tenant = useCurrentTenant();
  const user = useCurrentUser();
  const canUpsell = !tenant.isCore || user?.tier === 'pro' || user?.tier === 'core';

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  // Cache the data so we don't have to fetch it again
  const dataCache = useRef<PaletteData | undefined>(
    props.courses ? processCourses(props.courses) : undefined,
  );

  const showMenu = () => {
    if (!isMenuVisible) {
      setIsMenuVisible(true);
      hideModals();
      showModalForm(({ resolve }) => (
        <PaletteWrapper
          canUpsell={canUpsell}
          dataCache={dataCache.current}
          setDataCache={(val) => (dataCache.current = val)}
          hide={() => {
            setIsMenuVisible(false);
            resolve();
          }}
        />
      ));
    }
  };

  useCtrlKey('KeyK', (e) => {
    if (!isMenuVisible) {
      e.preventDefault();
      showMenu();
    }
  });

  return showMenu;
}

function PaletteWrapper({
  dataCache,
  setDataCache,
  canUpsell,
  hide,
}: {
  dataCache: PaletteData | undefined;
  setDataCache: (data: PaletteData) => void;
  canUpsell: boolean;
  hide: () => void;
}) {
  const [paletteData, setPaletteData] = useState<PaletteData | undefined>(dataCache);

  useAsyncEffect(async () => {
    const [courseData, upsellData] = await Promise.all([
      dataCache?.courses
        ? Promise.resolve(dataCache)
        : rpx.courses
            .getMyCourses({
              asGuide: false,
            })
            .then(processCourses),
      dataCache?.upsells || !canUpsell
        ? Promise.resolve(dataCache?.upsells || [])
        : rpx.upsells.getMyUpsells(),
    ]);
    const paletteData = {
      ...courseData,
      upsells: upsellData,
    };
    setDataCache(paletteData);
    setPaletteData(paletteData);
  }, []);

  return <Palette data={paletteData} canUpsell={canUpsell} hide={hide} />;
}

function Palette({
  data,
  canUpsell,
  hide,
}: {
  data?: PaletteData;
  canUpsell: boolean;
  hide(): void;
}) {
  const [searchState, setSearchState] = useState({
    term: '',
    type: undefined as ResultType | undefined,
  });
  const matchingItems = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const term = new RegExp(searchState.term, 'i');
    const filterItems = (items: Item[] = [], type: ResultType) => {
      const filtered = items.filter((item) => term.test(item.title) || term.test(item.id));
      // We display the first 6 items per category if it's not focused
      const displayed = type === searchState.type ? filtered : filtered.slice(0, 6);

      return {
        items: displayed,
        ids: displayed.map((item) => item.id),
        totalCount: filtered.length,
      };
    };

    const courses = filterItems(data?.courses, 'courses');
    const products = filterItems(data?.products, 'products');
    const bundles = filterItems(data?.bundles, 'bundles');
    const upsells = filterItems(data?.upsells, 'upsells');

    return {
      allIds: [...courses.ids, ...products.ids, ...bundles.ids],
      courses,
      products,
      bundles,
      upsells,
    };
  }, [data, searchState.term, searchState.type]);

  const selectedId = useKeyNavigation({
    items: matchingItems?.allIds || [],
    inputs: [searchState.term, searchState.type],
    onSelect: (selectedId) => {
      const el = document.getElementById(`cmd-${selectedId}`);
      el?.click();
      hide();
    },
  });

  return (
    <BaseDialog contentWidth onClose={hide}>
      <div class="flex flex-col min-h-full sm:w-4xl w-full h-screen-minus-4 overflow-auto">
        <header class="sticky top-0 bg-white z-10 pb-4">
          <SearchBox
            placeholder="Type something to search..."
            focusOnce
            class="ruz-input font-semibold block w-full pl-10 p-4 text-sm rounded-none border-none border-gray-200"
            containerClass="w-full border-b"
            onTermChange={(term) => setSearchState((s) => ({ ...s, term }))}
            value={searchState.term}
          />
          <div class="p-4 pb-0 text-gray-500">
            <p class="pl-2">What are you looking for?</p>
            <div class="grid grid-cols-2 md:flex md:flex-wrap gap-2 md:gap-1 mt-2">
              {resultTypes.map((type) => {
                if (!canUpsell && type === 'upsells') {
                  return null;
                }

                const config = sectionConfig[type];
                const isSelected = searchState.type === config.key;
                const totalHits = matchingItems?.[config.key]?.totalCount;
                const showTotalHits = searchState.type === undefined || isSelected;

                return (
                  <Button
                    key={config.key}
                    class={`inline-flex items-center px-3 md:px-2 py-1 capitalize rounded-lg text-xs font-semibold gap-1 outline-hidden hover:bg-indigo-100 ${
                      isSelected ? 'bg-indigo-200 text-gray-900' : 'bg-gray-100 text-gray-600'
                    }`}
                    onClick={() =>
                      setSearchState((s) => ({ ...s, type: isSelected ? undefined : config.key }))
                    }
                  >
                    {config.icon?.()}
                    <span class="grow text-left">
                      {config.title}
                      {showTotalHits && totalHits !== undefined && (
                        <span class="ml-1 text-gray-600">({totalHits})</span>
                      )}
                    </span>
                    {isSelected && <IcoX />}
                  </Button>
                );
              })}
            </div>
          </div>
        </header>
        <div class="flex flex-col p-4 pt-0">
          {matchingItems === undefined && <LoadingIndicator />}
          {matchingItems !== undefined && (
            <div class="divide-y">
              {Object.values(sectionConfig).map((config) => {
                if (searchState.type && searchState.type !== config.key) {
                  return null;
                }

                if (!canUpsell && config.key === 'upsells') {
                  return null;
                }

                return (
                  <Section
                    key={config.key}
                    title={config.title}
                    data={matchingItems[config.key]}
                    type={config.key}
                    searchTerm={searchState.term}
                    isFocused={searchState.type === config.key}
                    selectedId={selectedId}
                    onFocus={() => setSearchState((s) => ({ ...s, type: config.key }))}
                    hide={hide}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </BaseDialog>
  );
}

function Section({
  title,
  data,
  type,
  isFocused,
  searchTerm,
  selectedId,
  onFocus,
  hide,
}: {
  title: string;
  data: {
    items: Item[];
    totalCount: number;
  };
  type: ResultType;
  searchTerm: string;
  isFocused?: boolean;
  selectedId: string;
  onFocus: () => void;
  hide: () => void;
}) {
  const { items, totalCount } = data;

  return (
    <div class="p-2">
      <h3 class="text-base text-gray-700 font-semibold capitalize">{title}</h3>
      <h4 class="-mt-1 text-sm text-gray-500">
        <Case
          when={totalCount > items.length}
          fallback={`${items.length} ${pluralize('result', items.length)}`}
        >
          {items.length} {pluralize('result', items.length)} shown
          {!!totalCount && !isFocused && (
            <Button class="ml-1 text-indigo-600" onClick={onFocus}>
              ({totalCount} total)
            </Button>
          )}
        </Case>
      </h4>
      <div class="grid sm:grid-cols-2">
        {items.map((item) => (
          <SectionItem
            key={item.id}
            item={item}
            type={type}
            isSelected={item.id === selectedId}
            searchTerm={searchTerm}
            onClick={hide}
          />
        ))}
      </div>
    </div>
  );
}

function SectionItem({
  item,
  isSelected,
  searchTerm,
  type,
  onClick,
}: {
  item: Item;
  searchTerm: string;
  isSelected: boolean;
  type: ResultType;
  onClick: () => void;
}) {
  const src = useImageUrl(item.imagePath);
  const buttonId = `cmd-${item.id}`;
  const { prefix, highlight, suffix } = useMemo(
    () => highlightTerms(item.title, searchTerm),
    [item.title, searchTerm],
  );

  useEffect(() => {
    if (isSelected) {
      const el = document.getElementById(buttonId);
      el?.scrollIntoView({ block: 'center' });
    }
  }, [buttonId, isSelected]);

  return (
    <Button
      id={buttonId}
      class={`block w-full rounded-md text-left border-2 text-gray-600 hover:text-gray-700 hover:bg-gray-50 p-2 ${
        isSelected ? 'border-indigo-500' : 'border-transparent'
      }`}
      href={
        type === 'upsells'
          ? `/upsells/${item.id}`
          : URLS.guide.course({
              courseId: item.id,
            })
      }
      onClick={onClick}
    >
      <span class={`flex space-x-3 items-center`}>
        <span class="w-12 h-12 min-w-12 min-h-12 p-2 rounded-md bg-blue-100 text-indigo-600 inline-flex items-center justify-center">
          <Case
            when={!src}
            fallback={<img src={`${src}?width=600`} class="object-fill w-10 h-10" />}
          >
            {type === 'courses' && <IcoAcademicCap class="w-6 h-6 opacity-75" />}
            {type === 'products' && <IcoBook class="w-6 h-6 opacity-75" />}
            {type === 'bundles' && <IcoFolder class="w-6 h-6 opacity-75" />}
          </Case>
        </span>
        {}
        <span>
          <span class="line-clamp-1 grow font-semibold">
            <span>{prefix}</span>
            {highlight && <span class="font-bold bg-yellow-200">{highlight}</span>}
            {suffix && <span>{suffix}</span>}
          </span>
          {type !== 'upsells' && (
            <span class="inline-flex items-center text-xs text-gray-500">
              <span class="pr-3 border-r">
                {item.status === 'published' ? 'Signups open' : 'Signups closed'}
              </span>
              <span class="inline-flex items-center pl-3">
                <IcoUserGroup class="w-4 h-4 mr-1 opacity-75" />
                {item.numStudents || 0}
              </span>
            </span>
          )}
        </span>
      </span>
    </Button>
  );
}
