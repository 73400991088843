import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import * as urls from './urls';
import { RouteLoadProps } from '@components/router';

const localStorageKey = 'pricing/recentfolder';

function generateKey(productId: UUID) {
  return `${localStorageKey}/${productId}`;
}

export function useRecentlyViewedTracker({
  folderId,
  productId,
}: {
  folderId: string;
  productId: UUID;
}) {
  useDidUpdateEffect(() => {
    const key = generateKey(productId);
    if (folderId) {
      localStorage.setItem(key, folderId);
    } else {
      localStorage.removeItem(key);
    }
  }, [folderId]);
}

export function goToRecentlyViewed(route: RouteLoadProps) {
  if (!route.params.folderId) {
    const folderId = localStorage.getItem(generateKey(route.params.productId)) || '';
    route.params.folderId = folderId;
    route.router.rewrite(urls.folderRoute({ productId: route.params.productId, folderId }));
  }
}
