/**
 * Simple helpers for dealing with iCalander (ics).
 */

import ICAL from 'ical.js';
import { UserProfileRow } from 'server/types';
import { EventRow, EventTypeRow } from 'server/types/cal-schema';

type ICSUser = {
  name: string;
  email: string;
};

/**
 * Create an ICS User entry.
 * If partstat is undefined, we generate a simple organizer entry:
 *  CN=Chris Davies:mailto:chris@example.com
 *
 * Otherwise, it's an attendee entry:
 * CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;CN=Gonna
 *  Cancel:mailto:chris+gonnacancel@exmple.com
 */
export function icalUser(
  componentName: string,
  u: ICSUser & { partstat?: 'ACCEPTED' | 'NEEDS-ACTION' },
) {
  const result = new ICAL.Property(componentName);
  result.setParameter('cn', u.name);
  result.setValue('mailto:' + u.email);
  if (u.partstat) {
    result.setParameter('cutype', 'INDIVIDUAL');
    result.setParameter('role', 'REQ-PARTICIPANT');
    result.setParameter('partstat', u.partstat);
  }
  return result;
}

export type VEventOpts = Pick<EventRow, 'id' | 'end' | 'start' | 'icalSequence'> &
  Pick<EventTypeRow, 'name' | 'location'> & {
    attendee: Pick<UserProfileRow, 'name' | 'email'>;
    eventURL: string;
    host: Pick<UserProfileRow, 'name' | 'email'>;
  };

export function toVevent(opts: VEventOpts) {
  const vevent = new ICAL.Component('VEVENT');
  const locationDescription = opts.location === 'jitsi' ? ` on Jitsi Meet` : '';
  const description = [
    `Meeting with ${opts.host.name} and ${opts.attendee.name}${locationDescription}.`,
    '',
    'Meeting URL:',
    '',
    opts.eventURL,
  ].join('\n');

  vevent.addPropertyWithValue('uid', opts.id);
  vevent.addPropertyWithValue('sequence', opts.icalSequence || 0);
  vevent.addPropertyWithValue('dtstart', ICAL.Time.fromJSDate(opts.start, true));
  vevent.addPropertyWithValue('dtend', ICAL.Time.fromJSDate(opts.end, true));
  vevent.addPropertyWithValue('summary', opts.name);
  vevent.addPropertyWithValue('status', 'CONFIRMED');
  vevent.addProperty(
    icalUser('organizer', { name: 'Ruzuku Calendar', email: 'noreply+ruzcal@ruzuku.com' }),
  );
  vevent.addProperty(icalUser('attendee', { ...opts.host, partstat: 'ACCEPTED' }));
  vevent.addProperty(icalUser('attendee', { ...opts.attendee, partstat: 'ACCEPTED' }));

  if (opts.location) {
    const location = new ICAL.Property('location');
    location.setParameter('altrep', opts.eventURL);
    location.setValue(opts.location);
    vevent.addProperty(location);
  }
  vevent.addPropertyWithValue('description', description);
  return vevent;
}

export function toIcs(opts: VEventOpts & { prodid: string; status: 'CANCELLED' | 'CONFIRMED' }) {
  const comp = new ICAL.Component(['VCALENDAR', [], []]);
  comp.addPropertyWithValue('method', opts.status === 'CANCELLED' ? 'CANCEL' : 'REQUEST');
  comp.addPropertyWithValue('prodid', opts.prodid);
  comp.addPropertyWithValue('version', '2.0');
  comp.addPropertyWithValue('calscale', 'GREGORIAN');
  comp.addSubcomponent(toVevent(opts));
  return comp.toString();
}

/**
 * Convert a Ruzcal event to an ics blob.
 */
export function eventToIcal({
  event,
  eventType,
  host,
  attendee,
  eventURL,
  isCanceled,
}: {
  event: Pick<EventRow, 'id' | 'start' | 'end' | 'icalSequence'>;
  eventType: Pick<EventTypeRow, 'name' | 'location' | 'locationDetail'>;
  host: Pick<UserProfileRow, 'name' | 'email'>;
  attendee: Pick<UserProfileRow, 'name' | 'email'>;
  eventURL: string;
  isCanceled: boolean;
}) {
  const ics = toIcs({
    ...event,
    prodid: 'calendar.ruzuku.com',
    name: eventType.name,
    host,
    location: eventType.location,
    attendee,
    status: isCanceled ? 'CANCELLED' : 'CONFIRMED',
    eventURL,
  });

  return {
    ics,
    data: new Blob([ics], { type: 'text/calendar;charset=utf-8' }),
    filename: 'meeting.ics',
  };
}

function* getVEventDates(event: ICAL.Component, rangeStart: Date, rangeEnd: Date) {
  const iterator = new ICAL.RecurExpansion({
    component: event,
    dtstart: event.getFirstPropertyValue('dtstart') as ICAL.Time,
  });
  const e = new ICAL.Event(event);
  for (let next = iterator.next(); next && next.toJSDate() <= rangeEnd; next = iterator.next()) {
    const start = next.toJSDate();
    next.addDuration(e.duration);
    const end = next.toJSDate();
    if (end < rangeStart) {
      continue;
    }
    yield { start, end };
  }
}

/**
 * Generate all date ranges in the calendar that fall inside the
 * specified range (inclusive).
 */
export function* getICalEventDates(cal: ICAL.Component, rangeStart: Date, rangeEnd: Date) {
  for (const evt of cal.getAllSubcomponents('vevent')) {
    for (const block of getVEventDates(evt, rangeStart, rangeEnd)) {
      yield block;
    }
  }
}
