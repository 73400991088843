import { AsyncForm, FormGroup } from '@components/async-form';
import { LoginWrapper } from '@components/login-wrapper';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router';

export const route = defRoute({
  isPublic: true,
  load,
  Page,
});

async function load(route: RouteLoadProps) {
  const { courseId } = route.params;
  if (!courseId) {
    return { brand: undefined };
  }

  const brand = await rpx.branding.getBrand({ courseId });
  return { brand };
}

function SigninLink() {
  const intl = useIntl();
  return (
    <footer class="mt-8 pt-4 text-center block border-t border-gray-200">
      <a href={`/login${location.search}`}>{intl('← Sign in')}</a>
    </footer>
  );
}

function Page(props: LoadedProps<typeof load>) {
  const intl = useIntl();
  const [email, setEmail] = useState(props.params.email || '');
  const [emailSent, setEmailSent] = useState(false);

  useDocumentTitle(['Forgot Your Password?']);

  return (
    <LoginWrapper brand={props.state.brand}>
      {emailSent && (
        <div>
          <h1 class="text-xl text-indigo-500">{intl('Email sent!')}</h1>
          <p class="text-cool-gray-600">
            {intl('In a few minutes, a sign in link should arrive in your email inbox.')}
          </p>
          <SigninLink />
        </div>
      )}
      {!emailSent && (
        <AsyncForm
          class="flex flex-col gap-4"
          onSubmit={(data) => rpx.auth.forgotPassword(data).then(() => setEmailSent(true))}
        >
          <FormGroup prop="email">
            <input
              type="text"
              placeholder="email"
              name="email"
              value={email}
              onInput={(e: any) => setEmail(e.target.value)}
              class="ruz-input block w-full sm:text-sm sm:leading-5 bg-transparent"
              data-private
            />
          </FormGroup>
          <Button class="btn-primary w-full py-3">{intl('Email sign in link')}</Button>
          <SigninLink />
        </AsyncForm>
      )}
    </LoginWrapper>
  );
}
