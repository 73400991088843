import { AsyncForm, FormSection, FormSubSection } from '@components/async-form';
import { useMemo, useState } from 'preact/hooks';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { useUnsavedWarning } from '@components/autosaver';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@components/admin-nav';
import { useCurrentTenant } from '@components/router/session-context';
import { defaultTerminology } from 'shared/terminology';
import { TerminologyKeys } from 'server/types';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { defRoute } from '@components/router';

export const route = defRoute({
  authLevel: 'admin',
  Page,
});

function ModelInputs({
  singular,
  plural,
  onChange,
}: {
  singular: TerminologyKeys;
  plural: TerminologyKeys;
  onChange: (key: TerminologyKeys, value: string) => void;
}) {
  const { terminology, isCore } = useCurrentTenant();

  return (
    <div>
      <h3 class="text-base font-bold">{defaultTerminology[singular]}</h3>
      <div class="flex items-center space-x-2">
        <div>
          <label class="text-xs font-light">Singular</label>
          <input
            type="text"
            name="name"
            class={`ruz-input lowercase ${isCore ? 'text-gray-400' : ''}`}
            placeholder={defaultTerminology[singular]}
            defaultValue={terminology[singular]}
            disabled={isCore}
            title={isCore ? 'Core tenant terminology cannot be modified' : ''}
            onInput={(e: any) => onChange(singular, e.target.value.toLowerCase())}
          />
        </div>
        <div>
          <label class="text-xs font-light">Plural</label>
          <input
            type="text"
            name="name"
            class={`ruz-input lowercase ${isCore ? 'text-gray-400' : ''}`}
            placeholder={defaultTerminology[plural]}
            defaultValue={terminology[plural]}
            disabled={isCore}
            title={isCore ? 'Core tenant terminology cannot be modified' : ''}
            onInput={(e: any) => onChange(plural, e.target.value.toLowerCase())}
          />
        </div>
      </div>
    </div>
  );
}

function Page() {
  const tenant = useCurrentTenant();
  const [state, setState] = useState(() => {
    // Filter out Capital keys from the terminology runtime object
    // as we are not persisting them.
    return Object.keys(tenant.terminology).reduce((acc, k) => {
      const key = k as TerminologyKeys;
      if (key === key.toLowerCase()) {
        acc[key] = tenant.terminology[key];
      }
      return acc;
    }, {} as Record<TerminologyKeys, string>);
    // tenant.terminology
  });
  const initialState = useMemo(() => state, []);
  const hasUnsavedChanges = initialState !== state;
  const warnOnReload = {
    shouldWarn: hasUnsavedChanges,
  };

  function handleModelChange(key: TerminologyKeys, value: string) {
    setState((state) => ({ ...state, [key]: value }));
  }

  useUnsavedWarning(() => warnOnReload.shouldWarn);

  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="terminology" pageTitle="Terminology" />
        <AdminTabWrapper>
          <AdminTabHeader title="Custom Terminology" />
          <AsyncForm
            class="pb-20 mb-20 w-4xl max-w-full"
            onSubmit={async () => {
              try {
                await rpx.tenants.updateTenantTerminology({
                  tenantId: tenant.id,
                  terminology: state,
                });
                warnOnReload.shouldWarn = false;
                location.reload();
              } catch (err) {
                showError(err);
              }
            }}
          >
            <FormSection>
              <FormSubSection class="grid grid-cols-2 gap-8 grid-bor text-gray-500">
                <ModelInputs singular="course" plural="courses" onChange={handleModelChange} />
                <ModelInputs singular="module" plural="modules" onChange={handleModelChange} />
                <ModelInputs singular="lesson" plural="lessons" onChange={handleModelChange} />
                <ModelInputs singular="meeting" plural="meetings" onChange={handleModelChange} />
                <ModelInputs
                  singular="notification"
                  plural="notifications"
                  onChange={handleModelChange}
                />
                <ModelInputs
                  singular="discussion"
                  plural="discussions"
                  onChange={handleModelChange}
                />
                <ModelInputs singular="person" plural="people" onChange={handleModelChange} />
                <ModelInputs singular="guide" plural="guides" onChange={handleModelChange} />
                <ModelInputs
                  singular="facilitator"
                  plural="facilitators"
                  onChange={handleModelChange}
                />
              </FormSubSection>

              {hasUnsavedChanges && (
                <footer class="max-w-5xl my-10 mx-auto space-x-4 an-slide-up">
                  <BtnPrimary>Save changes</BtnPrimary>
                  <BtnSecondary
                    onClick={() => {
                      warnOnReload.shouldWarn = false;
                      location.reload();
                    }}
                  >
                    Cancel
                  </BtnSecondary>
                </footer>
              )}
            </FormSection>
          </AsyncForm>
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}
