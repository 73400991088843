/**
 * This file contains the call to action sales page block.
 */

import { EditorProps, SalesBlockInitialState, ViewerProps } from '@components/page-builder';
import * as CTA from '@components/page-builder/cta-button';

export const initialState = CTA.initialState;

export const name = 'Call to Action';

export const type = 'cta';

export const initialBlockState: SalesBlockInitialState = {
  type,
  paddingb: 'sm',
  paddingt: 'sm',
};

export function MiniView() {
  return (
    <span class="flex justify-center py-4">
      <span class="flex flex-col items-center w-3/4">
        <span class="block bg-gray-700 opacity-75 rounded-sm h-2 w-1/6"></span>
      </span>
    </span>
  );
}

export const defaultChoice = { description: 'Go to pricing table', url: '#pricing' };

export const CtaBlock = (props: EditorProps<any> | ViewerProps<any>) => {
  return (
    <div class="text-center">
      <CTA.CtaButton {...props} defaultChoice={defaultChoice} path="" />
    </div>
  );
};

export const Viewer = CtaBlock;

export const Editor = CtaBlock;
