import { LessonEditor } from './lesson-editor';
import { ModulesNav, ModulesPage } from './modules-nav';
import { LoadedProps, RouteLoadProps, useRouteParams } from '@components/router';
import { useEffect, useState } from 'preact/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { scrollToModule } from './scroll-to-module';
import { CopyModal, CopyModalProps } from './copy-modal';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ModuleForm } from '@components/module-helpers';
import { URLS } from 'shared/urls';
import { useCurrentTenant } from '@components/router/session-context';
import { showToast } from '@components/toaster';
import { emptyLessonTitle } from 'shared/terminology';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { useLocalStorageState } from 'client/lib/hooks';
import { GuideCoursePage } from '@components/guide-course-page';
import { DialogFooter, StandardDialog, showDialog } from '@components/dialog';
import {
  deleteLesson,
  deletedModule,
  updateModule,
  loadState,
  refreshOutline,
  saveLesson,
  load,
  getCurrentCourse,
  getCurrentLesson,
} from './state';
import { defCoursesRoute } from '@components/courses-app-router';

const PIN_STORAGE_KEY = 'guidePinSideNav';

const loadLessonRoute = (route: RouteLoadProps) =>
  load(route.params.courseId, route.params.lessonId);

export const guideLessonPage = {
  route: defCoursesRoute({
    load: loadLessonRoute,
    Page,
    authLevel: 'guide',
  }),
};

export const guideModulePage = {
  route: defCoursesRoute({
    load: loadModuleRoute,
    Page() {
      return <LoadingIndicator />;
    },
    authLevel: 'guide',
  }),
};

function Page(props: LoadedProps<typeof loadLessonRoute>) {
  const { state, setState } = props;
  const params = useRouteParams();
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const { courseId } = state;
  const course = getCurrentCourse(state);
  const lesson = getCurrentLesson(state);
  const [copying, setCopying] = useState<
    Omit<CopyModalProps, 'refreshOutline' | 'onClose'> | undefined
  >(undefined);
  const [isPinned, setIsPinned] = useLocalStorageState(PIN_STORAGE_KEY, true);
  const [showOutline, setShowOutline] = useState(false); // If the outline is unpinned, this controls whether or not it is visible / hidde

  const module = state.moduleId ? state.modules[state.moduleId] : undefined;
  const closeModuleEditor = () => setState((s) => ({ ...s, moduleId: undefined }));

  const promptForDelete = async (lessonId: UUID) => {
    if (!lesson) {
      return;
    }

    const confirmed = await showDialog({
      mode: 'warn',
      title: `Permanently delete ${terminology.lesson}?`,
      confirmButtonText: `Permanently delete ${terminology.lesson}`,
      children: (
        <>
          Are you sure you want to delete {terminology.lesson} &quot;
          <em class="text-black">{lesson.title || emptyLessonTitle(tenant)}</em>&quot;? This action
          cannot be undone.
        </>
      ),
    });

    if (!confirmed) {
      return;
    }

    // Perform the delete.
    try {
      await deleteLesson(setState, { courseId: course.id, lessonId: lesson.id });
      showToast({
        type: 'ok',
        title: `${terminology.lesson} #${lesson.id} deleted`,
        message: `Deleted "${lesson.title}".`,
      });
      // We're deleting the current lesson, so redirect to the nearest sibling
      // lesson, if there is one.
      const lessonIds = course.modules.flatMap((s) => state.modules[s].lessons);
      const index = lessonIds.indexOf(lessonId);
      const nextLesson = lessonIds[index - 1] || lessonIds[index + 1];

      if (nextLesson) {
        props.router.goto(`/manage/courses/${course.id}/lessons/${nextLesson}`);
      }
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    if (state.courseId !== params.courseId || state.lessonId !== params.lessonId) {
      loadState(setState, {
        courseId: params.courseId,
        lessonId: params.lessonId,
        currentLessonId: state.lessonId,
      });
    }
  }, [params.courseId, params.lessonId, state.courseId, state.lessonId]);

  useEffect(() => {
    // When we are editing a module, we'll ensure it's scrolled into view in the nav,
    // but won't bother animating the highlight, as that's distracting.
    state.moduleId && scrollToModule(state.moduleId, { highlight: false });
  }, [state.moduleId]);

  useAsyncEffect(async () => {
    if (!courseId) {
      return;
    }
    try {
      await rpx.courses.setLastOpenedAt({ courseId });
    } catch (err) {
      console.warn('Could not set last_opened_at', err);
    }
  }, [courseId]);

  return (
    <GuideCoursePage
      course={course}
      viewLink={
        lesson &&
        URLS.student.lesson({
          course,
          lesson,
        })
      }
      type="lessons"
    >
      <TutorialModal />
      {!lesson && <ModulesPage state={state} setState={setState} />}
      {lesson && (
        <div class="guide-page-content flex grow bg-white">
          <ModulesNav
            state={state}
            setState={setState}
            isPinned={isPinned}
            setIsPinned={setIsPinned}
            showOutline={showOutline}
            setShowOutline={setShowOutline}
          />
          <div class="grow p-4 md:p-8">
            <LessonEditor
              key={lesson.id}
              lesson={lesson}
              course={course}
              onDeleteLesson={(id) => promptForDelete(id)}
              onCopyLesson={(id) => {
                setCopying({ type: 'lesson', item: state.lessons[id] });
              }}
              onSave={(lesson) => saveLesson(setState, { courseId, lesson })}
              showOutlineButton={!isPinned}
              onOutlineClick={() => setShowOutline(true)}
            />
          </div>
        </div>
      )}
      {state.loadingLesson && <LoadingIndicator />}
      {copying && (
        <CopyModal
          {...copying}
          refreshOutline={() => refreshOutline(setState, { courseId: params.courseId })}
          onClose={() => {
            setCopying(undefined);
          }}
        />
      )}
      {module && (
        <ModuleForm
          key={module.id}
          id={module.id}
          courseId={course.id}
          accessFormat={course.accessFormat}
          isAbsoluteSchedule={!!course.isAbsoluteSchedule}
          title={module.title}
          prices={module.prices}
          isDraft={module.isDraft}
          startOffset={module.startOffset}
          startDate={module.startDate}
          onDelete={() => {
            deletedModule(setState, module.id);
          }}
          onUpdate={(opts) => {
            const result = updateModule(setState, state, { id: module.id, ...opts });
            result.then(() => scrollToModule(module.id));
            return result;
          }}
          onClose={closeModuleEditor}
          onCopy={() => setCopying({ type: 'module', item: module })}
        />
      )}
    </GuideCoursePage>
  );
}

function TutorialModal() {
  const { showTutorial } = useRouteParams();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(showTutorial !== undefined), [showTutorial]);

  if (!isOpen) {
    return null;
  }

  return (
    <StandardDialog contentWidth onClose={() => setIsOpen(false)}>
      <h2 class="text-lg lg:text-3xl font-semibold text-center py-2">
        Welcome to the Ruzuku course editor!
      </h2>
      <h4 class="text-lg text-center py-2">Watch this tutorial to get started...</h4>
      <iframe
        src="https://player.vimeo.com/video/845861404?h=4e222ae5de&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        className="w-full h-48 lg:h-96"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Ruzuku course setup"
      ></iframe>
      <DialogFooter hideCancel confirmButtonText="Jump into your course..." />
    </StandardDialog>
  );
}

/*
 * Fetches the first lesson of a module and redirect to it.
 */
async function loadModuleRoute(route: RouteLoadProps) {
  const firstLesson = await rpx.modules.getFirstLesson({ id: route.params.moduleId });
  const url = firstLesson
    ? URLS.guide.lesson({ courseId: route.params.courseId, lessonId: firstLesson.id })
    : URLS.guide.lessons({ courseId: route.params.courseId });
  route.router.rewrite(url);
}
