import { StudentPage } from '@components/student-page';
import { StudentCoursePreferences } from 'server/types';
import { useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import { Section } from './section';
import { Preference } from './preference';
import { NotificationPreferences } from './notification-preferences';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { DefaultSpinner } from '@components/spinner';
import { PurchasePane } from '../pmts/components/purchase-pane';
import { URLS } from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';
import { Button } from '@components/buttons';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defStudentRoute } from '@components/courses-app-router';

export const route = defStudentRoute({
  Page,
  authLevel: 'student',
  load,
});

export function PaymentPane({ courseId, guideEmail }: { courseId: UUID; guideEmail?: string }) {
  const intl = useIntl();
  const loader = useAsyncData(() => rpx.myCorePayments.getCoursePurchase({ courseId }), [courseId]);
  if (loader.isLoading) {
    return <DefaultSpinner />;
  }
  if (!loader.data?.purchase) {
    return null;
  }
  return (
    <div class="border-t dark:border-gray-700 pt-8 mt-10 dark:text-gray-200">
      <header class="mb-4">
        <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100 leading-6">
          {intl('Payment details')}
        </h3>
      </header>
      <PurchasePane {...loader.data} asSeller={false} guideEmail={guideEmail} />
    </div>
  );
}

function Page(props: LoadedProps<typeof load>) {
  const intl = useIntl();
  const { course, bundleId } = props.state;
  const [isSaving, setIsSaving] = useState(false);
  const [preferences, setPreferences] = useState(props.state.preferences);

  async function save(newPreferences: StudentCoursePreferences) {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    // Copy the current preferences object so we can fallback
    // to that when the API returns an exception.
    const oldPreferences = { ...preferences };
    // Update the preferences optimistically.
    setPreferences(newPreferences);

    try {
      await rpx.courses.saveStudentCoursePreferences({
        courseId: course.id,
        preferences: newPreferences,
      });
    } catch (err) {
      // Revert back the optimistic update.
      setPreferences(oldPreferences);
      showError(err);
    } finally {
      setIsSaving(false);
    }
  }

  const purchasedBundle = intl.split(
    `You have purchased this course through a bundle. Please visit <>the bundle page</> to see your payment details.`,
  );

  return (
    <StudentPage
      course={course}
      currentLink="preferences"
      editLink={{
        url: `/manage/courses/${course.id}`,
      }}
    >
      <div class="max-w-4xl p-8 mx-auto dark:text-gray-400">
        <h1 class="mb-8 text-2xl dark:text-gray-200">{intl('Course Preferences')}</h1>
        <div>
          <NotificationPreferences
            courseTitle={course.title}
            guideName={course.guide.name}
            preferences={preferences}
            save={save}
          />
          <Section title={intl('Privacy')} description={intl('Control what others see about you.')}>
            <fieldset>
              <legend class="mb-4 font-medium text-gray-900 dark:text-gray-200">
                {intl('Profile Page')}
              </legend>
              <div class="space-y-8">
                <Preference
                  title={intl('Completed Lessons')}
                  description={intl('Display my completed lessons on my profile.')}
                  checked={preferences.displayCompletedLessons}
                  onToggle={() => {
                    save({
                      ...preferences,
                      displayCompletedLessons: !preferences.displayCompletedLessons,
                    });
                  }}
                />
                <Preference
                  title={intl('Chats')}
                  description={intl('Let other participants start a chat with me.')}
                  checked={preferences.enableIncomingChats}
                  onToggle={() => {
                    save({
                      ...preferences,
                      enableIncomingChats: !preferences.enableIncomingChats,
                    });
                  }}
                />
              </div>
            </fieldset>
          </Section>
          {bundleId && (
            <Section title={intl('Bundle details')} description="">
              <p>
                {purchasedBundle[0]}
                <Button
                  class="dark:underline"
                  href={URLS.student.course({
                    course: {
                      id: bundleId,
                      title: '',
                    },
                  })}
                >
                  {purchasedBundle[1]}
                </Button>
                {purchasedBundle[2]}
              </p>
            </Section>
          )}
          <PaymentPane courseId={course.id} guideEmail={course.guide.email} />
        </div>
      </div>
    </StudentPage>
  );
}

async function load(route: RouteLoadProps) {
  const routeParams = route.params;
  const courseId = routeParams.courseId;
  const [course, preferences] = await Promise.all([
    rpx.courses.getStudentCourse({ id: courseId }),
    rpx.courses.getStudentCoursePreferences({ id: courseId }),
  ]);

  return {
    course,
    bundleId: course.bundleId,
    preferences,
  };
}
