import { h } from 'preact';
import { useCurrentUser } from 'client/lib/auth';
import { IcoArrowLeft, IcoPlus, IcoTrash } from '@components/icons';
import { RuzcalMgmtPage } from './mgmt-page';
import { useMemo } from 'preact/hooks';
import { Toggle } from '@components/toggle';
import dayjs from 'dayjs';
import { BtnPrimary, Button } from '@components/buttons';
import { Combobox } from './combobox';
import { focusRef } from 'client/utils/autofocus';
import { PageContent, PageHeading, PageSection } from './common';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import type { TimeSlot } from 'server/types/cal-overrides';
import { AsyncForm } from '@components/async-form';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router';

type State = RpxResponse<typeof rpx.ruzcal.getAvailability>;

const defaultName = 'Business hours';

export const route = defRoute({ load, authLevel: 'superadmin', Page });

async function load(opts: RouteLoadProps): Promise<State> {
  const { id } = opts.params;
  const isNew = id === 'new';
  const user = opts.auth.user!;
  if (isNew) {
    return {
      timeslots: [
        {
          days: [1, 2, 3, 4, 5],
          start: '9:00am',
          end: '5:00pm',
        },
      ],
      isDefault: true,
      id,
      name: '',
      hostId: user.id,
      timezone: user.timezone,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }
  return await rpx.ruzcal.getAvailability({ id });
}

function addMins(mins: number, time?: string) {
  if (!time) {
    return;
  }

  const date = dayjs('2000-01-01 ' + time).add(mins, 'minute');
  if (date.isValid()) {
    return date.format('h:mma');
  }
}

function parseTime(s: string, hour12: boolean) {
  const value = s.toLowerCase();
  const ampm = value.includes('a') ? 'am' : 'pm';
  const [h, m] = value.split(/[^0-9]/);
  const hh = parseInt(h, 10) || new Date().getHours();
  const mm = `${parseInt(m, 10) || 0}`.padEnd(2, '0');
  return `${hh}:${mm}${hour12 ? ampm : ''}`;
}

function TimeInput({
  hour12,
  ...props
}: Omit<h.JSX.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  value: string;
  onChange(value: string): void;
  hour12: boolean;
}) {
  const times = useMemo(() => {
    return new Array(24).fill(0).flatMap((_, i) => {
      const hour = hour12 ? i % 12 || 12 : i;
      const ampm = hour12 ? (i >= 12 ? 'pm' : 'am') : '';
      return [`${hour}:00${ampm}`, `${hour}:30${ampm}`];
    });
  }, []);
  return (
    <Combobox
      class="inline-ruz-input w-24 p-3 px-4 text-sm"
      values={times}
      {...props}
      onBlur={(e: any) => {
        props.onChange(parseTime(e.target.value, hour12));
      }}
    />
  );
}

function EditTimeSlot({
  value,
  onChange,
  onDelete,
}: {
  value: TimeSlot;
  onChange(value: TimeSlot): void;
  onDelete(): void;
}) {
  const days = useMemo(
    () =>
      new Array(7).fill(0).map((_, i) => ({
        day: i,
        desc: dayjs().set('day', i).format('ddd'),
      })),
    [],
  );
  return (
    <div class="flex flex-col gap-4 p-6 rounded-lg bg-white shadow-sm">
      <section class="flex justify-between gap-4">
        <div class="inline-flex items-center gap-2">
          <TimeInput
            name="start"
            placeholder="9:00am"
            value={value.start}
            onChange={(start) => onChange({ ...value, start })}
            hour12
          />
          -
          <TimeInput
            name="end"
            placeholder="5:00pm"
            value={value.end}
            onChange={(end) => onChange({ ...value, end })}
            hour12
          />
        </div>
        <span>
          <Button
            onClick={onDelete}
            class="hover:bg-red-50 size-8 md:size-10 hover:text-red-600 rounded-md aspect-square inline-flex items-center justify-center ml-auto hover:ring-1 hover:ring-red-200"
          >
            <IcoTrash />
          </Button>
        </span>
      </section>
      <section class="grid grid-cols-7 gap-2 select-none">
        {days.map((d) => {
          const checked = value.days.includes(d.day);
          return (
            <label key={d} class="cursor-pointer relative group flex flex-col bg-white">
              <input
                type="checkbox"
                class="absolute opacity-0 z-0"
                checked={checked}
                value={d.day}
                name="days[]"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const toggled = value.days.filter((x) => x !== d.day);
                  if (!checked) {
                    toggled.push(d.day);
                  }
                  onChange({
                    ...value,
                    days: toggled,
                  });
                }}
              />
              <span
                class={`relative z-10 flex items-center justify-center transition-all px-2 py-1 font-medium rounded group border border-gray-300 ${
                  checked ? 'bg-indigo-50 border-indigo-200' : ''
                } group-focus-within:ring-2 gap-2 hover:ring-3`}
              >
                <span
                  class={`size-2 hidden sm:inline-block rounded-sm ${
                    checked ? 'bg-indigo-500' : 'bg-gray-200'
                  }`}
                ></span>
                <span>{d.desc}</span>
              </span>
            </label>
          );
        })}
      </section>
    </div>
  );
}

function Page({ state, setState, router }: LoadedProps<typeof load>) {
  const user = useCurrentUser()!;
  return (
    <RuzcalMgmtPage title="Edit availability" currentPage="availability">
      <PageContent>
        <PageSection>
          <header>
            <a href="/calendar/availability" class="inline-flex items-center gap-2">
              <IcoArrowLeft />
              Back to availability
            </a>
            <PageHeading title={`Edit ${state.name}`} />
          </header>
          <AsyncForm
            class="flex flex-col gap-8 max-w-2xl"
            onSubmit={async () => {
              await rpx.ruzcal.saveAvailability({
                name: state.name.trim() || defaultName,
                id: state.id === 'new' ? undefined : state.id,
                timezone: state.timezone,
                timeslots: state.timeslots,
                isDefault: state.isDefault,
              });
              router.goto('/calendar/availability');
              await new Promise((r) => setTimeout(r, 1000));
            }}
          >
            <label class="flex flex-col gap-1 max-w-80">
              <span class="font-medium">Title</span>
              <input
                type="text"
                name="name"
                class="inline-ruz-input px-4 p-3 text-sm"
                value={state.name}
                placeholder={`e.g. ${defaultName}`}
                ref={focusRef}
                onInput={(e: any) => setState((s) => ({ ...s, name: e.target.value }))}
              />
            </label>
            <section class="flex flex-col gap-1">
              <span class="font-medium">
                Available times{' '}
                <span class="text-gray-500">({user.timezone.replaceAll('_', ' ')} time)</span>
              </span>
              <div class="flex flex-col gap-6">
                {state.timeslots.map((slot) => (
                  <EditTimeSlot
                    key={`${slot.days}-${slot.start}-${slot.end}`}
                    value={slot}
                    onDelete={() =>
                      setState((s) => ({
                        ...s,
                        timeslots: s.timeslots.filter((x) => x !== slot),
                      }))
                    }
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        timeslots: s.timeslots.map((x) => (x === slot ? value : x)),
                      }))
                    }
                  />
                ))}

                <footer>
                  <Button
                    class="flex items-center gap-2 p-3 px-4 bg-gray-200 text-gray-900 hover:shadow-md font-medium rounded-lg"
                    onClick={() => {
                      const prev = state.timeslots[state.timeslots.length - 1];
                      setState((s) => ({
                        ...s,
                        timeslots: [
                          ...s.timeslots,
                          {
                            start: addMins(30, prev?.end) || '9:00am',
                            end: addMins(90, prev?.end) || '5:00pm',
                            days: prev?.days || [1, 2, 3, 4, 5],
                          },
                        ],
                      }));
                    }}
                  >
                    <IcoPlus /> Add a time window
                  </Button>
                </footer>
              </div>
            </section>
            <label class="inline-flex items-center gap-3 cursor-pointer select-none">
              <Toggle
                name="isDefault"
                checked={state.isDefault}
                onClick={() => setState((s) => ({ ...s, isDefault: !s.isDefault }))}
              />
              Is default
            </label>
            <footer class="flex gap-4">
              <BtnPrimary class="p-3 px-4">Save availability</BtnPrimary>
              <Button
                href="/calendar/availability"
                class="text-inherit inline-flex items-center justify-center rounded-md hover:bg-gray-100 px-4 transition-all"
              >
                Cancel
              </Button>
            </footer>
          </AsyncForm>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
