import { rpx } from 'client/lib/rpx-client';
import { URLS } from 'shared/urls';
import { ProvisioningForm } from '@components/checkout';
import { CourseCheckoutPage, finalCheckoutPageURL } from '@components/course-checkout';
import { useGiftState } from './gift-form';
import { useIntl } from 'shared/intl/use-intl';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defStudentRoute } from '@components/courses-app-router';

async function load(route: RouteLoadProps) {
  return rpx.courseCheckout.getProvisioningState({ courseId: route.params.courseId });
}

type Props = LoadedProps<typeof load>;

export const route = defStudentRoute({
  load,
  Page,
});

function Page(props: Props) {
  const intl = useIntl();
  const { course, purchase } = props.state;
  const [giftState, setGiftState] = useGiftState(course);
  const giftEmailScheduledAt =
    giftState?.emailScheduledAt instanceof Date
      ? giftState.emailScheduledAt.toISOString()
      : giftState?.emailScheduledAt;
  const isGift = props.params.gift === 'true' || !!giftState?.email;

  const provision = async () => {
    const result = await rpx.courseCheckout.provision({
      courseId: course.id,
      purchaseId: purchase.id,
      giftMessage: giftState?.message,
      giftShouldSendEmail: giftState?.shouldSendEmail,
      giftEmailScheduledAt,
    });
    if (result.giftId) {
      setGiftState(undefined);
      return URLS.student.coursePage({
        course,
        page: `gifts/${result.giftId}/success?emailSent=${!!giftState?.shouldSendEmail}&emailDate=${
          giftEmailScheduledAt || ''
        }`,
      });
    }
    return finalCheckoutPageURL({
      id: course.id,
      title: course.title,
    });
  };

  return (
    <CourseCheckoutPage
      course={course}
      title={course.isProduct || isGift ? intl('Thank you!') : intl('Thank you for joining!')}
    >
      <ProvisioningForm purchase={purchase} provision={provision} />
    </CourseCheckoutPage>
  );
}
