/**
 * This file contains the dropdown menu heavily based on tailwind ui
 * https://tailwindui.com/components/application-ui/elements/dropdowns
 */

import type { ComponentChildren, h } from 'preact';
import { useState } from 'preact/hooks';
import { IcoChevronDown } from '@components/icons';
import { useEsc } from 'client/utils/use-esc';
import { Button, ButtonProps } from '@components/buttons';

interface Props {
  class?: string;
  position?: string;
  bg?: string;
  triggerClass?: string;
  hideDownIcon?: boolean;
  fullWidth?: boolean;
  children?: ComponentChildren;
  disabled?: boolean;
  noPadding?: boolean;
  renderMenu: () => h.JSX.Element;
}

export function MenuItem(
  props: ButtonProps & {
    isSelected?: boolean;
  },
) {
  const { isSelected, ...restOfProps } = props;
  return (
    <Button
      type={props.href ? undefined : 'button'}
      class={`inline-flex items-center capitalize px-4 py-3 text-left text-sm leading-5 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-hidden focus:bg-gray-100 dark:focus:bg-gray-700 focus:text-gray-900 rounded ${
        isSelected ? 'text-indigo-600 dark:text-sky-400' : 'text-gray-700 dark:text-gray-200'
      }`}
      role="menuitem"
      {...restOfProps}
    />
  );
}

function autoPosition(el: (HTMLElement & { $positioned?: boolean }) | null) {
  if (!el || el.$positioned) {
    return;
  }

  el.$positioned = true;
  setTimeout(() => {
    const parent = el.offsetParent;
    const bounds = el.getBoundingClientRect();
    const parentBounds = parent?.getBoundingClientRect() || {
      top: 0,
      left: 0,
    };
    if (bounds.top < 0) {
      el.style.bottom = 'unset';
      el.style.top = `calc(${-parentBounds.top}px + 1rem)`;
    } else if (bounds.bottom > window.innerHeight) {
      el.style.bottom = 'unset';
      el.style.top = `calc(${
        bounds.top - parentBounds.top - (bounds.bottom - window.innerHeight)
      }px - 4rem)`;
    }
    if (bounds.left < 0) {
      el.style.right = 'unset';
      el.style.left = `calc(${-parentBounds.left}px + 1rem)`;
    }
  });
}

export function Menu({
  position = 'right-0 top-2',
  bg = 'bg-white',
  zIndex = 'z-20',
  children,
  p = 'pb-2',
  onHide,
}: {
  position?: string;
  bg?: string;
  p?: string;
  zIndex?: string;
  children?: ComponentChildren;
  onHide: () => void;
}) {
  useEsc(onHide);

  return (
    <div
      class={`origin-top-right flex flex-col absolute ${zIndex} ${p} ${position} min-w-56 rounded-md overflow-hidden shadow-lg ${bg} dark:bg-gray-800 ring-1 ring-black/5 focus:outline-hidden`}
      role="menu"
      aria-orientation="vertical"
      tabIndex={-1}
      onClick={onHide}
      ref={autoPosition}
    >
      {children}
    </div>
  );
}

/**
 * Display a dropdown menu.
 */
export function Dropdown({
  class: className,
  position,
  bg,
  hideDownIcon,
  triggerClass,
  fullWidth,
  children,
  disabled,
  noPadding,
  renderMenu,
}: Props) {
  const [visible, setVisible] = useState(false);

  return (
    <div
      class={`${className || ''} items-center justify-center ${
        fullWidth ? 'flex' : 'inline-flex'
      } flex-col ${visible ? 'z-50' : ''}`}
    >
      <Button
        disabled={disabled}
        class={`${fullWidth ? 'flex w-full' : 'inline-flex'} items-center justify-start rounded ${
          triggerClass || 'font-medium'
        }`}
        type="button"
        onClick={() => {
          // The setTimeout prevents the menu from automatically closing due to
          // the onClick global haendler. There are other workarounds, but this
          // was the simplest, and seems to work well for all cases.
          setTimeout(() => setVisible(!visible));
        }}
      >
        {children}
        {!hideDownIcon && (
          <span class="ml-auto pl-1 inline-flex items-center">
            <IcoChevronDown class="h-4 w-4" />
          </span>
        )}
      </Button>
      <div class="relative w-full">
        {visible && (
          <Menu
            position={position}
            bg={bg}
            p={noPadding ? 'p-0' : 'pb-2'}
            onHide={() => setVisible(false)}
          >
            {renderMenu()}
          </Menu>
        )}
      </div>
    </div>
  );
}
