import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

const featuredTestimonial = {
  prefix: `I can’t tell you how much I appreciate everything you guys do – from providing an awesome platform to your wonderful support.`,
  highlight: `It’s not an overestimation to say you’ve transformed my life and the lives of thousands (!!!)`,
  suffix: `of my students – long may it continue!`,
  author: {
    name: 'Manuel Puro',
    imagePath: 'manuel-puro.webp',
  },
};

const testimonials = [
  {
    prefix: '',
    highlight: 'Ruzuku has made my life so much easier and has helped me to help more women.',
    suffix: `Ruzuku has taken so much of the stress and effort out of running my program. I am very happy! I love the look and feel of my course — you all did a great job!`,
    highlightClass: 'from-blue-200 to-blue-50',
    author: {
      name: 'Sara Wiseman',
      imagePath: 'sara-wiseman.jpg',
    },
  },
  {
    prefix: '',
    highlight: 'I don’t feel like another number.',
    suffix: `Your team seems to truly care about the product and service. I feel very confident if there is a challenge, it will be addressed quickly and the resources available are invaluable.`,
    highlightClass: 'from-yellow-200 to-yellow-50',
    author: {
      name: 'Julie Olsen, DPA',
      imagePath: 'julie-olsen.webp',
    },
  },
  {
    highlight: 'I am very impressed by how easy Ruzuku is to use.',
    suffix: `And your support staff are really great. Miriam answered so quickly with just the right amount of problem-solving and empathy. You’ve clearly created a great culture there.`,
    highlightClass: 'from-amber-200 to-amber-50',
    author: {
      name: 'Paul Banas',
      imagePath: 'paul-banas.webp',
    },
  },
  {
    prefix: `Very happy with Ruzuku, which has revolutionised my online courses. product!`,
    highlight:
      'Clean design, intuitive admin and massive flexibility - you can see the PhD behind this',
    suffix: 'product',
    highlightClass: 'from-pink-200 to-pink-50',
    author: {
      name: 'Nigel Clark',
      imagePath: 'nigel-clark.webp',
    },
  },
];

export function TestimonialWall() {
  return (
    <div class="relative isolate pb-4 pt-2 overflow-y-auto max-h-screen">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-xl text-center">
          <p class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            What people say…
          </p>
        </div>
        <div class="mx-auto grid max-w-3xl grid-cols-1 grid-rows-1 gap-4 text-sm leading-6 text-gray-900 sm:mt-4 lg:grid-cols-2">
          <figure class="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2">
            <blockquote class="px-8 py-4 text-lg leading-7 tracking-tight text-gray-900 sm:leading-8">
              <p>
                “{featuredTestimonial.prefix}{' '}
                <span class="bg-linear-to-r from-green-200 to-green-50 rounded-sm">
                  {featuredTestimonial.highlight}
                </span>{' '}
                {featuredTestimonial.suffix}”
              </p>
            </blockquote>
            <figcaption class="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <img
                src={`${RUZUKU_ASSETS_BASE_URL}/onboarding/${featuredTestimonial.author.imagePath}`}
                class="h-10 w-10 flex-none rounded-full bg-gray-50"
              />
              <div class="flex-auto">
                <div class="font-semibold">{featuredTestimonial.author.name}</div>
              </div>
            </figcaption>
          </figure>
          {testimonials.map((t) => (
            <figure
              key={t.author.name}
              class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
            >
              <blockquote class="text-gray-900">
                <p>
                  “{t.prefix ? `${t.prefix} ` : ''}
                  <span class={`bg-linear-to-r rounded-sm ${t.highlightClass}`}>
                    {t.highlight}
                  </span>{' '}
                  {t.suffix}”
                </p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img
                  class="h-10 w-10 rounded-full bg-gray-50"
                  src={`${RUZUKU_ASSETS_BASE_URL}/onboarding/${t.author.imagePath}`}
                />
                <div class="font-semibold">{t.author.name}</div>
              </figcaption>
            </figure>
          ))}
        </div>
      </div>
    </div>
  );
}
