import { ComponentChildren } from 'preact';
import { IcoChatSolid, IcoHeartSolid, IcoAnnouncement, IcoReply, IcoAt } from '@components/icons';
import { Case } from '@components/conditional';
import { UserProfileIcon } from '@components/avatars';
import {
  AnnouncementNotification,
  CommentNotification,
  Course,
  NewDiscussionNotification,
  Notification,
  Tenant,
} from 'server/types';
import { URLS } from 'shared/urls';
import { useRouteParams } from '@components/router';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { Timeago } from '@components/timeago';
import { PulsingCircle } from '@components/pulsing-circle';
import { useIntl } from 'shared/intl/use-intl';
import { Internationalize } from 'shared/intl';
import { emptyLessonTitle } from 'shared/terminology';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { showModalForm } from '@components/modal-form';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { DialogHeader, StandardDialog } from '@components/dialog';

interface Props {
  course: Pick<Course, 'id' | 'title'>;
  item: Notification;
  isLast: boolean;
  unread: boolean;
  onSelected?(item: Notification): void;
}

type ItemProps<ItemType> = {
  item: ItemType;
  course: Props['course'];
  onSelected?(item: ItemType): void;
};

function replaceAnnouncementPlaceholders({
  text,
  guideName = '',
  studentName = '',
  courseTitle,
}: {
  text: string;
  guideName?: string;
  studentName?: string;
  courseTitle: string;
}) {
  return text
    .replaceAll('{{{recipient-name}}}', studentName)
    .replaceAll('{{{guide-name}}}', guideName)
    .replaceAll('{{{course-name}}}', courseTitle);
}

function Root({
  title,
  content,
  href,
  image,
  icon,
  bgColor = 'bg-indigo-500',
  date,
  onClick,
}: {
  title: string[];
  content?: string;
  href?: string;
  image: ComponentChildren;
  icon?: ComponentChildren;
  bgColor?: string;
  date: Date;
  onClick: (event: MouseEvent) => void;
}) {
  const [prefix, highlight, suffix] = title;

  return (
    <a
      class="relative p-4 flex items-start space-x-3 hover:bg-gray-50 rounded-lg dark:hover:bg-black/25 cursor-pointer"
      href={href}
      onClick={onClick}
    >
      <div class="relative mr-2">
        {image}
        <Case when={!!icon}>
          <span
            class={`absolute top-1/2 left-1/2 ${bgColor} rounded-full p-1 text-white border border-white`}
          >
            {icon}
          </span>
        </Case>
      </div>
      <div class="min-w-0 flex-1 relative">
        <div>
          <p class="text-sm text-gray-600 dark:text-gray-300 relative pr-3">
            <span>{prefix}</span>
            {!!highlight && <strong>{highlight}</strong>}
            {!!suffix && <span>{suffix}</span>}
          </p>
          <Timeago class="text-xs text-gray-400 dark:text-gray-300" date={date} />
        </div>
        {!!content && (
          <div class="mt-2 text-sm text-gray-700 dark:text-gray-300">
            <p class="break-all">{content}</p>
          </div>
        )}
      </div>
    </a>
  );
}

function generateCommentNotificationTitle({
  notification,
  intl,
  tenant,
}: {
  notification: CommentNotification;
  intl: Internationalize;
  tenant: Tenant;
}) {
  const { actor, type, discussion, lesson } = notification;
  const place = discussion.prompt
    ? intl('on "{prompt:string}" discussion', {
        prompt: discussion.prompt,
      })
    : intl('on "{lesson:string}" lesson', {
        lesson: lesson?.title || emptyLessonTitle(tenant),
      });

  if (type === 'commentLiked') {
    return intl.split('<>{actor:string}</> liked your comment {place:string}', {
      actor: actor.name,
      place,
    });
  }

  if (type === 'newComment') {
    return intl.split('<>{actor:string}</> commented {place:string}', {
      actor: actor.name,
      place,
    });
  }

  if (type === 'mention') {
    return intl.split('<>{actor:string}</> mentioned you in a comment {place:string}', {
      actor: actor.name,
      place,
    });
  }

  if (type === 'commentReplied' && notification.comment.isReplyToMe) {
    return intl.split('<>{actor:string}</> replied to your comment {place:string}', {
      actor: actor.name,
      place,
    });
  }

  return intl.split('<>{actor:string}</> replied to a comment {place:string}', {
    actor: actor.name,
    place,
  });
}

function CommentItem({ item, onSelected }: ItemProps<CommentNotification>) {
  const tenant = useCurrentTenant();
  const intl = useIntl();
  const { courseId, courseSlug } = useRouteParams();
  const { actor, type, comment, lesson, discussion } = item;

  return (
    <Root
      title={generateCommentNotificationTitle({
        notification: item,
        intl,
        tenant,
      })}
      content={comment.content}
      href={
        lesson
          ? URLS.student.comment({
              course: {
                id: courseId,
                title: courseSlug,
              },
              lesson,
              commentId: comment.id,
              parentCommentId: comment.parentId,
            })
          : URLS.student.discussionComment({
              course: {
                id: courseId,
                title: courseSlug,
              },
              discussionId: discussion.id,
              categoryId: genericDiscussionCategoryIds.all,
              commentId: comment.id,
              parentCommentId: comment.parentId,
            })
      }
      image={
        <UserProfileIcon
          size="h-10 w-10 ring-8 ring-gray-50 dark:ring-gray-800 rounded-full flex items-center justify-center"
          user={actor}
        />
      }
      bgColor={
        (type === 'commentLiked' && 'bg-pink-500') ||
        (type === 'commentReplied' && 'bg-indigo-500') ||
        undefined
      }
      icon={
        <>
          {type === 'newComment' && <IcoChatSolid class="w-4 h-4" />}
          {type === 'commentReplied' && <IcoReply class="w-4 h-4 " />}
          {type === 'commentLiked' && <IcoHeartSolid class="w-4 h-4" />}
          {type === 'mention' && <IcoAt class="w-4 h-4" strokeWidth="2.5" />}
        </>
      }
      date={item.createdAt}
      onClick={() => onSelected?.(item)}
    />
  );
}

function NewDiscussionItem({ item, onSelected }: ItemProps<NewDiscussionNotification>) {
  const intl = useIntl();
  const { courseId, courseSlug } = useRouteParams();

  return (
    <Root
      title={intl.split('<>{actor:string}</> created a new discussion', {
        actor: item.actor.name,
      })}
      content={item.discussion.prompt}
      href={URLS.student.discussion({
        course: {
          id: courseId,
          title: courseSlug,
        },
        discussionId: item.discussion.id,
        categoryId: item.discussion.categoryId || genericDiscussionCategoryIds.all,
      })}
      image={
        <UserProfileIcon
          size="h-10 w-10 rounded-full flex items-center justify-center"
          user={item.actor}
        />
      }
      icon={<IcoAnnouncement class="w-4 h-4" />}
      date={item.createdAt}
      onClick={() => onSelected?.(item)}
    />
  );
}

function Announcement({ item, course, onSelected }: ItemProps<AnnouncementNotification>) {
  const intl = useIntl();
  const user = useCurrentUser();
  const title = replaceAnnouncementPlaceholders({
    text: item.message.title,
    studentName: user?.name,
    guideName: item.actor.name,
    courseTitle: course.title,
  });
  const [bodyCache, setBodyCache] = useState('');

  return (
    <Root
      title={intl.split('<>{actor:string}</> sent an announcement', {
        actor: item.actor.name,
      })}
      content={title}
      image={
        <UserProfileIcon
          size="h-10 w-10 rounded-full flex items-center justify-center"
          user={item.actor}
        />
      }
      icon={<IcoAnnouncement class="w-4 h-4" />}
      date={item.createdAt}
      onClick={async (e) => {
        e.stopPropagation();
        onSelected?.(item);

        let body = bodyCache;
        if (!body) {
          const result = await rpx.notifications.getAnnouncementBody({
            notificationId: item.id,
            courseId: course.id,
          });
          body = result.body;
          setBodyCache(result.body);
        }

        showModalForm(({ resolve }) => {
          return (
            <StandardDialog onClose={resolve}>
              <DialogHeader title={title} />
              <div class="minidoc-preview overflow-y-auto max-h-96 mb-6 bg-gray-100 rounded-lg border-2 border-dashed border-gray-300 [&_template-field]:bg-transparent">
                <div
                  class="p-1 lg:p-4 break-words"
                  dangerouslySetInnerHTML={{
                    __html: replaceAnnouncementPlaceholders({
                      text: body,
                      studentName: user?.name,
                      guideName: item.actor.name,
                      courseTitle: course.title,
                    }),
                  }}
                />
              </div>
            </StandardDialog>
          );
        });
      }}
    />
  );
}

export function NotificationItem({ item, course, isLast, unread, onSelected }: Props) {
  const itemProps = {
    course,
    onSelected,
  };

  return (
    <li class="relative">
      <div class={`relative ${isLast ? 'pb-2' : ''}`}>
        {!isLast && (
          <span
            class="absolute top-8 left-9 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-600"
            aria-hidden="true"
          />
        )}
        {(item.type === 'newComment' ||
          item.type === 'commentReplied' ||
          item.type === 'mention' ||
          item.type === 'commentLiked') && <CommentItem item={item} {...itemProps} />}
        {item.type === 'newDiscussion' && <NewDiscussionItem item={item} {...itemProps} />}
        {item.type === 'announcement' && <Announcement item={item} {...itemProps} />}
      </div>
      <Case when={unread}>
        <div class="absolute top-6 right-4">
          <PulsingCircle />
        </div>
      </Case>
    </li>
  );
}
