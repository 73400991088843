import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Case } from '@components/conditional';
import { ProgressBar } from '@components/progress-bar';
import { CurrentUser, useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { CorePricingTable } from 'client/pages/pmts/components/core-pricing-table';
import { useState } from 'preact/hooks';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { MAX_FREE_TIER_STUDENTS, RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

/**
 * Progress bar showing the number of used student enrollments for a guide on the free/trial tier.
 */
function UsedEnrollmentsProgressBar(props: { user: CurrentUser }) {
  const [usedEnrollments, setUsedEnrollments] = useState(0);
  useAsyncEffect(async () => {
    const enrollmentCount = await rpx.accounts.getTotalEnrollmentCount();
    setUsedEnrollments(
      enrollmentCount > MAX_FREE_TIER_STUDENTS ? MAX_FREE_TIER_STUDENTS : enrollmentCount,
    );
  }, [props.user]);
  const progress = Math.round((usedEnrollments / MAX_FREE_TIER_STUDENTS) * 100);
  return (
    <>
      <p class="text-base my-2 lg:mb-4 lg:mt-6">
        You can enroll up to {MAX_FREE_TIER_STUDENTS} students on your limited free plan. You have
        used {usedEnrollments} of your {MAX_FREE_TIER_STUDENTS} free enrollments.
      </p>
      <span class="block mx-auto">
        <ProgressBar
          progress={progress}
          showPercent={false}
          height="h-2"
          bgClass="bg-gray-200 dark:bg-gray-600"
        />
      </span>
    </>
  );
}

export function FreeTierBanner({ class: className = '' }: { class?: string }) {
  const tenant = useCurrentTenant();
  const user = useCurrentUser();

  const [showPricing, setShowPricing] = useState(false);
  if (!tenant.isCore || !user?.isRestricted) {
    return null;
  }

  return (
    <div class={`mt-4 ${className}`}>
      <div class="bg-indigo-50 rounded-lg mx-auto flex items-center justify-between text-gray-900">
        <div class="p-6 md:p-8 flex flex-col gap-2">
          <h2 class="text-lg lg:text-3xl mb-2 font-semibold tracking-tight">
            Enjoy limited free course creation on Ruzuku!
          </h2>
          <p class="text-sm lg:text-base">
            <UsedEnrollmentsProgressBar user={user} />
            <span class="block mt-3">
              Upgrade now to sign up unlimited students. Or{' '}
              <a target="_blank" rel="noopener noreferrer" href="mailto:sales@ruzuku.com">
                contact us
              </a>{' '}
              for help anytime!
            </span>
          </p>
          <footer class="flex flex-col lg:flex-row lg:items-center gap-2">
            <Case
              when={!showPricing}
              fallback={
                <BtnSecondary onClick={() => setShowPricing(false)}>
                  Hide Pricing Table
                </BtnSecondary>
              }
            >
              <BtnPrimary class="px-4 rounded-full" onClick={() => setShowPricing(true)}>
                Upgrade ⤑
              </BtnPrimary>
              <span class="hidden lg:block text-gray-300">|</span>
              <a
                href="https://ruzuku.appointlet.com/s/7360/peter-smiley"
                target="_blank"
                rel="noreferrer"
              >
                Book a call with us to go over any questions you have ⤑
              </a>
            </Case>
          </footer>
          {showPricing && (
            <div class="mt-4">
              <CorePricingTable />
            </div>
          )}
        </div>
        {!showPricing && (
          <div class="hidden md:block -mr-3 md:-mr-12">
            <img
              class="w-36 h-36"
              data-attribution="https://undraw.co"
              src={`${RUZUKU_ASSETS_BASE_URL}/undraw_outer_space_re_u9vd.svg`}
            />
          </div>
        )}
      </div>
    </div>
  );
}
