/**
 * The course promo block renders a course as a callout.
 * It needs to be aware of the course state *including* any memberships the
 * current user (if there is one) has. See the comment in the course grid for
 * a similar list of edgecases.
 */
import { showModalForm } from '@components/modal-form';
import {
  EditorProps,
  RichProp,
  SalesBlockInitialState,
  ViewerProps,
} from '@components/page-builder';
import { useImageUrl } from 'client/utils/cdn';
import { useContext } from 'preact/hooks';
import { CourseModal } from './course-modal';
import { CourseBlockContext } from './types';
import { h as htm } from 'minidoc-editor';
import { DescribeAvailability } from './course-grid-block';
import { URLS } from 'shared/urls';
import {
  CtaButton,
  CTAState,
  initialState as ctaInitialState,
} from '@components/page-builder/cta-button';
import { Color } from 'shared/colors';
import { IcoPlus } from '@components/icons';

interface PromoCourse {
  courseId: string;
  priceId?: string;
  couponId?: string;
}

export interface State {
  course?: PromoCourse;
  content: string;
  callToAction: CTAState;
}

export const initialState: State = {
  content: '',
  callToAction: {
    ...ctaInitialState,
    text: 'Enroll Now ➜',
  },
};

export const name = 'CoursePromo';

export const type = 'coursepromo';

export const initialBlockState: SalesBlockInitialState = {
  type,
  bgcolor: Color.white,
};

export function MiniView() {
  return (
    <span class="flex gap-4 p-4 bg-gray-500 rounded-md">
      <span class="block bg-gray-200 opacity-75 rounded-sm p-6"></span>
      <span class="block bg-gray-200 opacity-75 rounded-sm grow"></span>
    </span>
  );
}

function CourseCard(props: (EditorProps<State> | ViewerProps<State>) & { onPriceClick?(): void }) {
  const { state } = useContext(CourseBlockContext);
  const promoCourse = props.state.course!;
  const course = state.courses[promoCourse.courseId];
  const price = promoCourse.priceId ? state.prices[promoCourse.priceId] : undefined;
  const coupon = promoCourse.couponId ? state.coupons[promoCourse.couponId] : undefined;
  const posterImg = useImageUrl(course?.imagePath, {
    maxWidth: 600,
  });
  const signupsClosed = course?.status !== 'published';

  if (!course) {
    return null;
  }

  return (
    <div class="flex flex-col-reverse md:grid md:grid-cols-3 gap-10 text-sm">
      <div class="col-span-2 flex flex-col justify-center gap-4 text-base minidoc-nomargin">
        <RichProp {...props} path="content" />
        {props.setState && (
          <button class="text-left" onClick={props.onPriceClick}>
            <DescribeAvailability {...promoCourse} />
          </button>
        )}
        {!props.setState && <DescribeAvailability {...promoCourse} />}
        <footer>
          <CtaButton
            {...props}
            path="callToAction"
            defaultChoice={{
              description: 'Go to the course sales page',
              url: URLS.student.salespage({
                course,
                couponId: coupon?.id,
                priceId: price?.id,
              }),
            }}
          />
        </footer>
      </div>
      <img src={posterImg} class={`rounded-lg ${signupsClosed ? 'grayscale' : ''}`} />
    </div>
  );
}

export function Editor(props: EditorProps<State>) {
  const { state, setState } = props;
  const ctx = useContext(CourseBlockContext);

  if (state.course) {
    return (
      <CourseCard
        {...props}
        onPriceClick={() => {
          showModalForm(({ resolve }) => (
            <CourseModal
              course={
                state.course?.courseId ? ctx.state.courses[state.course?.courseId] : undefined
              }
              onCancel={() => resolve()}
              onPick={(result) => {
                ctx.setState((s) => ({
                  ...s,
                  prices: result.price
                    ? { ...s.prices, [result.price.id]: result.price }
                    : s.prices,
                  coupons: result.coupon
                    ? { ...s.coupons, [result.coupon.id]: result.coupon }
                    : s.coupons,
                }));
                setState((s) => ({
                  ...s,
                  course: {
                    courseId: result.course.id,
                    couponId: result.coupon?.id,
                    priceId: result.price?.id,
                  },
                }));
                resolve(result);
              }}
            />
          ));
        }}
      />
    );
  }

  return (
    <div class="grid grid-cols-3 justify-center gap-8 text-sm">
      <button
        class="flex flex-col gap-4 leading-snug"
        onClick={() =>
          showModalForm(({ resolve }) => (
            <CourseModal
              onCancel={() => resolve()}
              onPick={(result) => {
                ctx.setState((s) => ({
                  ...s,
                  courses: { ...s.courses, [result.course.id]: result.course },
                  prices: result.price
                    ? { ...s.prices, [result.price.id]: result.price }
                    : s.prices,
                  coupons: result.coupon
                    ? { ...s.coupons, [result.coupon.id]: result.coupon }
                    : s.coupons,
                }));
                setState((s) => ({
                  ...s,
                  content: s.course
                    ? s.content
                    : htm(
                        'div',
                        htm('p', htm('i', 'Featured course!')),
                        htm('h2', result.course.title),
                        htm(
                          'p',
                          `Limited seats are going fast! Don't miss out on this amazing opportunity!`,
                        ),
                      ).innerHTML,
                  course: {
                    courseId: result.course.id,
                    couponId: result.coupon?.id,
                    priceId: result.price?.id,
                  },
                  callToAction: {
                    ...s.callToAction,
                    url: '',
                  },
                }));
                resolve(result);
              }}
            />
          ))
        }
      >
        <span class="w-full bg-gray-900/10 hover:shadow-lg aspect-h-3 aspect-w-4 rounded-lg p-2">
          <span class="flex items-center justify-center font-semibold w-full">
            <IcoPlus class="mr-2" />
            Add Course
          </span>
        </span>
      </button>

      <p class="opacity-75 flex items-center p-4 col-span-2">
        Choose a course, price, and optionally a coupon to specially promote.
      </p>
    </div>
  );
}

export const Viewer = CourseCard;
