import { AttachmentContent } from '@components/attachments/attachment-item';
import { Case } from '@components/conditional';
import { ComponentChildren } from 'preact';
import { StudentQuizChoice } from 'server/types';
import { IcoCheckCircle, IcoXCircle } from '@components/icons';

interface ChoiceItemProps {
  choice: StudentQuizChoice;
  index: number;
  checked: boolean;
  disabled: boolean;
  galleryView: boolean;
  isCorrect?: boolean;
  hideCorrectAnswer: boolean;
  handleChange: () => void;
}

export const ALPHABET = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

export function ChoiceItem(props: ChoiceItemProps) {
  const { choice, galleryView, isCorrect, disabled, hideCorrectAnswer, checked, handleChange } =
    props;

  const ContentComponent = galleryView ? WithAttachment : WithoutAttachment;

  return (
    <div
      class={`flex items-center border dark:border-gray-700 rounded overflow-hidden relative ${
        disabled ? '' : 'cursor-pointer hover:border-indigo-300 dark:hover:border-sky-500'
      } ${galleryView ? 'flex-col' : ''} ${checked ? 'border-indigo-500 dark:border-sky-500' : ''}`}
      onClick={disabled ? undefined : handleChange}
    >
      <ContentComponent {...props}>
        <span class="text-base grow pl-4">{choice.content}</span>
        <Case when={!hideCorrectAnswer}>
          {isCorrect === true && (
            <IcoCheckCircle class="w-5 h-5 text-green-600 dark:text-green-400 mx-4" />
          )}
          {isCorrect === false && (
            <IcoXCircle class="w-5 h-5 text-red-600 dark:text-red-400 mx-4" />
          )}
        </Case>
      </ContentComponent>
    </div>
  );
}

function WithoutAttachment(
  props: ChoiceItemProps & {
    children: ComponentChildren;
  },
) {
  const { index, checked } = props;

  return (
    <>
      <div
        class={`${
          checked ? 'bg-indigo-500 text-white dark:bg-sky-500' : 'bg-gray-100 dark:bg-gray-700'
        } p-4`}
      >
        <span class="font-medium">{ALPHABET[index]}</span>
      </div>
      {props.children}
    </>
  );
}

function WithAttachment(
  props: ChoiceItemProps & {
    children: ComponentChildren;
  },
) {
  const { choice, index, checked, children } = props;

  return (
    <div class="flex flex-col grow justify-center items-center py-2 lg:py-2 text-gray-600 dark:text-gray-400">
      <div
        class={`lg:flex justify-center items-center overflow-hidden mx-auto lg:h-60 lg:w-60 max-h-60 max-w-60 dark:lg:bg-gray-800 rounded lg:mb-2 ${
          choice.file ? 'mb-2' : ''
        }`}
      >
        {!choice.file && <span class="bg-gray-100 dark:bg-gray-700 grow h-full"></span>}
        {choice.file && <AttachmentContent attachment={choice.file} />}
      </div>
      <div
        class={`${
          checked ? 'bg-indigo-500 text-white dark:bg-sky-500' : 'bg-gray-100 dark:bg-gray-700'
        } p-4 absolute left-0 flex items-center lg:shadow-md lg:bottom-auto lg:rounded-br-md ${
          choice.file ? 'rounded-br-md shadow-md top-0' : 'inset-y-0'
        }`}
      >
        <span class="font-medium">{ALPHABET[index]}</span>
      </div>
      {children}
    </div>
  );
}
