import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabWrapper } from '@components/admin-nav';
import { InstantCourseItem } from './instant-course-item';
import { showAddCourseModal } from './add-course-modal';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { URLS } from 'shared/urls';
import { IcoDocumentSearch, IcoPlus } from '@components/icons';
import { LoadedProps, defRoute } from '@components/router';
import { load } from './load';

export const route = defRoute({
  load,
  authLevel: 'superadmin',
  Page,
});

function Page({ state: courses, setState: setCourses }: LoadedProps<typeof load>) {
  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="instantCourses" />
        <AdminTabWrapper>
          <header>
            <h2 class="text-2xl">Instant Courses</h2>
            <nav class="space-x-4 flex items-center my-4">
              <BtnSecondary
                onClick={() =>
                  showAddCourseModal({
                    existingCourses: courses.map((c) => c.id),
                    onSuccess: (course) => {
                      setCourses((courses) => [...courses, course]);
                    },
                  })
                }
              >
                <IcoPlus class="w-4 h-4 mr-1 opacity-75" /> Add a Course
              </BtnSecondary>
              <BtnPrimary href={URLS.guide.instantCourses()}>
                <IcoDocumentSearch class="w-4 h-4 mr-1 opacity-75" />
                Preview Instant Courses
              </BtnPrimary>
            </nav>
          </header>
          <section class="py-4">
            <div class="table table-auto bg-white rounded-sm w-full divide-y">
              <div class="table-row bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div class="table-cell pl-4 pr-2 py-2">Title</div>
                <div class="table-cell pl-4 pr-2 py-2">Category</div>
                <div class="table-cell pl-4 pr-2 py-2"></div>
              </div>
              {courses.map((course) => (
                <InstantCourseItem
                  key={course.id}
                  course={course}
                  onChange={(newCourse) => {
                    setCourses(courses.map((c) => (c.id === course.id ? newCourse : c)));
                  }}
                  onDelete={() => setCourses(courses.filter((c) => c.id !== course.id))}
                />
              ))}
            </div>
          </section>
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}
