import { Button } from '@components/buttons';
import { Checkbox } from '@components/checkbox';
import { Dropdown } from '@components/dropdown';
import { IcoFilter, IcoX } from '@components/icons';
import { SearchBox } from '@components/search-box';
import { ComponentChildren } from 'preact';
import { useMemo } from 'preact/hooks';

export function FilterCount({ children }: { children: ComponentChildren }) {
  return <span class="text-gray-400 ml-4">{children}</span>;
}

export function FilterGroup({ title, children }: { title: string; children: ComponentChildren }) {
  return (
    <div class="flex flex-col space-y-2 text-sm min-w-40">
      <h3 class="uppercase text-xs font-bold tracking-wider text-gray-500 border-b pb-2">
        {title}
      </h3>
      {children}
    </div>
  );
}

export function FilterList<T extends { id: UUID }>(props: {
  title: string;
  items: Record<string, T>;
  renderItem(item: T): JSX.Element | string;
  toggleFilter(id: UUID): void;
  filter: Set<UUID>;
}) {
  const hasItems = useMemo(() => Object.keys(props.items).length > 0, [props.items]);

  if (!hasItems) {
    return null;
  }

  return (
    <FilterGroup title={props.title}>
      {Object.values(props.items).map((c) => (
        <Checkbox
          key={c.id}
          checked={props.filter.has(c.id)}
          onClick={() => props.toggleFilter(c.id)}
        >
          {props.renderItem(c)}
        </Checkbox>
      ))}
    </FilterGroup>
  );
}

export function FilterOptions<T>({
  title,
  options,
  currentValue,
  setFilter,
}: {
  title: string;
  currentValue: T;
  options: Array<{ title: string; value: T; count?: number; sortDirection?: number }>;
  setFilter(value: T): void;
}) {
  return (
    <FilterGroup title={title}>
      {options.map((o) => (
        <Button
          key={o.value}
          class={`${o.value === currentValue ? 'font-bold' : ''} text-left`}
          onClick={() => setFilter(o.value)}
        >
          {o.title}
          {o.count !== undefined && <FilterCount>{o.count}</FilterCount>}
        </Button>
      ))}
    </FilterGroup>
  );
}

export function FilterBox({
  compact,
  hasFilters,
  searchPlaceholder,
  searchTerm,
  setSearchTerm,
  clearFilters,
  children,
}: {
  compact?: boolean;
  hasFilters: boolean;
  searchTerm: string;
  searchPlaceholder: string;
  setSearchTerm(term: string): void;
  clearFilters(): void;
  children: ComponentChildren;
}) {
  return (
    <div class={`flex ${compact ? 'flex-col' : 'flex-col md:flex-row'}`}>
      <div class="flex">
        <Dropdown
          triggerClass="bg-gray-50 border border-r-0 border-gray-300 rounded-sm rounded-r-none inline-flex h-full px-2"
          position="left-0 top-full mt-2"
          class="text-gray-500"
          renderMenu={() => (
            <div
              class="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-16 p-4"
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </div>
          )}
        >
          <IcoFilter class="w-4 h-4 mr-1 opacity-75" />
          Filter
        </Dropdown>
        <SearchBox
          class="ruz-input pl-9 text-sm rounded-l-none"
          onTermChange={(term) => setSearchTerm(term.toLowerCase())}
          placeholder={searchPlaceholder}
          value={searchTerm}
        />
      </div>
      {(hasFilters || searchTerm.length > 0) && (
        <span
          class={`inline-flex ${compact ? 'mt-6' : 'mt-2 md:mt-0 md:border-l md:ml-4 md:pl-4'}`}
        >
          <Button
            class="inline-flex items-center"
            onClick={() => {
              clearFilters();
              setSearchTerm('');
            }}
          >
            <span class="border rounded-sm bg-gray-50 mr-2 p-0.5 inline-block">
              <IcoX class="w-3 h-3 opacity-75" />
            </span>
            <span class="text-indigo-500">Clear current filters</span>
          </Button>
        </span>
      )}
    </div>
  );
}
