import type { ComponentChildren, h } from 'preact';
import {
  IcoAcademicCap,
  IcoBook,
  IcoChartBar,
  IcoDocument,
  IcoFolder,
  IcoHome,
  IcoLibrary,
  IcoList,
  IcoMagnifyingGlass,
  IcoSearch,
  IcoSettings,
  IcoUser,
  SVGProps,
} from '@components/icons';
import { Button } from '@components/buttons';
import { useEffect, useState } from 'preact/hooks';
import { SlideOver } from '@components/slide-over';
import { UserMenu } from '@components/nav/user-menu';
import { useRootGuideSearch } from './root-guide-search';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import { hasLevel } from 'shared/auth';
import type { Course } from './root-guide-search';
import { defRoute, useRouteContext, useRouter } from '@components/router';
import { LoadingIndicator } from '@components/loading-indicator';
import { SiteNavLogo } from '@components/nav/site-nav-logo';
import { useIntl } from 'shared/intl/use-intl';

type HomepageTabs =
  | 'welcome'
  | 'overview'
  | 'courses'
  | 'products'
  | 'bundles'
  | 'upsells'
  | 'account'
  | 'instantCourses'
  | 'manageSite';

function NavLink(props: {
  isSelected: boolean;
  href: string;
  name: string;
  isExternal?: boolean;
  onClick?: () => void;
  Icon(p: SVGProps): h.JSX.Element;
}) {
  return (
    <li class="relative">
      {props.isSelected && (
        <span class="absolute inset-y-0 -left-2 w-[3px] rounded-tr-xl rounded-br-xl bg-indigo-600"></span>
      )}
      <a
        href={props.href}
        class={`group flex gap-x-3 rounded-md px-4 py-2 text-sm/6 ${
          props.isSelected
            ? 'text-indigo-600 font-semibold'
            : 'text-gray-800 hover:bg-gray-50 hover:text-indigo-600'
        }`}
        target={props.isExternal ? '_blank' : undefined}
        rel={props.isExternal ? 'noopener noreferrer' : undefined}
        onClick={props.onClick}
      >
        <props.Icon
          aria-hidden="true"
          class={`h-5 w-5 shrink-0 ${
            props.isSelected ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
          }`}
        />
        {props.name}
      </a>
    </li>
  );
}

function NavBarContent({ activeTab, close }: { activeTab: HomepageTabs; close?: () => void }) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const user = useCurrentUser();

  return (
    <div class="flex h-full min-h-0 flex-col w-full">
      <div class="pt-8 pb-4 px-6">
        <SiteNavLogo />
      </div>
      <nav class="flex flex-1 flex-col p-4">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              {hasLevel(user, 'guide') && (
                <NavLink
                  isSelected={activeTab === 'welcome'}
                  href="/welcome"
                  name={intl('Welcome')}
                  Icon={IcoHome}
                />
              )}
              {hasLevel(user, 'guide') && (
                <NavLink
                  isSelected={activeTab === 'overview'}
                  href="/overview"
                  name={intl('Overview')}
                  Icon={IcoMagnifyingGlass}
                />
              )}
              <NavLink
                isSelected={activeTab === 'courses'}
                href="/courses"
                name={intl('Courses')}
                Icon={IcoAcademicCap}
                onClick={close}
              />
              <NavLink
                isSelected={activeTab === 'products'}
                href="/products"
                name={intl('Products')}
                Icon={IcoBook}
                onClick={close}
              />
              <NavLink
                isSelected={activeTab === 'bundles'}
                href="/bundles"
                name={intl('Bundles')}
                Icon={IcoFolder}
                onClick={close}
              />
              {hasLevel(user, 'guide') && (
                <NavLink
                  isSelected={activeTab === 'upsells'}
                  href="/upsells"
                  name={intl('Upsells')}
                  Icon={IcoChartBar}
                />
              )}
            </ul>
          </li>
        </ul>
        <footer class="flex flex-col space-y-4 pt-4 pb-8 border-t border-primary w-full">
          <ul role="list" class="-mx-2 space-y-1">
            <NavLink
              isSelected={activeTab === 'account'}
              href="/account"
              name={intl('Account')}
              Icon={IcoUser}
            />
            {hasLevel(user, 'admin') && (
              <NavLink
                isSelected={activeTab === 'manageSite'}
                href="/admin/people"
                name={intl('Manage Site')}
                Icon={IcoSettings}
              />
            )}
            {(tenant.isCore || user?.hasInstantCourseAccess) && hasLevel(user, 'guide') && (
              <NavLink
                isSelected={activeTab === 'instantCourses'}
                href="/instant-courses"
                name="Instant Courses"
                Icon={IcoLibrary}
              />
            )}
            <NavLink
              isSelected={false}
              href={
                hasLevel(user, 'guide')
                  ? 'https://support.ruzuku.com/collection/741-guide-help'
                  : tenant.supportUrl || 'https://support.ruzuku.com/collection/746-student-help'
              }
              isExternal
              name={intl('Support Articles')}
              Icon={IcoDocument}
            />
          </ul>
        </footer>
      </nav>
    </div>
  );
}

function SearchButton({ courses }: { courses?: Course[] }) {
  const showSearchModal = useRootGuideSearch({
    courses,
  });

  return (
    <Button
      class="flex items-center justify-center w-full md:w-72 focus:ring-indigo-400 focus:border-indigo-400 rounded-lg text-sm text-gray-500 border border-gray-300 p-2"
      onClick={showSearchModal}
    >
      <IcoSearch class="mr-2" />
      Quick search...
      <span class="ml-auto pl-3 text-xs text-gray-400">⌘K</span>
    </Button>
  );
}

const LAST_ROOT_PAGE_URL = 'last-root-page-url';

export function RootPageLayout({
  activeTab,
  title,
  courses,
  actions,
  hideTitle,
  children,
}: {
  activeTab: HomepageTabs;
  title?: string;
  courses?: Course[];
  actions?: ComponentChildren;
  hideTitle?: boolean;
  children: ComponentChildren;
}) {
  const user = useCurrentUser();
  const route = useRouteContext();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useDocumentTitle([title]);

  useEffect(() => {
    // Persist the last visited root page URL before any dynamic params
    localStorage.setItem(LAST_ROOT_PAGE_URL, route.url.split(':')[0]);
  }, [route.url]);

  return (
    <div class="relative isolate flex min-h-svh w-full max-lg:flex-col lg:bg-gray-100">
      {isMobileMenuOpen && (
        <SlideOver
          position="left"
          contentContainerClass="mr-16"
          close={() => setIsMobileMenuOpen(false)}
        >
          <NavBarContent activeTab={activeTab} close={() => setIsMobileMenuOpen(false)} />
        </SlideOver>
      )}
      <div class="hidden lg:flex lg:fixed lg:inset-y-0 left-0 w-64">
        <NavBarContent activeTab={activeTab} />
      </div>
      <main class="flex flex-1 flex-col p-4 lg:min-w-0 lg:pl-64 lg:pr-2 lg:pt-2">
        <div class="grow bg-white lg:shadow-xs lg:rounded-lg pb-8">
          <div class="mx-auto px-2 lg:px-8 lg:max-w-6xl">
            <header class="flex gap-4 py-8 pb-0 mb-8">
              <Button class="lg:hidden" onClick={() => setIsMobileMenuOpen(true)}>
                <IcoList class="w-6 h-6 opacity-75" />
              </Button>
              <div class="grow">
                {hasLevel(user, 'guide') && <SearchButton courses={courses} />}
              </div>
              <div class="flex gap-8">
                <div class="hidden lg:block">{actions}</div>
                <UserMenu />
              </div>
            </header>
            <div class="flex lg:hidden items-center justify-between gap-4 px-2 pb-6">
              {!hideTitle && <h2 class="text-lg font-semibold text-gray-900">{title}</h2>}
              {actions}
            </div>
            {children}
          </div>
        </div>
      </main>
    </div>
  );
}

function RootRedirectPage() {
  const router = useRouter();
  useEffect(() => {
    const url = localStorage.getItem(LAST_ROOT_PAGE_URL);
    let redirectTo = url || '';
    // Redirect to /courses if no last root page URL is found
    if (!url || url === '/') {
      redirectTo = '/courses';
    }
    router.rewrite(redirectTo);
  }, []);

  return <LoadingIndicator />;
}

export const route = defRoute({
  Page: RootRedirectPage,
  authLevel: 'student',
});
