import { Discussion } from '@components/discussions';
import { IcoChat } from '@components/icons';
import { Course, FullLesson } from '@components/module-helpers';
import { useRouteParams } from '@components/router';
import { useIntl } from 'shared/intl/use-intl';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';

interface Props {
  course: Course;
  discussion: FullLesson['discussion'];
}

export function DiscussionSection({ course, discussion }: Props) {
  const intl = useIntl();
  const { commentId: highlightedCommentId, replyId: highlightedReplyId } = useRouteParams();

  if (!discussion?.isEnabled) {
    return null;
  }

  const { prompt, description } = discussion;

  return (
    <>
      <div class="h-1 bg-linear-to-r from-transparent via-gray-200 to-transparent dark:via-gray-700 -mx-6 my-12"></div>
      <div class="mb-8 font-medium dark:text-white">
        <h2 class="text-2xl">{intl('Discussion')}</h2>
        {discussion.required && (
          <h4 class="flex items-center mt-2">
            <span class="text-red-600 ml-2 text-2xl mr-2">*</span>
            {intl('You need to comment on this discussion first to complete this lesson.')}
          </h4>
        )}
      </div>
      {(!!prompt || !!description) && (
        <div class="mb-12">
          <div class={`flex ${!description ? 'items-center' : ''}`}>
            <span class="flex justify-center mr-6">
              <IcoChat class="w-14 h-14 opacity-75 text-theme-link dark:bg-gray-700 dark:text-white bg-theme-100 bg-gray-400 text-white rounded-full p-4" />
            </span>
            <div class="grow">
              <h2 class="text-lg font-medium text-gray-700 dark:text-gray-200 flex">
                <span>{prompt}</span>
              </h2>
              {!!description && (
                <ReadonlyMinidoc
                  class="mt-2 font-normal text-gray-500 dark:text-gray-400"
                  content={description}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <Discussion
        key={discussion.id}
        courseId={course.id}
        discussionId={discussion.id}
        highlightedId={highlightedCommentId}
        highlightedReplyId={highlightedReplyId}
        guideId={course.guide.id}
        hidePeople={course.hidePeople}
      />
    </>
  );
}
