/**
 * This file contains authentication helpers.
 */

import { ComponentChildren, createContext } from 'preact';
import { StateUpdater, useContext, useMemo, useState } from 'preact/hooks';
import { exitBeacon } from 'client/components/helpscout-beacon';
import { RuzukuGlobal, Tenant } from 'server/types';
import { RpxResponse, rpx } from '../rpx-client';
import { serialAsync } from 'client/utils/serial-async';
import type { Dispatch } from 'preact/hooks';

export type CurrentUser = NonNullable<RpxResponse<typeof rpx.auth.getCurrentUser>>;
type Metadata = CurrentUser['metadata'];

export const globalConfig = () => (window as any).ruzukuGlobal as RuzukuGlobal;

const store = rpx.auth;

const notImplemented = () => {
  throw new Error('Not implemented');
};

export interface Auth {
  user?: CurrentUser;
  setUser: Dispatch<StateUpdater<CurrentUser | undefined>>;
  config: RuzukuGlobal;
  updateUserMetadata: (metadata: Partial<Metadata>) => Promise<Metadata>;
  login(promise: Promise<CurrentUser>): Promise<CurrentUser>;
  logout(): Promise<void>;
}

export const emptyContext: Auth = {
  logout: notImplemented,
  login: notImplemented,
  updateUserMetadata: notImplemented,
  config: globalConfig(),
  setUser: () => {},
};

const AuthContext = createContext<Auth>(emptyContext);

export function useAuth() {
  return useContext(AuthContext);
}

export function useCurrentUser() {
  return useAuth().user;
}

export function useCurrentTenant(): Tenant {
  return useAuth().config?.tenant;
}

export function useConfiguration() {
  return useAuth()?.config;
}

const logout = serialAsync(store.logout);

export function AuthContextProvider(props: { children: ComponentChildren }) {
  const [user, setUser] = useState<Auth['user']>(() => globalConfig().user);
  const ctx = useMemo<Auth>(() => {
    return {
      user,
      setUser,
      config: globalConfig(),
      async login(p) {
        const newUser = await p;
        setUser(newUser);
        ctx.user = newUser;
        return newUser;
      },
      async logout() {
        await logout();
        setUser(undefined);
        exitBeacon(); // Close the helpscout beacon and user context
      },
      async updateUserMetadata(metadata: Partial<Metadata>) {
        const newMetadata = await store.updateMetadata(metadata);
        setUser((u) => {
          if (!u) {
            return u;
          }
          return {
            ...u,
            metadata: newMetadata,
          };
        });
        return newMetadata;
      },
    };
  }, [setUser, user]);

  return <AuthContext.Provider value={ctx}>{props.children}</AuthContext.Provider>;
}
